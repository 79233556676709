import React, { useState, useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ComponentModals from "../components/ComponentModals";
import { useNavigate } from "react-router-dom";
import {
  getData,
  getProcessing,
  resetModal,
  setProcessing,
  showDeleteModal,
  showEditModal,
  showViewModal,
} from "../redux/modal";
import { getInputs, setInputs } from "../redux/form";
import {
  deleteEntry,
  insertEntry,
  prepareData,
  updateEntry,
} from "../utils/utils";
import DisplayItems from "../components/DisplayItems";
// import { getUserDetails } from "../../../redux/user";
// import { functions } from "../../../services/fire";
// import { httpsCallable } from "firebase/functions";
// import axios from "axios";
import {
  addEntryToDB,
  // deleteEntryFromDB,
  // getDataEntries,
  // getStaticData,
  updateEntryById,
} from "../service/entries";
import {
  addAutoSuggestToDB,
  deleteAutoSuggestFromDB,
  getAutoSuggestFromDB,
} from "../service/autosuggest";
// import { getQuestions } from "../service/questions";
import { saveEntry } from "../service/database";
import {
  deleteUserInputFromDB,
  getUserInputFromDB,
  updateUserInputById,
} from "../service/userinput";
import SpinnerText from "../../../common/SpinnerText";

function UserInput({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector(getUserDetails);
  const inputs = useSelector(getInputs);
  const selectedEntry = useSelector(getData);
  const processing = useSelector(getProcessing);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [processFill, setProcessFill] = useState(false);
  const [processClean, setProcessClean] = useState(false);

  useEffect(() => {
    if (!user) {
      console.error("Not Authorised!");
      navigate("/", { replace: true });
    } else {
      getUserInputFromDB(10).then((data) => {
        console.log(data);
        setList(data);
        setFiltered(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (value) => {
    dispatch(showDeleteModal({ data: value }));
  };

  const handleAdd = async (value) => {
    await addAutoSuggestToDB({
      name: value.input.toLowerCase(),
      entryId: value.id,
      created: value.created.seconds * 1000,
    });
    await updateUserInputById(value.id, { added: true });

    const entries = updateEntry(value.id, list, { ...value, added: true });
    setList(entries);
    filterEntries(entries, search.trim());
  };

  const handleRemove = async (value) => {
    console.log(value);
    await deleteAutoSuggestFromDB(value.id);
    await updateUserInputById(value.id, { added: false });

    const entries = updateEntry(value.id, list, { ...value, added: false });
    setList(entries);
    filterEntries(entries, search.trim());
  };

  // const handleSearch = ({ currentTarget: input }) => {
  //   setSearch(input.value);
  //   filterEntries(list, input.value);
  // };

  const filterEntries = (entries, entry) => {
    const sub = entries.filter((i) => {
      const name = `${i.input.toLowerCase()}`;
      return name.indexOf(entry.toLowerCase()) !== -1;
    });
    setFiltered(sub);
  };

  const handleEdit = (value) => {
    //populate inputs
    dispatch(setInputs(value));
    dispatch(showEditModal({ data: value }));
  };

  const handleView = (value) => {
    //populate inputs
    dispatch(setInputs(value));
    dispatch(showViewModal({ data: value }));
  };

  const handleUploadSuccess = (uploadSnapshot) => {
    const path = uploadSnapshot.metadata.fullPath;

    if (path) {
      // updatePhotoUrl(path, list).then((data) => {
      //   setList(data);
      // });
    }
  };

  const performAdd = async () => {
    const formatted = prepareData(inputs);

    dispatch(setProcessing(true));
    // await doSave(formatted, true);
    const obj = {
      ...formatted,
      // id: formatted.id.toString(),
      link: "",
      location: "",
      prodserve: "",
      added: new Date().getTime(),
    };
    // console.log(obj);
    const out = await addEntryToDB(obj);
    //process with existing list
    const entries = insertEntry(list, out);
    setList(entries);
    filterEntries(entries, search.trim());
    dispatch(resetModal());
  };

  // const reloadAll = () => {
  //   setLoading(true);
  //   const useAxios = false;
  //   if (useAxios) {
  //     axios
  //       .get(
  //         `https://asia-southeast1-all-catholic.cloudfunctions.net/fillListing`
  //       )
  //       .then((res) => {
  //         const result = res.data;
  //         setLoading(false);
  //         console.log(result);
  //       });
  //   } else {
  //     const reload = httpsCallable(functions, "reloadall");
  //     const entries = getDataEntries();
  //     console.log(entries);
  //     console.log(entries.length);
  //     const questions = getQuestions();
  //     reload({ questions: questions, entries: entries }).then((data) => {
  //       setLoading(false);
  //       console.log(data);
  //     });
  //   }
  // };

  const performEdit = async () => {
    dispatch(setProcessing(true));
    //edit here and save
    const formatted = { ...selectedEntry, ...prepareData(inputs) };
    console.log(formatted);
    const entries = updateEntry(selectedEntry.id, list, formatted);
    await updateEntryById(selectedEntry.id, formatted);
    setList(entries);
    filterEntries(entries, search.trim());
    dispatch(resetModal());
  };

  const confirmDelete = async () => {
    const updated = deleteEntry(list, selectedEntry.id);
    await doSave(updated, list === null);
  };

  const doSave = async (entry, isEmpty) => {
    //save to db
    await saveEntry(entry, isEmpty);
  };

  // const doFill = async () => {
  //   setProcessFill(true);
  //   const data = getStaticData();
  //   console.log(data);
  //   for (let i = 0; i < data.length; i++) {
  //     const entry = data[i];
  //     const obj = {
  //       ...entry,
  //       // id: (i + 1).toString(),
  //       location: "",
  //       created: new Date().getTime(),
  //       added: new Date().getTime(),
  //     };
  //     // console.log(obj);
  //     await addEntryToDB(obj);
  //   }

  //   setProcessFill(false);
  // };

  const doCleanAutoSuggestDB = async () => {
    setProcessClean(true);
    const entries = await getAutoSuggestFromDB(200);
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      console.log(entry);
      await deleteAutoSuggestFromDB(entry.entryId);
      // await deleteEntryFromDB(entry.id);
    }
    setProcessClean(false);
    // setList(null);
    // setFiltered([]);
  };

  const doCleanAll = async () => {
    setProcessClean(true);
    const entries = await getUserInputFromDB(1000);
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      console.log(entry);
      // await deleteAutoSuggestFromDB(entry.entryId);
      await deleteUserInputFromDB(entry.id);
    }
    setProcessClean(false);
    // setList(null);
    // setFiltered([]);
  };

  return (
    <div>
      <div className="row justify-content-center mx-auto">
        <div className="">
          <Container>
            <Row>
              <div className="d-flex justify-content-between pb-3 pt-4">
                <div className="">
                  <h1 className="">
                    User Input{" "}
                    {filtered?.length > 0 ? `(${filtered.length})` : ""}
                  </h1>
                </div>
                <div className="">
                  {/* <Button
                    disabled={processFill}
                    variant="warning"
                    className="mt-2 mb-3"
                    onClick={doFill}
                  >
                    {!processFill ? "Fill DB" : <SpinnerText text=" " />}
                  </Button> */}
                  <Button
                    variant="outline-danger"
                    className="ms-2 mt-2 mb-3"
                    onClick={doCleanAutoSuggestDB}
                    disabled={processClean || true}
                  >
                    {!processClean ? (
                      "Clean Autosuggest"
                    ) : (
                      <SpinnerText text="" />
                    )}
                  </Button>
                  <Button
                    variant="danger"
                    className="ms-2 mt-2 mb-3"
                    onClick={doCleanAll}
                    disabled={processClean || true}
                  >
                    {!processClean ? "Clean ALL" : <SpinnerText text="" />}
                  </Button>
                  {/* <Button
                    variant="success"
                    className="ms-2 mt-2 mb-3"
                    onClick={() => {
                      dispatch(resetInputs());
                      dispatch(showAddModal());
                    }}
                  >
                    Add Entry
                  </Button> */}

                  {/* <Button
                    variant="outline-danger"
                    className="ms-2 mt-2 mb-3"
                    onClick={reloadAll}
                    disabled={loading}
                  >
                    {!loading ? "Reload JSON" : <SpinnerText text="" />}
                  </Button> */}
                </div>
              </div>
            </Row>
            {/* <Input
              type="text"
              name="search"
              value={search}
              placeholder="Quick Search"
              onChange={handleSearch}
              error={null}
              css="mb-3"
            /> */}
            <DisplayItems
              data={filtered}
              addHandler={handleAdd}
              removeHandler={handleRemove}
              deleteHandler={handleDelete}
              editHandler={handleEdit}
              viewHandler={handleView}
              uploadSuccessHandler={handleUploadSuccess}
            />
          </Container>
        </div>
      </div>
      <ComponentModals
        processing={processing}
        addHandler={performAdd}
        editHandler={performEdit}
        deleteHandler={confirmDelete}
      />
    </div>
  );
}

export default UserInput;
