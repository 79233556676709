import React from "react";
// import TypeWriterEffect from "react-typewriter-effect";

function Tagline({ css = "py-5" }) {
  return (
    <h3
      className={`${css} mx-3 text-center d-flex justify-contents-center fw-bold marianblue`}
    >
      Connecting Catholics in Singapore
      {/* <TypeWriterEffect
        startDelay={50}
        cursorColor="#3F3D56"
        multiText={[
          "Welcome to AllCatholicsSG",
          "Connecting Catholics in Singapore",
        ]}
        multiTextDelay={1000}
        typeSpeed={90}
      /> */}
    </h3>
  );
}

export default Tagline;
