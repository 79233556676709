import React from "react";
import ListTypeImageDetails from "./ListTypeImageDetails";

function ListImage({ entries }) {
  return entries.map((entry) => {
    const { document } = entry;
    const { name, photo, id } = document;
    return (
      <div key={id} className="py-2">
        <ListTypeImageDetails name={name} photo={photo} entry={entry} />
      </div>
    );
  });
}

export default ListImage;
