import { httpsCallable } from "firebase/functions";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { functions } from "../../../services/fire";
import ListImage from "../components/ListTypeImage";
import UpdateModal from "../../updatemodal/page/UpdateModal";
// import Typewriter from "typewriter-effect";
import QuickSearch from "../components/QuickSearch";
import WideLogo from "../../../common/WideLogo";
import { getSuggestions } from "../../../utils/settings";
import SuggestAdd from "../../../common/SuggestAdd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SubmitForm from "../components/SubmitForm";
import ContactBottom from "../../../common/ContactBottom";
import { addUserInputToDB, updateUserInput } from "../../../services/userinput";
import CustomSearch from "../components/CustomSearch";
import { MagnifyingGlass } from "react-loader-spinner";
import Footer from "../../../common/Footer";
import AutoSuggest from "../../../common/AutoSuggest";
import Categories from "../../../common/Categories";
let filterTimeout;
const autosuggestsearch = httpsCallable(functions, "autosuggestsearch");

function SearchAutoSuggest() {
  const { squery } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState(squery);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [storedQuery, setStoredQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // console.log(squery);
    async function fetchData() {
      // You can await here
      // console.log("handleQuickAutoSuggest called...");
      await handleQuickSearch(squery);
      // ...
    }
    fetchData();
  }, [squery]);

  const handleReset = () => {
    navigate("/", { replace: true });
  };

  const handleOnSelect = (item) => {
    // the item selected
    // console.log(item);
    if (item.name.length < 1) {
      return;
    }
    setSuggestions([]);

    redirectSubmit(item.name);
  };

  const redirectSubmit = (s) => {
    const q = s;
    // const q = s.trim();
    navigate(`/s/${q}`);
    setQuery(q);
  };

  const handleQuickSearch = async (qs) => {
    try {
      //try to save all searches
      const search = httpsCallable(functions, "search");
      setLoading(true);
      setQuery(qs);
      setStoredQuery(qs);
      search({ query: updateUserInput(qs), entries: 50 }).then((data) => {
        setSuggestions([]);

        setResults(data);
        setLoading(false);

        // console.log(data);
        addUserInputToDB(qs.trim(), data).then(() => {
          console.log(".");
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRedirectSubmit = (q) => {
    if (q.length < 1) {
      return;
    }
    redirectSubmit(q);
  };

  const handleOnSearch = (string, results) => {
    // console.log("handleOnSearch: ", string);
    if (string.trim().length === 0) {
      return;
    }
    setQuery(string);

    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results);
    autoSuggestQuery(string);
    // autoSuggestQueryDebounce(string);
  };

  const autoSuggestQuery = (query) => {
    console.log("Query: ", query);
    if (!query) return setSuggestions([]);
    // setOldSuggestions(suggestions);

    autosuggestsearch({ query: query, entries: 10 }).then((data) => {
      // setResults(data);
      // setLoading(false);
      // console.log(data);
      const val = data.data.message.hits.map((i) => {
        // console.log(i);
        return { id: i.document.id, name: i.document.name.toLowerCase() };
      });
      setSuggestions(val);
    });
  };

  const autoSuggestQueryDebounce = (query) => {
    console.log("Query: ", query);
    clearTimeout(filterTimeout);
    if (!query) return setSuggestions([]);

    filterTimeout = setTimeout(() => {
      // console.log("====>", query);
      const autosuggestsearch = httpsCallable(functions, "autosuggestsearch");
      autosuggestsearch({ query: query, entries: 10 }).then((data) => {
        // setResults(data);
        // setLoading(false);
        // console.log(data);
        const value = data.data.message.hits.map((i) => {
          // console.log(i);
          return { id: i.document.id, name: i.document.name.toLowerCase() };
        });

        setSuggestions(value);
        // addUserInputToDB(qs.trim(), data).then(() => {
        //   console.log(".");
        // });
      });
    }, 20);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (query.length < 2) {
      return;
    }
    redirectSubmit(query);
  };

  const formatResult = (item) => {
    return (
      <div className="pe-3">
        <div className="text-wrap py-1" style={{ lineHeight: "1.2em" }}>
          {item.name}
        </div>
      </div>
    );
  };

  return (
    <div>
      <UpdateModal />
      <div className="pb-3">
        <div
          className="pointer px-4 pt-2 pb-4 d-flex justify-content-start align-items-center"
          onClick={handleReset}
        >
          <WideLogo showVersion={true} />
        </div>
        <Container>
          <Row>
            <Col lg={10} className="pb-2">
              {/* <SubmitForm
                value={query}
                loading={loading}
                handleSubmit={handleSubmit}
                changeHandler={handleChange}
              /> */}
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                className="pb-2"
              >
                <CustomSearch
                  search={query}
                  value={suggestions}
                  handleOnSelect={handleOnSelect}
                  onChange={handleOnSearch}
                  formatResult={formatResult}
                />
              </form>
              {/* <Categories selected={query} /> */}
            </Col>
          </Row>
        </Container>
        {results && (
          <div className="bg-white">
            <Container>
              {results.data.message.found > 0 && (
                <Row>
                  <Col lg={10}>
                    <div className="ps-2 pb-3 text-muted">
                      About {results.data.message.found} result
                      {results.data.message.found > 1 && "s"}
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={10}>
                  <div className="pb-4 px-3">
                    {results.data.message.found > 0 && (
                      <>
                        <ListImage entries={results.data.message.hits} />
                        <AutoSuggest handler={handleRedirectSubmit} />

                        <Footer />
                      </>
                    )}
                  </div>
                  {loading === false && results.data.message.found === 0 && (
                    <div className="text-dark text-wrap">
                      <div className="fs-4 fw-bold">
                        "{storedQuery}" not found.
                      </div>
                      <div className="fs-4 d-flex justify-content-start mt-2 mb-5">
                        <SuggestAdd entry={storedQuery} />
                      </div>
                      <AutoSuggest handler={handleRedirectSubmit} />

                      {/* <div className="d-flex text-wrap mt-4 text-muted">
                        <QuickSearch
                          label=<div className="me-2 fw-bold text-dark">
                            Other searches
                          </div>
                          suggest={[
                            ...[
                              "migrant support",
                              "nursing home",
                              "financial assistance",
                              "community",
                              "volunteer",
                            ],
                            ...getSuggestions(),
                          ]}
                          handler={handleRedirectSubmit}
                        />
                      </div> */}
                      <Footer />
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {!results && (
          <Container>
            <Row>
              <Col lg={10}>
                <div className="text-center pt-5">
                  <MagnifyingGlass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="MagnifyingGlass-loading"
                    wrapperStyle={{}}
                    wrapperClass="MagnifyingGlass-wrapper"
                    glassColor="#efefef"
                    color="#2a4694"
                  />
                  <div className="pt-0">Searching...</div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
}

export default SearchAutoSuggest;
