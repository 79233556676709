import React from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function EmailInput({ email, emailErr, setEmail }) {
  let formCss = "form-control form-control-lg ";
  if (emailErr !== "") {
    formCss = `${formCss} is-invalid`;
  }

  return (
    <>
      <div className="mx-auto maxinputwidth input-group mt-4 mb-2">
        <span className="input-group-text me-1" id="email-icon">
          <EmailOutlinedIcon fontSize="small" />
        </span>
        <input
          type="text"
          value={email}
          error={emailErr}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          // autoFocus={true}
          className={formCss}
          placeholder="Type your email here..."
          aria-label="Username"
          aria-describedby="email-icon"
          // onBlur={handleBlur}
          onChange={({ currentTarget: input }) => {
            setEmail(input.value.toLowerCase());
          }}
        />
      </div>
      {emailErr !== "" && <p className="text-danger fw-bold">{emailErr}</p>}
    </>
  );
}

export default EmailInput;
