import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

function FeaturedMasonry() {
  const navigate = useNavigate();
  const items = [
    {
      name: "Latest Mass Timings",
      uid: 1,
      url: "www.mycatholic.sg/schedules",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fmycatholicsg-new.png?alt=media&token=73232d08-d86e-4d2e-8d01-40b307f5a682",
      description:
        "Checkout the latest Mass Schedules of all the 32 Churches in Singapore.",
    },
    {
      name: "My Rosary Ministry",
      uid: 2,
      url: "www.myrosaryministry.com",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Frosary.jpeg?alt=media&token=863dbc26-14d1-462f-95f9-d5647613362d",
      description:
        "Community that makes and prays the Rosary, growing closer to God through Mother Mary.",
    },
    {
      name: "Sacred Heart of Jesus Plushie by Little Flock",
      uid: 3,
      url: "www.littleflock.sg",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsacredheartjesusplushie.jpg?alt=media&token=8b18e215-1925-4495-8fe7-540f6939fec3",
      description: "Huggable Sacred Heart of Jesus stuffed toys",
    },
    {
      name: "Caritas Humanitarian Aid Relief Initiatives Singapore (CHARIS)",
      uid: 4,
      url: "www.charis-singapore.org",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHARIS_OG.jpeg?alt=media&token=7978912f-cbb7-47cb-b0d1-4e748a8d4ad8",
      description: "CHARIS helps in the humanitarian efforts in Asia...",
    },
  ];

  return (
    <div className="pt-2 px-0 pb-3">
      <div className="pt-3 pb-3 d-flex justify-content-start">
        <h5 className="px-3 text-dark fw-bold">Featured</h5>
      </div>

      <ResponsiveMasonry
        className="px-0 mx-0"
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}
      >
        <Masonry>
          {items.map((item) => (
            <div
              key={`${item.uid}`}
              style={{ position: "relative", zIndex: "0" }}
            >
              <div
                className="py-2 pointer"
                onClick={() => {
                  navigate(`/s/${item.name}`);
                }}
              >
                <div className="text-start mx-3 pt-2 pb-3 roundcornernocolor alert bg-white shadow">
                  {item?.photo && (
                    <div className="pt-3 text-center">
                      <img
                        className="img-fluid roundedframeborder"
                        src={item?.photo}
                        alt={item.name}
                      />
                    </div>
                  )}
                  <div className="fs-4 marianblue withtextdeco fw-bold px-0 pt-2 pb-0  pointer">
                    {item.name}
                  </div>
                  <div
                    style={{ lineHeight: "1.3em" }}
                    className="fs-5 text-left text-success text-truncate"
                  >
                    {item.url}
                  </div>
                  <div className="fs-5 pt-0 px-0 clamp3">
                    {item.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default FeaturedMasonry;
