import { addDoc, serverTimestamp, collection } from "firebase/firestore";
import { db } from "./fire";
import { genBrowserId } from "../utils/browserid";

export async function addUserInputToDB(input, data) {
  await addDoc(collection(db, "userinput"), {
    found: data.data.message.found,
    browserid: genBrowserId(),
    input: input.trim(),
    created: serverTimestamp(),
  });
}

export function updateUserInput(query) {
  const q = query
    .toLowerCase()
    .replaceAll("how ", " ")
    .replaceAll("who ", " ")
    .replaceAll("where ", " ")
    .replaceAll("what ", " ")
    .replaceAll(" is ", " ")
    .replaceAll(" for ", " ")
    .replaceAll(" find ", " ")
    .replaceAll(" catholic ", " ")
    .replaceAll("catholic ", " ")
    .replaceAll(" in ", " ")
    .replaceAll(" a ", " ")
    .replaceAll(" of ", " ")
    .replaceAll(" from ", " ")
    .replaceAll(" with ", " ")
    .replaceAll(" to ", " ")
    .replaceAll(" go ", " ")
    .replaceAll(" the ", " ");
  console.log(q);
  return q;
}
