import React from "react";
import QuickSearch from "../modules/search/components/QuickSearch";
import { getSuggestions } from "../utils/settings";

function AutoSuggest({ handler = () => {} }) {
  return (
    <div className="d-flex text-wrap mt-5 mb-2 text-muted">
      <QuickSearch
        label=<div className="me-2 fw-bold text-dark">Other searches</div>
        suggest={getSuggestions()}
        handler={handler}
      />
    </div>
  );
}

export default AutoSuggest;
