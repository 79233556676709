import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ListTypeImageDetails from "./ListTypeImageDetails";

function InfiniteScrollList({ items, fetchData }) {
  return (
    <InfiniteScroll
      dataLength={items.length} //This is important field to render the next data
      next={fetchData}
      hasMore={true}
      loader={<h4 className="pt-5">Loading...</h4>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {items.map((item) => (
        <div key={item.uid} className="m-4">
          <ListTypeImageDetails
            name={item.name}
            photo={item.photo}
            entry={{ document: item }}
          />
        </div>
      ))}
    </InfiniteScroll>
  );
}

export default InfiniteScrollList;
