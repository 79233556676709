import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

function AddEmailSuccess({ handler = () => {} }) {
  return (
    <Container className="appearslowly">
      <Row>
        <Col>
          <div className="d-flex flex-column mt-4 justify-content-center">
            <div className="bg-white mx-auto dashedborder text-center py-4 px-5 mb-4">
              <ThumbUpAltOutlinedIcon className="dialogicon marianblue mb-2" />
              <h2>Peace be with you!</h2>
              <p className="fs-5">
                Thank you for your interest and we will update you via email.
              </p>
            </div>
          </div>
          <Button size="lg" variant="outline-secondary" onClick={handler}>
            Done
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEmailSuccess;
