/** modal redux */
export const MODAL_NAME = "modal";
export const MODAL_INIT = {
  text: "",
  type: "",
};
/** constants */
export const MODAL_TYPE_VIEW = "view";
export const MODAL_TYPE_ADD = "add";
export const MODAL_TYPE_EDIT = "edit";
export const MODAL_TYPE_DELETE = "delete";
/** titles */
export const MODAL_TITLE_VIEW = "View Details";
export const MODAL_TITLE_ADD = "Add New Entry";
export const MODAL_TITLE_EDIT = "Edit Entry";
export const MODAL_TITLE_DELETE = "Delete Entry";
/** forms */
export const FIELD_NAME = "name";
export const FIELD_CATEGORY = "category";
export const FIELD_DESCRIPTION = "description";
export const FIELD_KEYWORDS = "keywords";
export const FIELD_PHOTO = "photo";
export const FIELD_EMAIL = "email";
export const FIELD_PHONE = "phone";
export const FIELD_FACEBOOK = "facebook";
export const FIELD_INSTAGRAM = "instagram";
export const FIELD_LINK = "link";
export const FIELD_ADDRESS = "location";
export const FIELD_WHATSAPP = "whatsapp";
export const FIELD_WEBSITE = "website";

/** forms redux */
export const FORM_NAME = "form";
export const FORM_INIT = {
  [FIELD_NAME]: "",
  [FIELD_CATEGORY]: "",
  [FIELD_DESCRIPTION]: "",
  [FIELD_KEYWORDS]: "",
  [FIELD_PHOTO]: "",
  [FIELD_EMAIL]: "",
  [FIELD_PHONE]: "",
  [FIELD_FACEBOOK]: "",
  [FIELD_INSTAGRAM]: "",
  [FIELD_LINK]: "",
  [FIELD_ADDRESS]: "",
  [FIELD_WHATSAPP]: "",
  [FIELD_WEBSITE]: "",
};
//invalid form if "name" equals ""
export const INVALID_FORM_FIELD_VALUES = {
  [FIELD_NAME]: "",
  /** DO NOT ADD OPTIONAL FIELDS HERE */
};

export function getSalutationValue() {
  return [
    "Rev Fr",
    "Friar",
    "Rev Msgr",
    "Rev Deacon",
    "Most Rev",
    "His Excellency Archbishop",
    "H. Em. Cardinal",
  ];
}

export function getStatusValue() {
  return ["Active", "Retired"];
}

export function getChurchRoleValue() {
  return ["Parish Priest", "Assistant Priest", "Priest-in-Residence"];
}
export function getPriestType() {
  return [
    "Apostolic Nuncio",
    "Archbishop Emeritus",
    "Archbishop of Singapore",
    "Carmelites of Mary Immaculate (CMI)",
    "Congregation of the Disciples of the Lord (CDD)",
    "Diocesan",
    "Dominicans, The Order of Preachers (OP)",
    "Franciscan Friars (OFM)",
    "Heralds of Good News (HGN)",
    "Jesuits (SJ)",
    "Order of Discalced Carmelites (OCD)",
    "Paris Foreign Mission (MEP)",
    "Prelature of the Holy Cross & Opus Dei (Opus Dei)",
    "Redemptorists (CSsR)",
    "Sacred Hearts of Jesus & Mary (SS.CC)",
    "Scheut Missions (CICM)",
  ];
}
export function getSuffixValue() {
  return [
    "CDD",
    "CICM",
    "CMI",
    "CSsR",
    "DD",
    "HGN",
    "JCL",
    "MEP",
    "OCD",
    "OFM",
    "OFM, JCL",
    "OP",
    "Opus Dei",
    "SJ",
    "SS.CC",
  ];
}

export function getOrgPhotoPath(id) {
  const filePath = "priest/" + id + "/public/logo";
  return filePath;
}
