export function getQuestions() {
  const test = true;
  if (test) {
    return getStaticData();
  } else {
    return getQuestionsFromDB();
  }
}

function getQuestionsFromDB() {
  return null;
}

function getStaticData() {
  return [
    { name: "where to find mass with children liturgy" },
    { name: "where to find children mass" },
    { name: "where to find tagalog mass" },
    { name: "where to find tamil mass" },
    { name: "where to find bahasa indonesia mass" },
    { name: "how to help someone with depression" },
    { name: "where to find speech therapist" },
    { name: "where to find catholic nursing home" },
    { name: "where to find elderly home" },
    { name: "how to help someone with dementia" },
    { name: "how to help someone with alzheimer's" },
    { name: "how to help someone with parkinson" },
    { name: "where to find catholic preschool" },
    { name: "where to find catholic kindergarten" },
    { name: "where to find catholic nursery" },
    { name: "where to find catholic infant care" },
    { name: "where to find catholic childcare" },
    { name: "where to find catholic primary school near me" },
    { name: "where to find catholic secondary school near me" },
    { name: "which parish has a bookshop" },
    { name: "where to find catholic church with gift shop inside" },
    { name: "where to buy a catholic bible" },
    { name: "where to buy bible for studying" },
    { name: "where to buy chinese bible" },
    { name: "where to buy easy to understand bible" },
    { name: "where to buy children's bible" },
    { name: "where to buy catholic bible suitable for kids" },
    { name: "where to buy confirmation dress" },
    { name: "where to buy baptism clothes" },
    { name: "where to buy baptism clothes for baby" },
    { name: "where to buy baptism clothes for kid" },
    { name: "where to buy baptism clothes teenager" },
    { name: "where to buy baptism clothes for adult" },
    { name: "what to do during and after catholic divorce" },
    { name: "where can i get some money" },
    { name: " who can give me money" },
    { name: "catholic hospital near me" },
    { name: "how to call or find a priest for last rites" },
    { name: "need a priest to bless someone who is about to die" },
    { name: "when is the next baby or child baptism" },
    { name: "catholic baptism for my kid" },
    { name: "catholic jobs" },
    { name: "where to find malayalam mass" },
    { name: "where to find healing mass" },
    { name: "where to find charismatic mass" },
    { name: "catholic funeral" },
    { name: "catholic undertaker" },
    { name: "funeral mass wake catholic" },
    { name: "which church has space to hold a wake" },
    {
      name: "where to find church with columbarium with available space for niche",
    },
    { name: "where to find to place my ashes" },
    { name: "where to find  final resting place" },
    { name: "where to find catholic doctor" },
    { name: "where to find ob-gyne" },
    { name: "where to find pediatrician" },
    { name: "where to find cardiologist" },
    { name: "where to find dermatologist" },
    { name: "where to find surgeon" },
    { name: "where to find orthopedic" },
    { name: "how to register for marriage in church" },
    { name: "what are the retreat house available" },
    { name: "i am looking for a painter to paint a portrait of jesus" },
    { name: "i am looking for a catholic roommate" },
    { name: "where to find a dietician" },
    { name: "where to find jobs in singapore" },
    { name: "where to find a catholic confinement nanny" },
    { name: "i am looking for a counsellor for my teenage child" },
    { name: "where to bring my children for weekend activities " },
    { name: "where to find christmas activities for children" },
    { name: "where to go for the devotion to our mother of perpertual help" },
    { name: "is there a feast for the sto. nino in singapore" },
    { name: "where to go for a pilgrimage" },
    { name: "where to go to volunteer " },
    { name: "i am looking for a catholic girlfriend" },
    { name: "gatherings for single catholics" },
    { name: "where to go for the devotion to the sacred heart of jesus" },
    { name: "what are the requirements to register for marriage in church" },
    { name: "where to find a priest for spiritual guidance" },
    { name: "what are the benefits of being a catholic" },
    { name: "what to do if my child is converting to other religion" },
    { name: "who is the archbishop of singapore" },
    { name: "where to buy candle" },
    { name: "where to buy cake" },
    { name: "where to buy rosary" },
  ];
}
