import React from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import AdminControl from "./AdminControl";

function MainAdmin({ user }) {
  return (
    <Provider store={store}>
      <AdminControl user={user} />
    </Provider>
  );
}

export default MainAdmin;
