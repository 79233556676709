// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID,
};
/**
 * 1. In public.html, add <script>self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;</script>
 * This will display something like this on the console.log:
 * App Check debug token: xxx-xxx-xxx-xxx-xxx. You will need to add it to your app's App Check settings in the Firebase console for it to work.
 *
 * Copy the debug token and save it to Debug Keys in Firebase Console.
 * See https://firebase.google.com/docs/app-check/web/debug-provider
 * */
if (
  process.env.REACT_APP_ENVIRONMENT_TYPE ||
  process.env.REACT_APP_ENVIRONMENT_TYPE === "dev"
) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_APPCHECK_DEBUG_TOKEN;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_V3),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app, "asia-southeast1");
