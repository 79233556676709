import {
  FIELD_ADDRESS,
  FIELD_NAME,
  FIELD_PHOTO,
  FIELD_EMAIL,
  FIELD_PHONE,
  FIELD_DESCRIPTION,
  FIELD_WHATSAPP,
  FIELD_WEBSITE,
  FIELD_INSTAGRAM,
  FIELD_KEYWORDS,
  FIELD_FACEBOOK,
  FIELD_CATEGORY,
} from "./config";

export function prepareData(data) {
  const date = new Date().getTime();
  return {
    created: date,
    // id: date,
    [FIELD_NAME]: data[FIELD_NAME].trim(),
    [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION].trim(),
    [FIELD_CATEGORY]: data[FIELD_CATEGORY].trim(),
    [FIELD_KEYWORDS]: data[FIELD_KEYWORDS].trim(),
    [FIELD_EMAIL]: data[FIELD_EMAIL].trim(),
    [FIELD_WEBSITE]: data[FIELD_WEBSITE].trim(),
    [FIELD_FACEBOOK]: data[FIELD_FACEBOOK].trim(),
    [FIELD_INSTAGRAM]: data[FIELD_INSTAGRAM].trim(),
    [FIELD_PHONE]: data[FIELD_PHONE].trim(),
    [FIELD_PHOTO]: data[FIELD_PHOTO].trim(),
    [FIELD_WHATSAPP]: data[FIELD_WHATSAPP].trim(),
    [FIELD_ADDRESS]: data[FIELD_ADDRESS].trim(),
    [FIELD_PHOTO]: data[FIELD_PHOTO].trim(),
  };
}

export function reformatFields(data) {
  const map = new Map();
  for (let i in data) {
    //regroup
    const str = i.split("_"); //1st part is key, 2nd part is id
    const value = data[i];
    const key = str[0];
    const id = str[1];
    if (!map.has(id)) {
      map.set(id, { [key]: value });
    } else {
      const val = map.get(id);
      map.set(id, { ...val, [key]: value });
    }
  }
  const results = [];

  for (let [key, value] of map) {
    results.push({ ...value, id: key });
  }

  return results;
}

export function reformatAssociations(data) {
  return reformatFields(data);
}

export function reformatAdditionalInfo(data) {
  return reformatFields(data);
}

export function reformatExtras(data) {
  return reformatFields(data);
}

export function insertEntry(list, data) {
  const entries = [];
  //put latest on top so easy to upload photo
  entries.push(data);
  if (list !== null) {
    entries.push(...list);
  }
  return entries;
}

export function updateEntry(id, list, data) {
  return list.map((i) => (i.id === id ? data : i));
}

export function deleteEntry(list, id) {
  return list.filter((i) => i.id !== id);
}

export function getEntry(id, list) {
  return list.filter((i) => i.id === id);
}
