import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "./fire";

export async function getUserListFromDB() {
  const results = [];
  const querySnapshot = await getDocs(collection(db, "userlist"));
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
    // console.log(`${doc.id} => ${doc.data()}`);
  });
  return results;
}

export async function checkEmailFromDB(email) {
  const results = [];
  const userRef = collection(db, "userlist");
  const q = query(userRef, where("email", "==", email.toLowerCase()));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
    // console.log(`${doc.id} => ${doc.data()}`);
  });
  return results.length > 0 ? true : false;
}

export async function addEmailToDB(email) {
  await addDoc(collection(db, "userlist"), {
    email: email.toLowerCase(),
    created: serverTimestamp(),
  });
}
