import React, { useState } from "react";
import Landing from "./modules/landing/page/Landing";
import "./assets/fonts/Nunito-Medium.ttf";
import { Route, Routes } from "react-router-dom";
import NotFound from "./modules/notfound/page/NotFound";
// import Search from "./modules/search/page/Search";
import MainAdmin from "./modules/control/admin/MainAdmin";
// import MainSearch from "./modules/search/page/MainSearch";
// import MainCrud from "./modules/crud/admin/MainCrud";
// import InstaSearch from "./modules/instasearch/page/InstaSearch";
// import AutoSearch from "./modules/search/page/AutoSearch";
// import VectorSearch from "./modules/search/page/VectorSearch";
import MainAutoSuggestSearch from "./modules/search/page/MainAutoSuggestSearch";
import SearchAutoSuggest from "./modules/search/page/SearchAutoSuggest";
import LoginForm from "./modules/login/page/LoginForm";
import { auth } from "./services/fire";
import { onAuthStateChanged } from "firebase/auth";
import { getUserFromDB } from "./services/user";
// import { setUserDetails } from "./redux/user";
import TempPage from "./modules/temp/admin/TempPage";
import MainUserInput from "./modules/userinput/admin/MainUserInput";
// import { useNavigate } from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      const test = true;
      if (!test) {
        getUserFromDB(currentUser.uid).then((data) => {
          console.log(data);
          // dispatch(setUserDetails(currentUser.uid));
          setUser(currentUser.uid);
        });
      } else {
        // dispatch(setUserDetails(currentUser.uid));
        setUser(currentUser.uid);

        console.log("Authorised!!!");
      }
    } else {
      console.log("Invalid Users!!!!");
    }
  });

  return (
    <Routes>
      {/* <Route element={<VectorSearch />} path="/vector" exact /> */}
      <Route element={<LoginForm user={user} />} path="/login" exact />
      <Route element={<Landing user={user} />} path="/contact" exact />
      {/* <Route element={<AutoSearch user={user} />} path="/auto" exact /> */}
      <Route element={<MainAutoSuggestSearch user={user} />} path="/" exact />
      <Route
        element={<SearchAutoSuggest user={user} />}
        path="/s/:squery"
        exact
      />
      {/* <Route element={<MainSearch user={user} />} path="/main" exact /> */}
      {/* <Route element={<Search user={user} />} path="/as/:squery" exact /> */}
      {/* <Route element={<Search user={user} />} path="/search" exact /> */}
      {/* <Route element={<InstaSearch user={user} />} path="/search" exact /> */}
      <Route element={<MainUserInput user={user} />} path="/userinput" exact />
      <Route element={<MainAdmin user={user} />} path="/entries" exact />
      <Route element={<TempPage user={user} />} path="/temp" exact />
      {/* <Route element={<MainCrud />} path="/control" exact /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
