import React, { useState, useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ComponentModals from "../components/ComponentModals";
import { useNavigate } from "react-router-dom";
import {
  getData,
  getProcessing,
  resetModal,
  setProcessing,
  showAddModal,
  showDeleteModal,
  showEditModal,
  showViewModal,
} from "../redux/modal";
import { getInputs, resetInputs, setInputs } from "../redux/form";
import {
  deleteEntry,
  insertEntry,
  prepareData,
  updateEntry,
} from "../utils/utils";
import DisplayItems from "../components/DisplayItems";
import Input from "../../../common/Input";
// import { getUserDetails } from "../../../redux/user";
import { functions } from "../../../services/fire";
import { httpsCallable } from "firebase/functions";
import SpinnerText from "../../../common/SpinnerText";
import axios from "axios";
import {
  addEntryToDB,
  deleteEntryFromDB,
  getDataEntries,
  getDataEntriesFromDB,
  getStaticData,
  updateEntryById,
} from "../service/entries";
import { getQuestions } from "../service/questions";
import { saveEntry } from "../service/database";
import { addAutoSuggestToDB } from "../../userinput/service/autosuggest";
import { getSalutationValue } from "../../userinput/utils/config";
import { getParishes } from "../../../services/parish";
const parishes = getParishes();

function AdminControl({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector(getUserDetails);
  const inputs = useSelector(getInputs);
  const selectedEntry = useSelector(getData);
  const processing = useSelector(getProcessing);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processFill, setProcessFill] = useState(false);
  const [processClean, setProcessClean] = useState(false);

  useEffect(() => {
    if (!user) {
      console.error("Not Authorised!");
      navigate("/", { replace: true });
    }
    getDataEntriesFromDB().then((data) => {
      console.log(
        data.map((i) => {
          return { id: i.id, entry: i.name };
        })
      );
      setList(data);
      setFiltered(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (value) => {
    dispatch(showDeleteModal({ data: value }));
  };

  const handleSearch = ({ currentTarget: input }) => {
    setSearch(input.value);
    filterEntries(list, input.value);
  };

  const filterEntries = (entries, entry) => {
    const sub = entries.filter((i) => {
      const name = `${i.name.toLowerCase()} ${i.keywords.toLowerCase()}`;
      return name.indexOf(entry.toLowerCase()) !== -1;
    });
    setFiltered(sub);
  };

  const handleEdit = (value) => {
    //populate inputs
    dispatch(setInputs(value));
    dispatch(showEditModal({ data: value }));
  };

  const handleView = (value) => {
    //populate inputs
    dispatch(setInputs(value));
    dispatch(showViewModal({ data: value }));
  };

  const handleUploadSuccess = (uploadSnapshot) => {
    const path = uploadSnapshot.metadata.fullPath;

    if (path) {
      // updatePhotoUrl(path, list).then((data) => {
      //   setList(data);
      // });
    }
  };

  const performAdd = async () => {
    const formatted = prepareData(inputs);

    dispatch(setProcessing(true));
    // await doSave(formatted, true);
    const obj = {
      ...formatted,
      // id: formatted.id.toString(),
      link: "",
      location: "",
      prodserve: "",
      added: new Date().getTime(),
    };
    // console.log(obj);
    const out = await addEntryToDB(obj);
    //process with existing list
    const entries = insertEntry(list, out);
    setList(entries);
    filterEntries(entries, search.trim());
    dispatch(resetModal());
  };

  const reloadAll = () => {
    setLoading(true);
    const useAxios = false;
    if (useAxios) {
      axios
        .get(
          `https://asia-southeast1-all-catholic.cloudfunctions.net/fillListing`
        )
        .then((res) => {
          const result = res.data;
          setLoading(false);
          console.log(result);
        });
    } else {
      const reload = httpsCallable(functions, "reloadall");
      const entries = getDataEntries();
      console.log(entries);
      console.log(entries.length);
      const questions = getQuestions();
      reload({ questions: questions, entries: entries }).then((data) => {
        setLoading(false);
        console.log(data);
      });
    }
  };

  const performEdit = async () => {
    dispatch(setProcessing(true));
    //edit here and save
    const formatted = { ...selectedEntry, ...prepareData(inputs) };
    console.log(formatted);
    const entries = updateEntry(selectedEntry.id, list, formatted);
    await updateEntryById(selectedEntry.id, formatted);
    setList(entries);
    filterEntries(entries, search.trim());
    dispatch(resetModal());
  };

  const confirmDelete = async () => {
    const updated = deleteEntry(list, selectedEntry.id);
    await doSave(updated, list === null);
  };

  const doSave = async (entry, isEmpty) => {
    //save to db
    await saveEntry(entry, isEmpty);
  };

  const doFill = async () => {
    setProcessFill(true);
    const data = getStaticData();
    console.log(data);
    // let count = 0;
    for (let i = 0; i < data.length; i++) {
      // const entry = data[i];
      const [ispriest, transformed, entry] = transformObj(data[i]);
      // if (ispriest) {
      //   console.log(entry);
      // }
      // if (transformed) {
      //   count++;
      // }
      // console.log(entry);
      const obj = {
        ...entry,
        // id: (i + 1).toString(),
        location: "",
        created: new Date().getTime(),
        added: new Date().getTime(),
      };
      // console.log(obj);
      const result = await addEntryToDB(obj);
      await addAutoSuggestToDB({
        name: entry.name,
        entryId: result.id,
        created: new Date().getTime(),
      });
    }
    // console.log("Total: ", count);
    setProcessFill(false);
  };

  const transformObj = (obj) => {
    // console.log(obj);
    const salutations = getSalutationValue();
    let isPriest = false;
    for (let i = 0; i < salutations.length; i++) {
      const entry = salutations[i];
      if (obj.name.startsWith(entry)) {
        isPriest = true;
        break;
      }
    }
    let transformed = false;
    if (isPriest) {
      //check if matching church
      for (let j = 0; j < parishes.length; j++) {
        const church = parishes[j];
        if (obj.description.indexOf(church.completename) !== -1) {
          transformed = true;
          obj.name = `${obj.name} @ ${church.completename}`;
          // console.log(obj.name);
          break;
        }
      }
    }
    return [isPriest, transformed, obj];
  };

  const doCleanDB = async () => {
    setProcessClean(true);
    for (let i = 0; i < list.length; i++) {
      const entry = list[i];
      console.log(entry.id);
      await deleteEntryFromDB(entry.id);
    }
    setProcessClean(false);
    setList(null);
    setFiltered([]);
  };

  return (
    <div>
      <div className="row justify-content-center mx-auto">
        <div className="">
          <Container>
            <Row>
              <div className="d-flex justify-content-between pb-3 pt-4">
                <div className="">
                  <h1 className="">
                    Entries {filtered?.length > 0 ? `(${filtered.length})` : ""}
                  </h1>
                </div>
                <div className="">
                  <Button
                    disabled={processFill || true}
                    variant="warning"
                    className="mt-2 mb-3"
                    onClick={doFill}
                  >
                    {!processFill ? "Fill DB" : <SpinnerText text=" " />}
                  </Button>
                  <Button
                    variant="outline-danger"
                    className="ms-2 mt-2 mb-3"
                    onClick={doCleanDB}
                    disabled={processClean || true}
                  >
                    {!processClean ? "Clean DB" : <SpinnerText text="" />}
                  </Button>
                  <Button
                    variant="success"
                    className="ms-2 mt-2 mb-3"
                    onClick={() => {
                      dispatch(resetInputs());
                      dispatch(showAddModal());
                    }}
                  >
                    Add Entry
                  </Button>

                  <Button
                    variant="outline-danger"
                    className="ms-2 mt-2 mb-3"
                    onClick={reloadAll}
                    disabled={loading || true}
                  >
                    {!loading ? "Reload JSON" : <SpinnerText text="" />}
                  </Button>
                </div>
              </div>
            </Row>
            <Input
              type="text"
              name="search"
              value={search}
              placeholder="Quick Search"
              onChange={handleSearch}
              error={null}
              css="mb-3"
            />
            <DisplayItems
              data={filtered}
              deleteHandler={handleDelete}
              editHandler={handleEdit}
              viewHandler={handleView}
              uploadSuccessHandler={handleUploadSuccess}
            />
          </Container>
        </div>
      </div>
      <ComponentModals
        processing={processing}
        addHandler={performAdd}
        editHandler={performEdit}
        deleteHandler={confirmDelete}
      />
    </div>
  );
}

export default AdminControl;
