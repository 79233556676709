import React from "react";
import DisplayActions from "../../../common/DisplayActions";
import DisplayEntry from "./DisplayEntry";

function SingleItem({
  entry,
  addHandler = () => {},
  removeHandler = () => {},
  editHandler = () => {},
  viewHandler = () => {},
  deleteHandler = () => {},
  uploadSuccessHandler = () => {},
}) {
  const css = entry.hide ? { display: "none" } : { display: "block" };

  return (
    <div
      style={css}
      className="mt-2 px-3 pb-3 pt-0 roundcornernocolor alert bg-white shadow"
    >
      <div className="pt-3 d-flex justify-content-between align-items-center">
        <DisplayEntry
          entry={entry}
          uploadSuccessHandler={uploadSuccessHandler}
        />
      </div>
      <DisplayActions showlabel={true} css="" detail={entry} />

      <div className="my-0 py-0" style={{ minWidth: "100px" }}>
        <div className="my-0 py-0 d-flex justify-content-end">
          {!entry?.added && (
            <button
              className="mr-1 btn btn-sm btn-link text-dark fw-bold"
              onClick={() => addHandler(entry)}
            >
              Add
            </button>
          )}
          {entry?.added && (
            <button
              className="mr-1 btn btn-sm btn-link text-danger fw-bold"
              onClick={() => removeHandler(entry)}
            >
              Remove
            </button>
          )}
          {/* <button
            className="btn btn-sm btn-link text-dark"
            onClick={() => viewHandler(entry)}
          >
            View
          </button> */}
          {/* <button
            className="btn btn-sm btn-link text-dark"
            onClick={() => editHandler(entry)}
          >
            Edit
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default SingleItem;
