import React from "react";
import { useSelector } from "react-redux";
import DisplayActions from "../../../common/DisplayActions";
import { getInputs } from "../redux/form";

import DisplayEntry from "./DisplayEntry";

function ComponentModalViewForm() {
  const inputs = useSelector(getInputs);

  return (
    <div className="row">
      <div className="form-group col-lg-12 mb-0">
        <div className="p-3">
          <DisplayEntry detailed={true} entry={inputs} />
          <DisplayActions showlabel={true} css="" detail={inputs} />
        </div>
      </div>
    </div>
  );
}

export default ComponentModalViewForm;
