import React from "react";

function DisplayEntry({
  detailed = false,
  entry,
  uploadSuccessHandler = () => {},
}) {
  const { name, description, keywords } = entry;

  return (
    <div className="">
      <p className="fw-bold fs-4 mb-0">{name}</p>

      {description && (
        <div className="fs-5 font-weight-normal">{description}</div>
      )}
      <div className="my-2">
        {keywords.split(",").map((i, index) => {
          if (i.trim().length === 0) {
            return null;
          }
          return (
            <p
              key={index}
              className="me-1 my-1 badge dashedborder rounded-pill bg-light text-dark fw-normal"
              dangerouslySetInnerHTML={{
                __html: i.trim().toLowerCase(),
              }}
            ></p>
          );
        })}
      </div>
    </div>
  );
}

export default DisplayEntry;
