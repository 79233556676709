import React from "react";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";

function Report({ showicon = false, entry, variant }) {
  const title = variant !== "feedback" ? "Report" : "Feedback";
  const show =
    showicon === true ? (
      variant !== "feedback" ? (
        <OutlinedFlagIcon className="fs-5" />
      ) : (
        <FeedbackOutlinedIcon className="fs-5" />
      )
    ) : null;

  return (
    <a
      target="_blank"
      className="text-muted"
      rel="noopener noreferrer"
      href={`mailto:allcatholicsg@gmail.com?subject=${title}: ${encodeURIComponent(
        entry
      )}&body=Peace be with you! `}
    >
      <div className="text-muted pt-3 pb-2 pointer d-flex justify-content-end align-items-center">
        <span className="fs-6 me-2">{title}</span>
        {show}
      </div>
    </a>
  );
}

export default Report;
