import React from "react";
import SingleItem from "./SingleItem";

function DisplayItems({
  data,
  deleteHandler = () => {},
  addHandler = () => {},
  removeHandler = () => {},
  editHandler = () => {},
  viewHandler = () => {},
  uploadSuccessHandler = () => {},
}) {
  if (!data) {
    return null;
  }
  return data.map((i) => (
    <SingleItem
      key={i.id}
      entry={i}
      deleteHandler={deleteHandler}
      removeHandler={removeHandler}
      addHandler={addHandler}
      editHandler={editHandler}
      viewHandler={viewHandler}
      uploadSuccessHandler={uploadSuccessHandler}
    />
  ));
}

export default DisplayItems;
