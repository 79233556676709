import React from "react";
import { isValidPhotoSource } from "../../../utils/validate";
import ListDetails from "./ListDetails";

function ListTypeImageDetails({ name, photo, entry }) {
  return (
    <div className="row shadow lightframeborder ">
      {photo.length > 0 && isValidPhotoSource(photo) ? (
        <>
          <div className="text-center px-3 pt-3 col-sm-12 col-md-3 d-flex justify-content-center align-items-start">
            <img
              className="img-fluid roundedframeborder"
              src={photo}
              alt={name}
            />
          </div>

          <div className="col-sm-12 col-md-9 p-3">
            <ListDetails data={entry} showlabel={true} />
          </div>
        </>
      ) : (
        <div className="col-sm-12 p-4">
          <ListDetails data={entry} showlabel={true} />
        </div>
      )}
    </div>
  );
}

export default ListTypeImageDetails;
