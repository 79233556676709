import React from "react";
import { SPINNER_TYPE } from "../utils/settings";

const SpinnerText = ({ text, color }) => {
  return (
    <React.Fragment>
      <span
        className={`spinner-${SPINNER_TYPE} spinner-${SPINNER_TYPE}-sm ${color}`}
        role="status"
        aria-hidden="true"
      ></span>{" "}
      {text}
    </React.Fragment>
  );
};

export default SpinnerText;
