import React from "react";
import { getSuggestions } from "../utils/settings";
import SwipeButton from "./SwipeButton";
import { useNavigate } from "react-router-dom";

function Categories({ selected = "" }) {
  const navigate = useNavigate();

  const redirectSubmit = (s) => {
    const q = s?.trim();
    navigate(`/s/${q}`);
  };

  return (
    <SwipeButton
      entries={getSuggestions()}
      selected={selected}
      selectHandler={(i) => {
        redirectSubmit(i);
      }}
    />
  );
}

export default Categories;
