import React from "react";

function SuggestAdd({ entry }) {
  return (
    <a
      target="_blank"
      className="text-dark"
      rel="noopener noreferrer"
      href={`mailto:allcatholicsg@gmail.com?subject=Suggest Add: ${encodeURIComponent(
        entry
      )}&body=Peace be with you! I would like to suggest to add "${entry}" to AllCatholics.SG`}
    >
      <div className="pt-3 pb-2 pointer d-flex justify-content-end align-items-center">
        <span className="fs-5 me-2">
          Click to suggest to add <span className="fw-bold">"{entry}"</span>
        </span>
      </div>
    </a>
  );
}

export default SuggestAdd;
