import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { signInWithEmailAndPassword } from "firebase/auth";
import Input from "../../../common/Input";
import { isValidEmail } from "../../../utils/validate";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../services/fire";

function LoginForm({ user }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/temp", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const goBack = () => {
    navigate("/autosuggest", { replace: true });
  };

  const handleBlur = () => {
    const isEmailOk = isValidEmail(email);
    if (!isEmailOk) {
      setEmailErr("Invalid Email");
    } else {
      setEmailErr("");
    }
  };

  const handleSubmit = async () => {
    const isEmailOk = isValidEmail(email);
    if (!isEmailOk) {
      setEmailErr("Invalid Email");
    }
    if (password.length > 0 && isEmailOk) {
      setEmailErr("");
      setPasswordErr("");
      try {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            // const user = userCredential.user;
            // dispatch(setUserDetails(user.uid));
            navigate("/temp", { replace: true });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`${errorCode}: ${errorMessage}`);
          });
      } catch (e) {
        setPasswordErr("Incorrect email or password");
      }
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto d-flex justify-content-center">
      <Container className="py-4 justify-content-center">
        <Row>
          <Col lg={8} className="my-4">
            <h1>Login</h1>
          </Col>
        </Row>

        <Row>
          <Col lg={8} className="mb-4">
            <Input
              name="email"
              label="Email"
              value={email}
              error={emailErr}
              onBlur={handleBlur}
              onChange={({ currentTarget: input }) => {
                setEmail(input.value.toLowerCase());
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="">
            <Input
              type="password"
              name="password"
              label="Password"
              value={password}
              error={passwordErr}
              onChange={({ currentTarget: input }) => {
                setPassword(input.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="justify-content-end d-flex py-4">
            <Button
              variant="outline-secondary"
              className="px-3 me-4"
              size="lg"
              onClick={goBack}
            >
              Back
            </Button>
            <Button
              variant="pure"
              className="marianbutton px-4"
              size="lg"
              disabled={loading || email.length === 0 || password.length === 0}
              onClick={handleSubmit}
            >
              {loading ? (
                <div className="d-flex align-items-center">
                  <Spinner
                    className="me-1"
                    size="sm"
                    animation="border"
                    variant="light"
                  />
                  Logging...
                </div>
              ) : (
                "Login"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginForm;
