import React from "react";
import { formatTime } from "../../../utils/date";

function DisplayEntry({
  detailed = false,
  entry,
  uploadSuccessHandler = () => {},
}) {
  const { input, found, created, added } = entry;

  return (
    <div className="">
      <p className="fw-bold fs-4 mb-0">
        <a
          target="_blank"
          className={added ? "text-primary" : "text-dark"}
          rel="noopener noreferrer"
          href={`https://allcatholics.sg/s/${encodeURIComponent(input)}`}
        >
          {input}
        </a>
      </p>

      <div className="fs-5 font-weight-normal">
        About <b>{found}</b> results
      </div>
      <div className="my-0">{formatTime(created.seconds * 1000)}</div>
      {added && (
        <small>
          <span className="badge rounded-pill bg-success">ADDED </span>
        </small>
      )}
    </div>
  );
}

export default DisplayEntry;
