import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { getInputs, setInputChange } from "../redux/form";
import Input from "../../../common/Input";
import {
  FIELD_ADDRESS,
  FIELD_CATEGORY,
  FIELD_DESCRIPTION,
  FIELD_EMAIL,
  FIELD_FACEBOOK,
  FIELD_INSTAGRAM,
  FIELD_KEYWORDS,
  FIELD_NAME,
  FIELD_PHONE,
  FIELD_WEBSITE,
  FIELD_WHATSAPP,
} from "../utils/config";

function ComponentModalForm() {
  const dispatch = useDispatch();
  const inputs = useSelector(getInputs);

  const handleChange = ({ currentTarget: input }) => {
    const fields = {
      ...inputs,
      [input.name]: input.value,
    };
    const obj = { inputs: fields, name: input.name, value: input.value };
    dispatch(setInputChange(obj));
  };

  return (
    <div className="row">
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_NAME}
          value={inputs[FIELD_NAME]}
          label="Name"
          labelextra={<span className="text-danger">*</span>}
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>

      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_DESCRIPTION}
          value={inputs[FIELD_DESCRIPTION]}
          label="Description"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>

      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_KEYWORDS}
          value={inputs[FIELD_KEYWORDS]}
          label="Keywords"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_CATEGORY}
          value={inputs[FIELD_CATEGORY]}
          label="Category"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_EMAIL}
          value={inputs[FIELD_EMAIL]}
          label="Email"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_WEBSITE}
          value={inputs[FIELD_WEBSITE]}
          label="Website"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_FACEBOOK}
          value={inputs[FIELD_FACEBOOK]}
          label="Facebook"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_INSTAGRAM}
          value={inputs[FIELD_INSTAGRAM]}
          label="Instagram"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_PHONE}
          value={inputs[FIELD_PHONE]}
          label="Phone"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-xs-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_WHATSAPP}
          value={inputs[FIELD_WHATSAPP]}
          label="Whatsapp"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>

      <div className="form-group col-sm-12 mb-3 pb-0">
        <Input
          bold={true}
          type="text"
          name={FIELD_ADDRESS}
          value={inputs[FIELD_ADDRESS]}
          label="Address"
          placeholder=""
          onChange={handleChange}
          error={null}
        />
      </div>
      <div className="form-group col-sm-12 pt-4">
        <span className="text-danger">*</span> Required
      </div>
    </div>
  );
}

export default ComponentModalForm;
