import React from "react";
import { Container, Navbar } from "react-bootstrap";
// import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import logo from "../assets/images/allcatholics-logo.png";

function Header({ handler = () => {} }) {
  return (
    <Navbar bg="white" fixed="top" className="navshadow">
      <Container className="py-2">
        <Navbar.Brand
          href=""
          className="fw-bold align-items-center d-flex"
          onClick={handler}
        >
          <img
            src={logo}
            alt="AllCatholics.SG"
            width="50px"
            height="50px"
            className="me-1"
          />
          <span className="marianblue fs-1">AllCatholics.SG</span>
        </Navbar.Brand>

        {/* <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <div className="text-dark pointer" onClick={() => {}}>
              <ExitToAppRoundedIcon /> Logout
            </div>
          </Navbar.Text>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default Header;
