import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import UpdateModal from "../../updatemodal/page/UpdateModal";
// import Typewriter from "typewriter-effect";
import Tagline from "../../landing/components/Tagline";
import WideLogo from "../../../common/WideLogo";
import Categories from "../../../common/Categories";
// import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../services/fire";
import CustomSearch from "../components/CustomSearch";
import full from "../../../assets/images/back.jpg";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "react-autocomplete";
import Footer from "../../../common/Footer";
import AutoSuggest from "../../../common/AutoSuggest";
import FeaturedMasonry from "../../control/featured/page/FeaturedMasonry";
import InfiniteScrollList from "../components/InfiniteScrollList";
import SwipeButton from "../../../common/SwipeButton";
import { getSuggestions } from "../../../utils/settings";

let filterTimeout;

function MainAutoSuggestSearch() {
  // const { squery } = useParams();
  const navigate = useNavigate();
  // console.log({ squery });
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    // console.log("Before calling retrieve");
    const retrieve = httpsCallable(functions, "retrieve");
    retrieve().then((obj) => {
      // console.log(obj);
      const data = obj?.data?.message;
      console.log(data);
      setItems((prevItems) => [...prevItems, ...data]);
      // setLoading(false);
    });
  }, []);

  // console.log(items);

  // useEffect(() => {
  //   function callFunctionOnBottom() {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       // Call your function here
  //       setPage((prevPage) => prevPage + 1);

  //       console.log("Function called!");
  //     }
  //   }

  //   window.addEventListener("scroll", callFunctionOnBottom);
  //   return () => window.removeEventListener("scroll", callFunctionOnBottom);
  // }, []);

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  };
  const fetchData = () => {
    const retrieve = httpsCallable(functions, "retrieve");
    retrieve().then((obj) => {
      // console.log(obj);
      const data = obj?.data?.message;
      // console.log(data);
      setItems((prevItems) => [...prevItems, ...data]);
      // setLoading(false);
    });
  };
  const handleOnSelect = (item) => {
    // the item selected
    // console.log(item);
    // console.log("Selected");
    // if (item.name.length < 1) {
    //   return;
    // }
    setSuggestions([]);

    redirectSubmit(item.name);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };
  const formatResult = (item) => {
    return (
      <div className="pe-3">
        <div className="text-wrap py-1" style={{ lineHeight: "1.2em" }}>
          {item.name}
        </div>
      </div>
    );
  };

  const handleChange = (event) => {
    // event.preventDefault();
    setQuery(event.target.value);
    // console.log(event.target.value);
    // const autosearch = httpsCallable(functions, "autosearch");
    // autosearch({ query: event.target.value, entries: 10 }).then((data) => {
    //   // setResults(data);
    //   // setLoading(false);
    //   console.log(data);
    //   // addUserInputToDB(qs.trim(), data).then(() => {
    //   //   console.log(".");
    //   // });
    // });
  };

  const redirectSubmit = (s) => {
    const q = s;
    // const q = s.trim();
    navigate(`/s/${q}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (query.length < 1) {
    //   return;
    // }
    redirectSubmit(query);
  };

  const handleRedirectSubmit = (q) => {
    if (q.length < 2) {
      return;
    }
    redirectSubmit(q);
  };

  const autoSuggestQuery = (query) => {
    clearTimeout(filterTimeout);
    if (!query) return setSuggestions([]);

    filterTimeout = setTimeout(() => {
      // console.log("====>", query);

      const autosuggestsearch = httpsCallable(functions, "autosuggestsearch");
      autosuggestsearch({ query: query, entries: 10 }).then((data) => {
        // setResults(data);
        // setLoading(false);
        // console.log(data);
        const value = data.data.message.hits.map((i) => {
          // console.log(i);
          return { id: i.document.id, name: i.document.name.toLowerCase() };
        });

        setSuggestions(value);
        // addUserInputToDB(qs.trim(), data).then(() => {
        //   console.log(".");
        // });
      });
    }, 50);
  };

  const handleOnSearch = (string, results) => {
    // console.log("handleOnSearch: ", string);
    // console.log(suggestions);
    // if (string.trim().length === 0) {
    //   return;
    // }
    setQuery(string);

    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results);
    autoSuggestQuery(string);
  };

  const renderInput = (props) => {
    return (
      <input
        {...props}
        className="mt-2"
        placeholder="What are you looking for?"
        autoFocus={true}
        style={{
          border: "none",
          borderWidth: 0,
          boxShadow: "none",
        }}
      />
    );
  };

  // const scrollDiv = document.getElementById("scroll")?.offsetTop;
  const Test = () => (
    <div
      className="py-2"
      style={{
        borderRadius: "30px",
        border: "1px solid #dadada",
      }}
    >
      <div className="d-flex justify-content-start px-2">
        <div className="text-muted ps-1 pe-2">
          <SearchIcon />
        </div>
        <div className="text-muted text-truncate">
          What are you looking for?
        </div>
      </div>
    </div>
  );
  const ShowModal = () => (
    <Modal
      fullscreen={true}
      show={showModal}
      onHide={() => setShowModal(false)}
      animation={true}
      centered
      // backdrop="static"
      keyboard={false}
    >
      <div className="d-flex justify-content-center">
        <span
          className="p-2 pointer"
          onClick={() => {
            setQuery("");
            setShowModal(false);
          }}
        >
          <ArrowBackOutlinedIcon />
        </span>
        <span className="">
          <Autocomplete
            value={query}
            getItemValue={(item) => item.name}
            items={suggestions}
            renderInput={renderInput}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                style={{ background: isHighlighted ? "lightgray" : "white" }}
              >
                {item.name}
              </div>
            )}
            onSelect={handleOnSelect}
            onChange={(e) => handleOnSearch(e.target.value)}
          />
        </span>

        {/* <CustomSearch
     value={suggestions}
     handleOnSelect={handleOnSelect}
     onChange={handleOnSearch}
     formatResult={formatResult}
   /> */}
      </div>
    </Modal>
  );

  return (
    <div>
      <UpdateModal />

      <div className="py-4 mb-0">
        <div className="text-center d-flex flex-column vertical-center justify-content-center">
          <WideLogo showVersion={true} />
          <Tagline css="py-4 text-muted" />

          <Container id="scroll">
            <Row>
              <Col className="mx-auto" lg={8}>
                {/* <SubmitForm
                  value={query}
                  loading={false}
                  handleSubmit={handleSubmit}
                  changeHandler={handleChange}
                /> */}
                {/* <ReactSearchAutocomplete
                  items={suggestions}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  // formatResult={formatResult}
                /> */}
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {/* <div
                    className="d-block d-sm-none"
                    onClick={() => {
                      console.log("Focused");
                      setShowModal(true);
                    }}
                  >
                    <CustomSearch
                      autoFocus={false}
                      value={suggestions}
                      handleOnSelect={handleOnSelect}
                      onChange={(val) => {
                        setShowTagline(false);
                        handleOnSearch(val);
                      }}
                      formatResult={formatResult}
                    />
                  </div> */}
                  <div
                    className="d-nones d-sm-blocks mb-3"
                    style={{ zIndex: "1" }}
                  >
                    <CustomSearch
                      value={suggestions}
                      handleOnSelect={handleOnSelect}
                      onChange={handleOnSearch}
                      formatResult={formatResult}
                    />
                  </div>
                  {/* <CustomSearch
                    value={suggestions}
                    handleOnSelect={handleOnSelect}
                    onChange={handleOnSearch}
                    formatResult={formatResult}
                  /> */}
                </form>
                {/* <Categories /> */}
                <div className="pt-4 d-flex align-items-center justify-content-center">
                  <img
                    src={full}
                    alt="AllCatholics.SG Background"
                    height="180px"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mx-auto px-0" lg={10}>
                <FeaturedMasonry />
                {/* <Featured /> */}
                <div className="pb-0 d-flex justify-content-start">
                  <h5 className="text-dark px-3 fw-bold">
                    Explore AllCatholics.SG
                  </h5>
                </div>
                <div className="text-start ">
                  {/* <InfiniteScrollMasonry items={items} fetchData={fetchData} /> */}
                  <InfiniteScrollList items={items} fetchData={fetchData} />
                </div>
                <AutoSuggest handler={handleRedirectSubmit} />
              </Col>
            </Row>
            <Footer />
            {/* <Row>
              <Col>
                <div className="d-flex justify-content-center text-center">
                  <ContactBottom />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center align-items-center text-center">
                  <div className="pt-4 pb-5 text-muted">
                    Made with <FavoriteIcon className="text-danger" />
                  </div>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </div>
      <Container className="px-4">
        <Row>
          <Col className="px-4"></Col>
        </Row>
      </Container>

      {/* <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <ContactBottom />
      </div> */}
    </div>
  );
}

export default MainAutoSuggestSearch;
