import React from "react";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

function ContactUs({ entry }) {
  return (
    <a
      target="_blank"
      className="text-dark"
      rel="noopener noreferrer"
      href={`mailto:allcatholicsg@gmail.com?subject=${encodeURIComponent(
        entry
      )}&body=Peace be with you! `}
    >
      <div className="pointer lightframedashedborder p-2 m-2">
        <MailOutlineRoundedIcon fontSize="sm" className="me-2" />
        <span className="withtextdeco">Contact AllCatholics.SG</span>
      </div>
    </a>
  );
}

export default ContactUs;
