import React from "react";
import { Spinner } from "react-bootstrap";
import { SPINNER_TYPE } from "../utils/settings";

function SubmitButton({
  css = "my-4 px-5 marianbutton",
  label = "Notify Me",
  disabled,
  loading,
  loadingText = "Processing...",
  handler,
}) {
  return (
    <button
      className={`btn btn-lg ${css}`}
      size="lg"
      disabled={disabled || loading}
      onClick={handler}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            className=""
            size="sm"
            animation={SPINNER_TYPE}
            variant="light"
          />
          {loadingText}
        </div>
      ) : (
        <>{label}</>
      )}
    </button>
  );
}

export default SubmitButton;
