import React from "react";

function QuickSearch({
  label = <span className="me-2">Try:</span>,
  suggest,
  handler = () => {},
}) {
  return (
    <div className="fs-5 mt-2 py-2 ">
      <div className="d-flex justify-content-start flex-wrap">
        <span className="">{label}</span>
        {suggest.map((entry, index, { length }) => (
          <div
            key={index}
            className="quicksearch withtextdeco pointer me-2"
            onClick={() => handler(entry)}
          >
            {entry}
            {index + 1 === length ? "" : ","}
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuickSearch;
