import { createSlice } from "@reduxjs/toolkit";
import { FORM_INIT, FORM_NAME } from "../../utils/config";
import {
  addCustomValidateFieldWithExclude,
  validateAddForm,
} from "../../utils/validate";

const initialState = {
  inputs: FORM_INIT,
  allowsave: false,
};

const slice = createSlice({
  name: FORM_NAME,
  initialState,
  reducers: {
    setInputs: (state, action) => {
      const fields = action.payload;
      const isValid = addCustomValidateFieldWithExclude(fields, "");
      state.inputs = fields;
      //if input is valid, this will enable the SAVE button
      state.allowsave = isValid;
    },
    setDatePickerValue: (state, action) => {
      const DATE_PICKER_FIELD = "timestamp";
      const fields = action.payload;
      const isValid = validateAddForm(
        fields,
        DATE_PICKER_FIELD,
        fields[DATE_PICKER_FIELD]
      );
      state.inputs = fields;
      //if input is valid, this will enable the SAVE button
      state.allowsave = isValid;
    },
    setInputChange: (state, action) => {
      const fields = action.payload;
      const { inputs, name, value } = fields;
      const isValid = validateAddForm(inputs, name, value);
      state.inputs = inputs;
      state.allowsave = isValid;
    },
    setAllowSave: (state, action) => {
      state.allowsave = action.payload;
    },
    resetInputs: () => initialState,
  },
});

function getSlice(state) {
  return state[FORM_NAME];
}
//setters
export const { setInputs, setInputChange, setDatePickerValue, resetInputs } =
  slice.actions;

//getters
export const getInputs = (state) => getSlice(state).inputs;
export const getAllowSave = (state) => getSlice(state).allowsave;

export default slice.reducer;
