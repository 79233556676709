import {
  query,
  getDocs,
  setDoc,
  collection,
  where,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../services/fire";

export async function addEntryToDB(entry) {
  const ref = collection(db, "entries");
  const docRef = doc(ref);
  const obj = {
    ...entry,
    id: docRef.id,
  };
  await setDoc(docRef, obj);
  return obj;
}

export async function updateEntryById(id, data) {
  console.log(id);
  console.log(data);
  const ref = doc(db, "entries", id);

  await updateDoc(ref, { ...data });
}

export async function deleteEntryFromDB(entryId) {
  await deleteDoc(doc(db, "entries", entryId));
}

export function getDataEntries() {
  const test = true;
  if (test) {
    return getStaticData();
  } else {
    return getDataEntriesFromDB();
  }
}

export async function getDataEntriesFromDB(sort = true) {
  const results = [];
  const ref = collection(db, "entries");
  const q = query(ref, where("name", "!=", ""));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
    // console.log(`${doc.id} => ${doc.data()}`);
  });

  if (sort === true && results.length > 0) {
    results.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return results;
}

export function getStaticData() {
  return [
    {
      name: "Religious of the Cenacle (RC)",
      description:
        "The Religious of the Cenacle is an international religious congregation called to participate in the transformation of the world by awakening and deepening faith with and for the people of our times. We work to enable people to discover God in the world and in their lives. We accompany and support others in their search for meaning and direction in life, through retreats and spiritual direction, biblical apostolate, psycho-spiritual formation, parish animation, religious education and pastoral counselling.",
      category: "Organisation",
      keywords:
        "donate, vocation, retreats, spiritual direction, talks, mentoring, counselling, depression",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCenacle-Sisters.gif?alt=media&token=670d4b45-d536-4293-bfc1-3893a32c64eb",
      link: "",
      website: "http://www.cenaclemission.com/",
      email: "cenaclemissionsingapore@gmail.com",
      phone: "+65 6565 2895",
      whatsapp: "",
      facebook: "https://www.facebook.com/cenacleSG/",
      instagram: "",
    },
    {
      name: "Roman Catholic Prison Ministry (RCPM)",
      description:
        "Aims to share the love of Jesus with inmates in order to help them achieve peace and hope through the grace of God and by their suffering; RCPM engages in Eucharistic Celebration / Communion Service, Scriptures Sharing, Faith Exploration, Development & Formation, Administering the Sacraments of Baptism, Confirmation, Reconciliation, Annointing of the Sick & Condemned, Intercessory Prayer; Inner Healing; & Counselling, Pastoral Assistance",
      category: "Organisation",
      keywords: "support group, counselling, healing, annointing",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FRoman-Catholic-Prison-Ministry.jpg?alt=media&token=e057904f-0333-448b-b7ed-41d33b9e163a",
      link: "",
      website: "https://rcpm.org.sg/",
      email: "ask.rcpm@rcpm.org.sg",
      phone: "+65 6100 7276",
      whatsapp: "",
      facebook:
        "https://www.facebook.com/Roman-Catholic-Prison-Ministry-159023504196728",
      instagram: "",
    },
    {
      name: "Catholic Aids Response Effort (CARE)",
      description:
        "CARE provides shelter for destitute and homeless People Living With HIV/AIDS (PLWHA). We gather them together for medical, emotional and spiritual care, and counselling, and assist them to apply for financial assistance.",
      category: "Organisation",
      keywords:
        "aids support, shelter, outreach, support for children with aids, support group, volunteer",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Aids-Response.webp?alt=media&token=d7597210-fc9c-41f6-98d7-54f7ad2684dc",
      link: "",
      website: "https://catholicaidsresponseeffort.com/",
      email: "administrator@care.org.sg",
      phone: "+65 6353 5440",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Agape Human Wellness Group",
      description:
        "Agape Human Wellness Group is a Singapore-based charity committed to supporting children, youth and special groups of low-income families and impoverished communities.",
      category: "Organisation",
      keywords:
        "poverty, charity, less privileged, donate food for Malaysia and India, support group, depression",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAGAPE.webp?alt=media&token=2fc3ddba-861a-493f-8afa-9631174c4756",
      link: "",
      website: "https://www.ahwgroup.net/",
      email: "anthony_kathy@hotmail.com",
      phone: "+65 9387 6348",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Archdiocesan Land & Properties Singapore (ALPS)",
      description:
        "Archdiocesan Land & Properties Singapore is the property arm of the Catholic Church in Singapore. ",
      category: "Archdiocese",
      keywords: "land, property, property management",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FALPS-blk.jpg?alt=media&token=e181be9a-6637-4df4-8885-0352ca6f6e4c",
      link: "",
      website: "",
      email: "alps@catholic.org.sg",
      phone: "+65 6906 8440",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Archdiocesan Commission for Apostolate of Mandarin-speaking (ACAMS)",
      description:
        "ACAMS serve as the highest coordinating and strategizing body among the mandarin speaking groups in the Archdiocese of Singapore to promote the apostolate works of the Mandarin-speaking Community.",
      category: "Archdiocese",
      keywords: "community for Mandarin-speaking, Chinese community",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FArchdiocesanCommissionForApostolateOfMandarinSpeaking(ACAMS).jpg?alt=media&token=893d4f55-4a47-4069-86ed-f66506b82215",
      link: "",
      website: "https://acams.org.sg/",
      email: "info.acams@catholic.org.sg",
      phone: "+65 6431 9890",
      whatsapp: "",
      facebook: "https://www.facebook.com/CAMSjiaowei/",
      instagram: "",
    },
    {
      name: "Assisi Hospice",
      description:
        "Assisi Hospice provides palliative care for patients and families that preserves their dignity and quality of life. ",
      category: "Organisation",
      keywords: "care for patients, care for the sick,",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAssisiHospice.jpg?alt=media&token=1f5538a9-4327-42c7-9cfe-9710e7cee958",
      link: "",
      website: "https://www.assisihospice.org.sg/",
      email: "assisi@assisihospice.org.sg",
      phone: "+65 6832 2650",
      whatsapp: "",
      facebook: "https://www.facebook.com/assisihospice",
      instagram: "https://www.instagram.com/assisi_hospice/",
    },
    {
      name: "Beginning Experience Singapore",
      description:
        "Beginning Experience helps those suffering the loss of a love relationship through death, divorce or separation and ready to come to terms with that loss, begin to move forward with their lives.",
      category: "Organisation",
      keywords:
        "support for widows, support for divorce, support for separated, support group, separated, single parent, deceased spouse, deceased husband, deceased wife, former spouse",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fbeginningexperience.jpg?alt=media&token=1202c4e0-7a9f-461f-aba8-171613b175ea",
      link: "",
      website: "https://www.besingapore.com/",
      email: "beginningexperiencesg@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Boys’ Town Clinical Intervention Centre",
      description:
        "The Boys’ Town Clinical Intervention Centre provides psychological interventions through assessments, counselling, psychotherapy, behavioural management, group work and other psychological services. ",
      category: "Organisation",
      keywords:
        "support for young boys, support for boys, youth, residential, adventure center, clinical intervention, youth outreach, support group, teenage depression, child depression, young male",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FBoysTownClinicalInterventionCenter.jpg?alt=media&token=04e89d5a-25bb-4fa8-a527-03701ae579ca",
      link: "",
      website: "https://www.boystown.org.sg/",
      email: "info@boystown.org.sg",
      phone: "+65 6690 5420",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossian Village",
      description:
        "The Canossian Village carries out social mission through education. We seek to provide for the needs of the children and families who come through our gates, with a preferential option for the poor.",
      category: "Organisation",
      keywords: "social service for children, children care, student care",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcanossianvillage.png?alt=media&token=777c847c-0437-4d29-92a4-51dce8d7d0e0",
      link: "",
      website: "https://canossaville.org.sg/",
      email: "hello@canossaville.org.sg",
      phone: "+65 8401 4073",
      whatsapp: "",
      facebook: "https://www.facebook.com/CanossavilleCCS",
      instagram: "https://www.instagram.com/canossaville/",
    },
    {
      name: "Canossian Daughters of Charity",
      description:
        "The Canossian Daughters of Charity seek to show God’s love through passionate, joyful, unconditional service to the poorest, thus bringing meaning to their lives. The Sisters are engaged in a wide range of ministries of charity to serve all who are in need, especially the poorest, thus bringing meaning to their lives.",
      category: "Religious Order",
      keywords:
        "Religious, catechisis, pastoral, education, youth, prison, inter-religious, faith formation, spiritual direction, Myanmar, Cambodia, Singapore",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCanossianDaughtersofCharity.jpg?alt=media&token=b9a9deab-41ca-4c57-af05-74117ac5f23b",
      link: "",
      website: "http://www.canossians-sg.org/",
      email: "csbakhita@yahoo.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossaville Children and Community Services (CCCS)",
      description:
        "Canossaville Children and Community Services (Canossaville) is a Catholic social service agency, serving and impacting the lives of children and their families across their preschool and primary years, regardless of race, language, or religion. The sectors under Canossaville include Canossaville Preschool – A mainstream preschool specialising in supporting children with hearing loss, Canossaville Student Care – An integrated student care centre serving the needs of students from The Canossian Village, Canossian Child Development Unit – A multidisciplinary team of professionals supporting the children and their families in The Canossian Village across the areas of learning, behavioural, and psychosocial needs, Canossian EIPIC (Early Intervention Programme for Infants and Children) – A one-stop support for children aged 6 years and below with hearing loss",
      category: "Community",
      keywords: "volunteer, internship, children support, hearing loss",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcanossaville_logo.jpg?alt=media&token=b0d67b50-2237-4582-b80f-b811f0cdb372",
      link: "",
      website: "https://canossaville.org.sg/",
      email: "hello@canossaville.org.sg",
      phone: "+65 8401 4073",
      whatsapp: "",
      facebook: "https://www.facebook.com/CanossavilleCCS",
      instagram: "https://www.instagram.com/canossaville/",
    },
    {
      name: "Caritas Singapore",
      description:
        "Caritas Singapore is the official social and community arm of the Catholic Church in Singapore, whose work has touched the lives of many regardless of race or religion.",
      category: "Organisation",
      keywords:
        "charity, donate, outreach, volunteer, migrant crisis, social services",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCaritasSingapore.jpg?alt=media&token=2ef0eff6-e1a1-4009-ac33-092e71cb3692",
      link: "",
      website: "https://www.caritas-singapore.org/",
      email: "info@caritas-singapore.org",
      phone: "+65 6801 7400",
      whatsapp: "",
      facebook: "https://www.facebook.com/CaritasSingapore/",
      instagram: "",
    },
    {
      name: "Cathedral of the Good Shepherd ",
      description:
        "Built in 1847, the Cathedral of the Good Shepherd is the oldest Roman Catholic Church in Singapore and is a gazetted National Monument. This is our Mother Church situated near City Hall Mrt.",
      category: "Chruch",
      keywords: "Church, Cathedral, Mother Church, City District",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcathedral.jpeg?alt=media&token=f765585b-2148-4813-8f87-aa386db91482",
      link: "",
      website: "https://cathedral.catholic.sg",
      email: "cathedral@catholic.org.sg",
      phone: "+65 6337 2036",
      whatsapp: "",
      facebook: "https://www.facebook.com/CathedralOfTheGoodShepherd/",
      instagram: "https://www.instagram.com/cathedralofthegoodshepherd/",
    },
    {
      name: "Catholic Business Network",
      description:
        "Catholic Business Network (CBN) brings together Catholic business owners, professionals and executives to advance Catholic values and ethics in their work and serve the broader community. ",
      category: "Organisation",
      keywords:
        "employment, find job, Business News, Business Community, c-suite, golf, entrepreneur, workplace, work, colleague, Career Counselling, networking, inspirational talks, fund raising, fellowship",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholicBusinessNetwork.png?alt=media&token=2ba01bca-e57a-4582-851b-c373a113985e",
      link: "",
      website: "https://www.cbn.sg/",
      email: "admin@cbn.sg",
      phone: "+65 6801 7491",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Divorce Support Group",
      description:
        "A Christ-centred support group to bring hope and healing for persons who are divorced or separated and their families, and to help them respond positively to the universal call to holiness",
      category: "Organisation",
      keywords:
        "divorce, annulment, separation, tribunal, separate, counselling",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcatholicdivorcegroup.jpg?alt=media&token=8d27a485-fc66-45af-a2db-b58444d20a4c",
      link: "",
      website: "https://www.catholicdivorce.sg/",
      email: "catholicdivorce.sg@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Family Life",
      description:
        "Catholic Family Life seeks to form, empower and restore families at all stages of development through education, formation and counselling programmes in collaboration with partners and affiliates.",
      category: "Organisation",
      keywords:
        "Counselling, Depression, Stress, Parent, Child, Therapy, Crisis, Pregnancy, Marriage Preparation, Study, Anxiety, Abortion, mental health",
      prodserve:
        "Pregnancy Crisis and Support, Rachels Vineyard, Counselling, Therapy",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic%20Family%20Life.jpg?alt=media&token=9bbf1ae2-6d6b-4046-b38b-a12c19be45df",
      link: "",
      website: "https://www.familylife.sg/",
      email: "cfl@familylife.sg",
      phone: "+65 6488 0278",
      whatsapp: "",
      facebook: "https://www.facebook.com/CatholicFamilyLifeSG",
      instagram: "https://www.instagram.com/CatholicFamilyLifeSG/",
    },
    {
      name: "Catholic Foundation",
      description:
        "Catholic Foundation is the fundraising arm of the Archdiocese of Singapore. Its Giving in Faith & Thanksgiving (GIFT) programme promotes Christian giving for a vibrant, evangelising and missionary Church.",
      category: "Archdiocese",
      keywords: "Donate, Give Money, Contribute Money",
      prodserve: "Fundraising",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCF.jpg?alt=media&token=84c3f5a0-cad1-4ead-a0b1-df8703811f4b",
      link: "",
      website: "https://www.catholicfoundation.sg/",
      email: "cf@catholic.org.sg",
      phone: "+65 6338 0348",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Lawyers Guild",
      description:
        "The Catholic Lawyers Guild (CLG) provides legal aid to the financially poor and disadvantaged, regardless of religious faith or nationality.  ",
      category: "Guild",
      keywords:
        "legal aid, legal advice, Free Legal Consultation, family issues, estates, employment, personal accidents, tenancy disputes, monetary claims, motor accident, industrial accident claims, migrant support",
      prodserve: "Pro Bono Legal Clinic, Mediation",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholicLawyersGuild.jpg?alt=media&token=3ddf5f85-74d1-425e-a9d7-422aaad224db",
      link: "",
      website: "http://clgsingapore.com/",
      email: "admin@clgsingapore.com",
      phone: "+65 6801 7494",
      whatsapp: "",
      facebook: "https://www.facebook.com/clgsingapore/",
      instagram: "https://www.instagram.com/clgsingapore/",
    },
    {
      name: "Catholic Leadership Centre",
      description:
        "Catholic Leadership Centre forms and equip Catholic leaders to build a vibrant, evangelising and missionary Church through Training and Consulting. ",
      category: "Organisation",
      keywords:
        "servant leadership, community building, parish training, Parish Pastoral Council, lay leaders, Leadership formation",
      prodserve: "Leadership Training, Parish Consulting",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic%20Leadership%20centre%20logo.png?alt=media&token=65fd29d4-3bdf-4da3-b3ef-ec935fe5aaaa",
      link: "",
      website: "https://catholicleader.sg/",
      email: "enquiry.clc@catholic.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Preschool Education (Singapore) Ltd ",
      description:
        "Catholic Preschool Education (Singapore) is archdiocesan organisation that runs over 10 Catholic preschools (kindergartens and child care centres). ",
      category: "Nursery School",
      keywords:
        "preschool, Little Shepherds’ Schoolhouse, nursery, kindergarten, infant, pedagogy, Kids, Education, Learning",
      prodserve: "Childcare, Kindergarten",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholicPreschoolEducationSingaporeLtd.jpg?alt=media&token=9d7d2580-882b-4faf-9006-42dec5012194",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg/",
      email: "enquiries@catholicpreschool.edu.sg",
      phone: "+65 9437 5372",
      whatsapp: "",
      facebook: "https://www.facebook.com/LSSCPES",
      instagram: "https://www.instagram.com/littleshepherdsschoolhouse/",
    },
    {
      name: "Catholic Theological Institute of Singapore (CTIS)",
      description:
        "The Catholic Theological Institute of Singapore was established for the systematic formation in Catholic theology, in faithfulness to Holy Scripture and the Magisterium of the Catholic Church.",
      category: "Organisation",
      keywords:
        "Theology, Seminar, course, school, Catechesis, Faith Formation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCTIS.jpg?alt=media&token=1c0842d7-e021-4c77-aecb-df4bd2a63464",
      link: "",
      website: "https://www.ctis.sg/",
      email: "admin@ctis.sg",
      phone: "+65-6902 8700",
      whatsapp: "",
      facebook: "https://www.facebook.com/ctissingapore",
      instagram: "",
    },
    {
      name: "Catholic Theology Network ",
      description:
        "Catholic Theology Network assists its members in understanding the theological foundation for the Church’s teachings. It provides talks, courses, and holds conferences related to theology.  ",
      category: "Organisation",
      keywords: "theology seminar, course, conference",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholicTheologyNetwork.jpg?alt=media&token=20c620c2-52a9-4b86-849d-8446adbcd68a",
      link: "",
      website: "https://www.a-z.ctn.sg/",
      email: "theology.sg@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/CatholicTheologyNetwork/",
      instagram: "",
    },
    {
      name: "Catholic Welfare Services",
      description:
        "Catholic Welfare Services (CWS) helps the poor, destitute and those in need. It runs five homes for the aged (St Theresa’s, St Vincent, Gift of Love, Villa Francis, St Joseph’s) and a crisis centre.",
      category: "Organisation",
      keywords:
        "welfare, charity, support, financial assistance, food relief, social work support, outreach, bursary awards, nursing home, shelter, youth outreach, homeless, scholarship, counselling, depression",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCWSS.jpg?alt=media&token=e56dc5e1-813d-45df-9cb4-2cd01a0d7a15",
      link: "",
      website: "http://catholicwelfare.org.sg/",
      email: "generalenquiries@catholicwelfare.org.sg",
      phone: "+65 6337 7954",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Caritas Humanitarian Aid Relief Initiatives Singapore (CHARIS)",
      description:
        "Caritas Humanitarian Aid & Relief Initiatives, Singapore (CHARIS) is the umbrella body for overseas humanitarian aid by the Archdiocese of Singapore. ",
      category: "Organisation",
      keywords:
        "charity, relief, aid, disaster, calamity, overseas, outreach, mission trips",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHARIS.png?alt=media&token=ffd8361d-9e2d-4f93-887d-74aac31f2903",
      link: "",
      website: "https://www.charis-singapore.org/",
      email: "info@charis-singapore.org",
      phone: "+65 6337 4119",
      whatsapp: "",
      facebook: "https://www.facebook.com/CHARISingapore/",
      instagram: "https://www.instagram.com/charis_singapore/",
    },
    {
      name: "Clarity Singapore Limited",
      description:
        "Clarity Singapore is a mental health charity which delivers community mental health services in the individual or group settings.  ",
      category: "Organisation",
      keywords:
        "mental health, support, counselling, Group Therapy, depression, anxiety, psychological problems, Psychotherapy",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FClaritySingapore.jpeg?alt=media&token=cdf775f7-2385-4f22-8d7e-2eb64e82339d",
      link: "",
      website: "https://www.clarity-singapore.org/",
      email: "ask@clarity-singapore.org",
      phone: "+65 6757 7990",
      whatsapp: "",
      facebook: "https://www.facebook.com/claritysg/",
      instagram: "https://www.instagram.com/clarity_sg/",
    },
    {
      name: "Couple Empowerment Programme ",
      description:
        "The Couple Empowerment Programme (CEP) is an Archdiocesan-approved Post-Marriage Catechesis and formation programme based on Catholic Church teachings. ",
      category: "Community",
      keywords:
        "couple community, couple programme, relationship development, marriage programme",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCoupleEmpowermentProgramme.jpeg?alt=media&token=f0e053ad-2767-4c8d-93ff-638fc311a777",
      link: "",
      website: "http://www.cep-sg.org/",
      email: "goto_cep@hotmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/SG.CEP/",
      instagram: "",
    },
    {
      name: "Couples for Christ Singapore",
      description:
        "Couples for Christ Singapore is a worldwide family renewal ministry, whose mission is to renew and build strong Christian marriages and families within the parishes where it is present.  ",
      category: "Organisation",
      keywords:
        "couple community, couple programme, relationship development, marriage programme, Mission trip, faith formation, cfc",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCouplesForChristSG.jpg?alt=media&token=85832f71-1f99-49cf-9175-d6e46d7ddb93",
      link: "",
      website: "https://couplesforchrist.org.sg/",
      email: "cfc@catholic.org.sg",
      phone: "+65 6284 1880",
      whatsapp: "",
      facebook: "https://www.facebook.com/CouplesforChristSingapore",
      instagram: "",
    },
    {
      name: "Crossings Café ",
      description:
        "Crossings Café is a Catholic social enterprise that serves quality food. It employs people with special needs, provides meals to beneficiaries and donates its profits to charity. Crossings Cafe collaborates with organisations such as Assumption Pathway School and Bettr Barista to take on interns and graduates to work at the café as servers, cooks and baristas.",
      category: "Restaurant",
      keywords:
        "social enterprise, cafe, charity, special needs jobs, internship",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCrossingsCafe-1.jpg?alt=media&token=c97725c5-936d-4cca-9972-253fe51e5672",
      link: "",
      website: "https://crossingscafe.com.sg/",
      email: "manager@crossingscafe.com.sg",
      phone: "+65 6338 2669",
      whatsapp: "",
      facebook: "https://www.facebook.com/crossingscafe",
      instagram: "https://www.instagram.com/crossingscafe/",
    },
    {
      name: "Focolare Movement",
      description:
        "Focolare, an international lay movement that promotes the ideals of unity and universal brotherhood",
      category: "Organisation",
      keywords: "community, Mission Trip, Philippines, Indonesia",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FFocolareMovement.jpeg?alt=media&token=125727ba-0338-46ae-a814-b14ceebf3aaa",
      link: "",
      website: "https://www.focolare.org/",
      email: "fm.indonesia@focolare.org.sg",
      phone: "+65 9776 7768",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "The Institute for World Evangelisation - ICPE Mission",
      description:
        "The Institute for World Evangelisation - ICPE Mission is a Catholic Mission committed to world evangelisation, with its members forming communities in various locations worldwide, including Singapore",
      category: "Organisation",
      keywords: "Mission trip, Philippines, medical, Faith Formation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FInstituteForWorldEvangelisation-ICPEMission.jpg?alt=media&token=6a23c4a8-c533-4f63-b886-295db2854694",
      link: "",
      website: "https://icpesg.wordpress.com/",
      email: "icpe.sg@gmail.com",
      phone: "+65 9829 1382",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Landings Singapore",
      description:
        "Landings is a ministry that welcomes returning Catholics back to the Church. We are a reconciliation ministry based on compassionate listening, where participants share their faith stories and explore Catholic themes confidentially and without judgement.  At Landings, we gather in small groups and share our faith stories.",
      category: "Community",
      keywords: "returning catholics, community, rejoin catholics",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLandingsSingapore.jpg?alt=media&token=44e94079-3f73-406c-817e-b05f23b34c5a",
      link: "",
      website: "https://landings.org.sg/",
      email: "singapore@landing.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/landings.sg/",
      instagram: "https://www.instagram.com/landings.sg/",
    },
    {
      name: "Mamre Oaks",
      description:
        "Mamre Oaks hopes to reach out to those aged 16 and above with mild to moderate intellectual and developmental disabilities to provide a hospitable place that is safe and conducive.",
      category: "Community",
      keywords:
        "intellectual disability, support for low income, volunteer, special child, autism, down syndrome, volunteer, donate, workshops, special needs, mission",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMamreOaks.jpg?alt=media&token=38fcf090-a443-4afd-9075-e630aacafb44",
      link: "",
      website: "https://www.mamreoaks.sg/",
      email: "webmail@mamreoaks.sg",
      phone: "+65 6978 7800",
      whatsapp: "",
      facebook: "https://www.facebook.com/mamreoaksltd",
      instagram: "https://www.instagram.com/mamre_oaks_ltd/",
    },
    {
      name: "Mount Alvernia Hospital",
      description:
        "Mount Alvernia Hospital’s mission is whole person health and pastoral care. It is not-for-profit, providing general acute tertiary care with two multi-disciplinary medical centres.",
      category: "Hospital",
      keywords:
        "hospital, medical, dental, healthcare, outreach, health screening, day surgery, diagnostic imaging, rehabilitation, 24h walk-in accident, emergency",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fmountalvernia.jpg?alt=media&token=0c5ccde2-ff56-48b0-bbc5-6abc86c11feb",
      link: "",
      website: "https://mtalvernia.sg/",
      email: "enquiry@mtalvernia.sg",
      phone: "+65 6347 6650",
      whatsapp: "",
      facebook: "https://www.facebook.com/mtalverniahospital/",
      instagram: "https://www.instagram.com/mtalverniahospital/",
    },
    {
      name: "Office For the New Evangelisation (ONE)",
      description:
        "The Office for the New Evangelisation’s mission is to facilitate processes and initiatives to move baptised Catholics into becoming missionary disciples.",
      category: "Organisation",
      keywords: "ONE",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOfficeForTheNewEvangelisation.jpg?alt=media&token=f743e8a3-3d46-4463-8751-3ab9f0cb89f4",
      link: "",
      website: "https://one.org.sg/",
      email: "enquiries.one@catholic.org.sg",
      phone: "+65 6911 0420",
      whatsapp: "",
      facebook: "https://www.facebook.com/officeforthenewevangelisation",
      instagram: "https://www.instagram.com/evangelise.sg/",
    },
    {
      name: "Office For Young People",
      description:
        "The Office for Young People ministers to young people from 16 to 35 years of age. We seek to raise up a generation of young people passionately in love with Jesus and His Church  ",
      category: "Organisation",
      keywords:
        "community, OYP, youth, university, poly, ITE, tertiary, NSF, working adults, teenager, teen support, students, youth formation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOYP%20Logo.jpg?alt=media&token=6469c236-e2c4-4dbc-93b5-429ac2749bdf",
      link: "",
      website: "https://oyp.org.sg/",
      email: "info.oyp@catholic.org.sg",
      phone: "+65 6285 2571",
      whatsapp: "",
      facebook: "https://www.facebook.com/oyp.sg/",
      instagram: "https://www.instagram.com/oyp.sg",
    },
    {
      name: "Opus Dei Prelature",
      description:
        "Opus Dei aims to promote the message that all the baptised are called to Christian perfection – to holiness – through the fulfilment of their work and their daily duties.",
      category: "Religious Order",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOpusDeiLogo.png?alt=media&token=c157585e-4f8b-4cac-84de-3ac229173c51",
      link: "",
      website: "https://opusdei.org/en-sg",
      email: "singapore.sg@opusdei.org",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Queen of Peace (Parent Catechetical Ministry)",
      description:
        "The mission of the Queen of Peace (Parent Catechetical Ministry) is to form and build a community of parents who are good Catholic role models for their children. ",
      category: "Cathecism",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOLQP.jpg?alt=media&token=b85f556d-7f87-45c1-9661-4c723b9322bd",
      link: "",
      website: "https://www.queenofpeace.sg/parentcatecheticalministry",
      email: "qopcatechism@gmail.com",
      phone: "+65 8454 2834",
      whatsapp: "+65 8454 2834",
      facebook: "",
      instagram: "",
    },
    {
      name: "Redemptorists",
      description:
        "The Congregation of the Most Holy Redeemer (Redemptorists) was founded by St. Alphonsus de Ligouri in 1732. The mission statement of the Redemptorists is to preach the Gospel to the poor and abandoned.",
      category: "Religious Order",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FRedemptorists.jpg?alt=media&token=16400e31-6a83-4b00-971f-987c3e9c3102",
      link: "",
      website: "https://novenachurch.com/redemptorist-community/",
      email: "secretariatcssr@novenachurch.com",
      phone: "+65 6255 2133",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Francis Xavier Major Seminary",
      description:
        "St Francis Xavier Major Seminary forms and prepares seminarians to be priests who are passionate disciples in brotherhood and shepherds after the heart of Christ for the Archdiocese of Singapore. ",
      category: "Seminary ",
      keywords:
        "seminarian, priesthood, brother, vocation, become a priest, become a brother",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FStFrancisXavierMajorSem.jpg?alt=media&token=b57f1989-e8e3-4040-bfd6-0c37d27bd593",
      link: "",
      website: "https://seminary.catholic.sg/",
      email: "sfxms.secretariat@catholic.org.sg",
      phone: "+65 6906 8450",
      whatsapp: "",
      facebook: "https://www.facebook.com/seminarianssg",
      instagram: "",
    },
    {
      name: "Verbum Dei Missionaries (FMVD)",
      description:
        "The Verbum Dei Missionary Fraternity is a Catholic contemplative-active community dedicated to prayer and preaching of the Word of God. We are also present in 37 countries.",
      category: "Organisation",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fverbum-dei.png?alt=media&token=99a3118f-2150-46e1-8b3b-b8e45c6e53b8",
      link: "",
      website: "https://verbumdeisingapore.org/",
      email: "verbumdeispore@yahoo.com.sg",
      phone: "+65 6274 0251",
      whatsapp: "",
      facebook: "https://www.facebook.com/vdsing",
      instagram: "",
    },
    {
      name: "Worldwide Marriage Encounter ",
      description:
        "The Worldwide Marriage Encounter experience helps couples to dialogue with each other and God, to create deeper understanding of marriage and providing a loving environment for their children. ",
      category: "Organisation",
      keywords: "couple programme, family programme, relationship development",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FWorldwideMarriageEncounter.jpeg?alt=media&token=962269cb-451a-4314-8f8d-d6693d288d21",
      link: "",
      website: "https://wwmesg.org/",
      email: "me_secretariat@wwmesingapore.com",
      phone: "+65 9670 5390",
      whatsapp: "",
      facebook: "https://www.facebook.com/wwmesingapore",
      instagram: "",
    },
    {
      name: "Singapore Archdiocesan Catholic Charismatic Renewal (SACCRE)",
      description:
        "SACCRE is the principal coordinating organization of the Catholic Charismatic Renewal within the Archdiocese of Singapore.",
      category: "Organisation",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSACCRE.jpeg?alt=media&token=12a536ff-3515-46ac-a010-6e52dc8326e1",
      link: "",
      website: "",
      email: "saccre.resource.centre@gmail.com",
      phone: "+65 6280 7003",
      whatsapp: "",
      facebook: "https://www.facebook.com/saccre.sg/",
      instagram: "",
    },
    {
      name: "Church of Sts Peter & Paul",
      description:
        "The Church of Sts Peter and Paul was completed in 1870 and became the “seat” of the Chinese community in Singapore. It is the second oldest Church in Singapore, and is a national monument. It is currently run by the Order of Discalced Carmelites.",
      category: "Church",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FChurchOfStsPeterAndPaul.jpeg?alt=media&token=b9f179fa-5605-4f6b-9474-16d5c6e92115",
      link: "",
      website: "https://sppchurch.org.sg",
      email: "sts_peternpaul@singnet.com.sg",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "https://www.facebook.com/sppchurchsg/",
      instagram: "",
    },
    {
      name: "Archdiocesan Emergency Response Operations (AERO)",
      description:
        "AERO is responsible for security and fire safety considerations of the Archdiocese. It coordinates ground emergency response teams of the churches in the archdiocese",
      category: "Archdiocese",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAERO.png?alt=media&token=39e415e4-f791-4f93-a520-9800a1775cb3",
      link: "",
      website: "https://aero.catholic.sg",
      email: "aero@catholic.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Joseph’s Church (Victoria Street) ",
      description:
        "St Joseph’s Church (Victoria Street) was constructed in 1906. It was built in the Gothic style of the Portugese mission. In 1999, the Church stopped being a Portugese Church and became fully anchored in the Archdiocese of Singapore. The Church, gazetted as  national monument in 2005, has been under restoration since 2017. ",
      category: "Church",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt%20Joseph%20church%20logo.png?alt=media&token=fa5e9147-bffe-4a40-9b34-cd9af96b7210",
      link: "",
      website: "https://sjcvs.org.sg/",
      email: "sjc.secretariat@catholic.org.sg",
      phone: "+65 6338 3167",
      whatsapp: "",
      facebook: "https://www.facebook.com/StJosephVS",
      instagram: "",
    },
    {
      name: "Pregnancy Crisis and Support",
      description:
        "Pregnancy Crisis and Support, under Catholic Family Life, provides support for women with unplanned and unsupported pregnancies and assists them to make an informed decision.",
      category: "Organisation",
      keywords: "pregnancy support, unplanned, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FPregnancyCrisisAndSupport.jpg?alt=media&token=42051170-b2c8-4a46-a442-597704d001a0",
      link: "",
      website: "https://www.pregnancycrisis.sg/",
      email: "help@pregnancycrisis.sg",
      phone: "+65 6339 9770",
      whatsapp: "",
      facebook: "https://www.facebook.com/PregnancyCrisisSupport/",
      instagram: "https://www.instagram.com/pregnancycrisissupport/",
    },
    {
      name: "SFX Bible Apostolate Team",
      description:
        "The SFX Bible Apostolate Team are ordinary members of the parish, with the mission to promote the Love for and the Knowledge of the Bible as the Living Word of God.",
      category: "Community",
      keywords: "bible sharing, prayer group, bible study",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSFXBibleApostolateTeam.jpg?alt=media&token=a36aeefb-6d7a-40c4-95d2-50d860316115",
      link: "",
      website: "https://www.sfxchurch.sg/bat/",
      email: "secretariat@sfxchurch.sg",
      phone: "+65 6280 6076",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Chancery of the Roman Catholic Archdiocese of Singapore",
      description:
        "The Chancery’s primary role is to assist the Archdiocese in its administrative functions and governance, and to preserve and safeguard the documents and artefacts of the Church.",
      category: "Archdiocese",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FChanceryOfTheRomanCatholicArchdioceseOfSingapore.jpeg?alt=media&token=f43e2780-bd92-4b90-b2a5-8d455543a192",
      link: "",
      website: "https://chancery.catholic.sg/",
      email: "chancery@catholic.org.sg",
      phone: "+65 6336 9408",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Architectural Guild ",
      description:
        "Catholic Architectural Guild is a lay apostolate that consists of Catholics in the Architectural industry under the guidance of the Catholic Archdiocese of Singapore. ",
      category: "Guild",
      keywords: "Architect, Mission Trip, Support",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic%20architectural%20guild.jpg?alt=media&token=549b5845-430f-407d-b6f2-6ac378238972",
      link: "",
      website: "https://www.cag.catholic.sg/",
      email: "catholicarchguild@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/profile.php?id=100068088199586",
      instagram: "https://www.instagram.com/catholicarchguild/",
    },
    {
      name: "CANA the Catholic Centre",
      description:
        "CANA The Catholic Centre aims to be a truly Catholic hub in the city, a place for connecting, for learning, for respite, and for care. Besides housing several Church offices, a cafe is also located there.",
      category: "Organisation",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCANA%20the%20catholic%20centre.jpg?alt=media&token=60a6bad3-7c57-4d79-802b-3640fe365b48",
      link: "",
      website: "",
      email: "canatheplacetobe2013@gmail.com",
      phone: "+65 6338 4080",
      whatsapp: "",
      facebook: "https://www.facebook.com/CANA.SINGAPORE",
      instagram: "https://www.instagram.com/cana.singapore/",
    },
    {
      name: "Jesus Youth Singapore",
      description:
        "Jesus Youth is an International Catholic lay movement that began in India.  It focuses on evangelising young people and nurturing their missionary initiatives. It started in Singapore in the year 2000.",
      category: "Community",
      keywords: "youth, mission, young people",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FJeuss%20YouthSpore.png?alt=media&token=dfed7935-5b99-49ea-ab15-10f55873e000",
      link: "",
      website: "https://singapore.jesusyouth.org/",
      email: "singapore@jesusyouth.org",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/jy15sg/",
      instagram: "https://www.instagram.com/jesusyouth_singapore/",
    },
    {
      name: "Archdiocesan Commission for Tamil-Speaking (ACTS)",
      description:
        "ACTS aims to evangelise to the local and migrant Tamil-speaking Catholics. The commission helps ensure that the various programmes and initiatives of the Church are effective and meaningful for the Tamil community.",
      category: "Community",
      keywords:
        "migrant support, tamil community, indian community, support for Indian migrant",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FACTS.jpg?alt=media&token=a25afc3e-a21a-48c0-a8d8-924b12a6f05b",
      link: "",
      website: "https://acts.catholic.sg/",
      email: "acts.secretariat@catholic.org.sg",
      phone: "+65 6294 0264",
      whatsapp: "",
      facebook: "https://www.facebook.com/ACTSsingapore",
      instagram: "https://www.instagram.com/ACTSSingapore/",
    },
    {
      name: "Archdiocesan Commission for Malayalam Apostolate (ACMA)",
      description:
        "ACMA aims to provide liturgical and Para-liturgical service through different (Latin, Syro-Malabar and Syro-Malankara) liturgical rites, provide family prayer groups (Kudumba koottayma), coordinate various Malayalam Catholic Communities & Groups under the umbrella of the Commission and organise various religious and cultural activities for Malayalam speaking community",
      category: "Community",
      keywords: "malayalam community, indian community, ",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FACMA.jpg?alt=media&token=74b22e50-f490-480b-a5eb-616fcd04354c",
      link: "",
      website: "",
      email: "archmalayalam@catholic.org.sg",
      phone: "+65 6288 7761",
      whatsapp: "",
      facebook: "https://www.facebook.com/malayalamcommission/",
      instagram: "",
    },
    {
      name: "Office for Catechesis",
      description:
        "The Office for Catechesis provides training and formation for catechists and those involved in catechetical ministries, such as parish-based Children and Youth Catechesis, home-based Catechesis and RCIA.",
      category: "Cathecism",
      keywords: "become a Catechist, catechism, sunday school",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOffice%20of%20catechesis.png?alt=media&token=cf2d3b4d-c47d-40bf-bd7a-a96640abac4b",
      link: "",
      website: "https://www.catechesis.org.sg/",
      email: "enquiry.ofc@catholic.org.sg",
      phone: "+65 6911 0400",
      whatsapp: "",
      facebook: "https://www.facebook.com/OfficeForCatechesis",
      instagram: "https://www.instagram.com/officeforcatechesis/",
    },
    {
      name: "Legion of Mary",
      description:
        "The Legion of Mary is a lay movement that is open to all Catholics who, with a devotion to Our Lady as Queen and Mother, desire to seek sanctification and evangelise the world.",
      category: "Community",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Flegion%20of%20mary%20logo.png?alt=media&token=448fd7a8-dc98-4b7a-a390-f91c812f02ee",
      link: "",
      website: "https://legiomariae.net/",
      email: "singaporesenatus@gmail.com",
      phone: "+659649 3893",
      whatsapp: "",
      facebook: "https://www.facebook.com/SingaporeSenatus1/",
      instagram: "",
    },
    {
      name: "Natural Family Planning",
      description:
        "Natural Family Planning service is provided by Catholic Medical Guild, Catholic Nurses’ Guild and Catholic Family Life as the best way to promote self-awareness and joint responsibility in family planning.",
      category: "Community",
      keywords: "family planning",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FNatural%20Fam%20planning.jpg?alt=media&token=d67f4dbb-d50d-4374-9d89-84820e7d5379",
      link: "",
      website: "http://naturalfamilyplanning.sg/site/",
      email: "enquiry@naturalfamilyplanning.sg",
      phone: "+65 9106 1990",
      whatsapp: "",
      facebook: "https://www.facebook.com/nfpsgpcommunity",
      instagram: "https://www.instagram.com/nfpsg/",
    },
    {
      name: "Catholic Engaged Encounter",
      description:
        "Catholic Engaged Encounter is a program that is delivered over a weekend and is a gift of time for couples to be together - to communicate honestly about their plans to enter marriage.",
      category: "Community",
      keywords:
        "couple programme, marriage preparation, relationship development",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic%20engaged%20encounter.jpeg?alt=media&token=f68c9f2c-5c68-4a37-a72a-46137d7b4890",
      link: "",
      website: "http://www.ceespore.sg/",
      email: "ceespore-pr@catholic.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/CatholicEngagedEncounterSingapore",
      instagram: "",
    },
    {
      name: "Archdiocesan Commission for the Pastoral Care of Migrants & Itinerant People (ACMI)",
      description:
        "ACMI looks after the pastoral needs of migrants in Singapore with casework, breadbasket events at workers’ dormitories, befriender networks, and by skills development and integration courses.",
      category: "Organisation",
      keywords:
        "migrants, Casework, Skills Training, Community Development, migrant courses, financial aid, coaching, counselling, bread basket programme, legal aid, shelter, education, support group, migrant support",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FACMI%20Logo.png?alt=media&token=3b2dd081-e38c-49c7-bc22-f44ebffa53f3",
      link: "",
      website: "https://www.acmi.org.sg/",
      email: "ACMI@catholic.org.sg",
      phone: "+65 6801 7400",
      whatsapp: "",
      facebook: "https://www.facebook.com/ACMI.Singapore/",
      instagram: "https://www.instagram.com/acmi.singapore/",
    },
    {
      name: "Carlo Catholic Services",
      description:
        "Carlo Catholic Services aims to bring the Christian message of salvation to all people of good will, to encourage believers to receive deeper Christian formation and to promote spiritual growth.  ",
      category: "Shop",
      keywords:
        "gift shop, baptismal gown, communion veil, books, church items, statues, rosary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCARLOCATHOLICSERVICES.png?alt=media&token=cf56eed9-96ed-4622-bca9-1bbaa2457223",
      link: "",
      website: "https://carlo.org.sg/",
      email: "carlo@carlo.org.sg",
      phone: "+65 6337 7489",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Abilities Beyond Limitations & Expectations (ABLE)",
      description:
        "ABLE is a social service agency that aims to enable the physically challenged to live with dignity and have productive, meaningful and independent lives",
      category: "Organisation",
      keywords:
        "caregivers, rehabilitation, training, case management, employment, therapy, therapist, transport services, job coaching, disability, employment training, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAble%20logo.png?alt=media&token=3310bdae-5e2a-4059-8345-25e36b3bc017",
      link: "",
      website: "https://www.able-sg.org/",
      email: "enquiries@able-sg.org",
      phone: "+65 6801 7460",
      whatsapp: "",
      facebook: "https://www.facebook.com/AbilitiesBLE/",
      instagram: "https://www.instagram.com/able.sg/",
    },
    {
      name: "Choice",
      description:
        "Choice hopes to impress young working adults on what belonging in a relationship is. A relationship with God, family and the community - to encourage them in the ongoing mission of the church.",
      category: "Community",
      keywords: "relationships, program for singles, program for dating adults",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FChoice%20-%20logo.jpeg?alt=media&token=338bca33-8316-4b62-88f1-f3b041f684d1",
      link: "",
      website: "https://www.choice.org.sg/",
      email: "registration@choice.org.sg",
      phone: "+65 6567 9771",
      whatsapp: "",
      facebook: "https://www.facebook.com/choicesingapore",
      instagram: "https://www.instagram.com/choicesingapore/",
    },
    {
      name: "Archdiocese Synodal Committee",
      description:
        "The Archdiocesan Synodal Committee facilitates the Singapore Archdiocese’s participation in the current worldwide Synodal Process. It is moderated by Fr Valerian Cheong and Lawrence Chong, with representative clergy from the five districts, and representation from the religious congregations and laity.",
      category: "Archdiocese",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FA%20synodal%20church.jpg?alt=media&token=10607f9b-4fc2-45c5-9328-77378bffb94f",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Communauté Catholique Francophone De Singapour",
      description:
        "The French-speaking Catholic Community of Singapore serves some 500 families and French-speaking professionals of all origins who share both the French language and the Catholic faith.",
      category: "Community",
      keywords: "french community, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOrganisation_French%20.jpeg?alt=media&token=55145792-e336-47d2-ac38-3c06267ab87e",
      link: "",
      website: "https://www.paroisse-singapour.com/",
      email: "aumonier.paroisse.singapour@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/ccfsingapour",
      instagram: "",
    },
    {
      name: "Congregation of the Disciples of the Lord (CDD)",
      description:
        "The mission of CDD is to strive to evangelise all peoples, especially the Chinese, through inculturation of the faith. Its defining characteristics are fervent love for the Holy Eucharist and devoted loyalty to the Holy See. ",
      category: "Community",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCongregation%20of%20the%20Disciples%20of%20the%20Lord.png?alt=media&token=6fe57d26-21ba-4ab3-9a3d-487d3d2ff187",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "A Call to Share (ACTS)",
      description:
        "ACTS aims to share our experience of Christ, our skills and our resources with the children, the poor and the needy overseas and to share the values of Christ-centreness, Integrity, Respect and humility, Commitment and Generosity in all our acts. Its missions to many parts of Cambodia, to Myanmar, Philippines and Vietnam with participants coming from as far away as Australia, Mauritius, Shanghai, and the USA.",
      category: "Community",
      keywords:
        "mission trips, outreach, volunteer, support for poor, overseas mission, Cambodia, Myanmar, Philippines",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FACTS_logos.png?alt=media&token=106afaae-4e51-46b4-88d3-b0708cd92ccc",
      link: "",
      website: "https://acts-singapore.com/",
      email: "contact@acts-singapore.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/ACTSSG",
      instagram: "https://www.instagram.com/acts.singapore/",
    },
    {
      name: "ANAK-Tnk",
      description:
        "ANAK-Tnk was founded in 1998 to support Manila’s most unfortunate children: those living in extreme poverty in the streets and in the slums of the capital city of the Philippines. The foundation’s action and purpose are being supported outside the Philippines by branches operating on all continents. ANAK-Tnk focuses on areas such as Education, Nutrition, Health, and Protection for street children, child scavengers, slum children, and children with special needs.",
      category: "Organisation",
      keywords:
        "mission trips, support poor children, children violence, children abuse, philippines",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FANAK_Tnk.png?alt=media&token=b54de8b2-48ae-41b5-99c9-e175d201c8ca",
      link: "",
      website: "https://www.anak-tnk.org/",
      email: "singapore@anak-tnk.org",
      phone: "+65 9670 0898",
      whatsapp: "",
      facebook: "https://www.facebook.com/anaktnkfoundation/",
      instagram: "https://www.instagram.com/anak_tnk/",
    },
    {
      name: "Cahaya Masa Depan",
      description:
        "Sponsor young Indonesians in their studies and provide health support to underprivileged communities of the Riau Islands",
      category: "Community",
      keywords:
        "support for poor, sponsor student, medical support, health support, mission, Indonesia",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCahaya-Masa-Depan.png?alt=media&token=b6061031-1ac9-48f1-9847-c9144c83148a",
      link: "",
      website: "http://cahayamasadepan.org/",
      email: "assomasadepan@gmail.com",
      phone: "+65 8751 6598",
      whatsapp: "",
      facebook: "https://www.facebook.com/assocahayamasadepan/",
      instagram: "https://www.instagram.com/cahaya.masa.depan/",
    },
    {
      name: "Catholic Medical Guild",
      description:
        "We are a society of healthcare professionals in Singapore that gathers Catholic physicians together. The Catholic Medical Guild of Singapore aims to build a vibrant Catholic medical community, to be an authoritative voice and a reference point on bioethical issues and to provide medical services to the community",
      category: "Guild",
      keywords:
        "healthcare, medical, volunteer, physicians, doctors, give back, community, outreach, Mission",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Medical-Guild.jpg?alt=media&token=f83ff384-4e01-4f22-b6a6-6cf0945b8f98",
      link: "",
      website: "http://www.cmg.org.sg/",
      email: "cmgsingapore@yahoo.com.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/cmg.org.sg/",
      instagram: "",
    },
    {
      name: "Catholic Nurses Guild",
      description:
        "CNG is an association of committed and caring nurses who strive to uphold technical competence according to Christian moral principles through fostering and promoting professional and spiritual growth of its members; participating in pastoral and community programmes towards the sick, handicapped and the elderly.",
      category: "Guild",
      keywords:
        "nurses, medical, give back, community, volunteer, outreach, nursing, healthcare, mission",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCNG.png?alt=media&token=8eb1139d-74d3-44ec-a55d-3a7fdd591828",
      link: "",
      website: "https://sgcatholicnurses.wordpress.com/",
      email: "catholicnurse@gmail.com",
      phone: "+65 9236 1047",
      whatsapp: "",
      facebook: "https://www.facebook.com/groups/cngsingapore/",
      instagram: "https://www.instagram.com/catholic_nurses_guild_sg/",
    },
    {
      name: "Clare’s Missionary",
      description:
        "Clare’s Missionary focuses on helping children from needy and dysfunctional families through the education services in Clare’s Enrichment Corner.",
      category: "Organisation",
      keywords:
        "enrichment, children education, volunteer, outreach, tuition centre, mission, malaysia, sabah",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fclaremissionary.jpeg?alt=media&token=c409636f-47d5-436d-a7bd-2090fe0f726a",
      link: "",
      website: "https://www.claresenrichmentcorner.com/clares-missionary",
      email: "claresmissionary@gmail.com",
      phone: "+65 9620 7768",
      whatsapp: "",
      facebook: "https://www.facebook.com/claresmissionary.sg/",
      instagram: "https://www.instagram.com/claresmissionary/",
    },
    {
      name: "Father Damien Society",
      description:
        "The Father Damien Society was formed in 2009 with the main objective of reaching out to the less fortunate youths in Indonesia and the Philippines. At the beginning we mainly supported them by providing them with stationery and other support educational equipment needed. At the moment we are supporting a kampong Kindergarten at Punggor in Batam. We also helped them with some renovation of their building when it was battered badly. There are about 12 teachers there and we have been supplementing their meager salary. ",
      category: "Organisation",
      keywords:
        "youth, support for poor, education support, mission, Blessed Sacrament Church",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Ffrdamien.jpeg?alt=media&token=4fa978be-a42e-4136-ac16-a4025127bdee",
      link: "",
      website:
        "https://makehopehappen.charis-singapore.org/MemberOrganisation/father-damien-society",
      email: "frdamiensociety@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/frdamiensociety",
      instagram: "",
    },
    {
      name: "Fishing Rod Society",
      description:
        "The name of Fishing Rod Society came from the quote ’Give a man a fish, you feed him for a day. Teach a man to fish, you feed him for a lifetime’. Fishing Rod Society’s main overseas project is Agape Centre, where they serve refugees from Myanmar in Kuala Lumpur, and focus on two key areas: education for the poor and comfort for the sick.",
      category: "Organisation",
      keywords:
        "refugee, overseas, mission trip, education, medical, volunteer, Malaysia",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FFishing%20Rod%20Society.jpg?alt=media&token=02066387-42b2-4cb4-b7c1-6c84c3da690b",
      link: "",
      website:
        "https://makehopehappen.charis-singapore.org/MemberOrganisation/fishing-rod-society",
      email: "fishingrodsociety@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Jesuit Refugee Service",
      description:
        "Jesuit Refugee Service is an international Catholic organisation with programmes in more than 50 countries. The mission of JRS is to accompany, serve and defend the rights of refugees and other forcibly displaced persons. JRS Singapore seeks to raise awareness about the refugee situation and provide volunteering opportunities for Singaporeans to support JRS programmes, especially in the Asia Pacific region.",
      category: "Organisation",
      keywords: "mission trips, volunteer, anti-trafficking, refugee, outreach",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FJRSSG.png?alt=media&token=a4864e1b-c60c-491e-ad12-9fc1bdba5067",
      link: "",
      website: "http://jrssg.org/",
      email: "contact@jrssg.org",
      phone: "+65 6801 7490",
      whatsapp: "",
      facebook: "https://www.facebook.com/JRSSingapore/",
      instagram: "",
    },
    {
      name: "Project Kyrie",
      description:
        "Our mission at Project Kyrie is to support humanitarian relief efforts of NGOs and individuals to make a small difference in the lives of others by facilitating funds and resources to organisations and persons of most need.",
      category: "Organisation",
      keywords: "donate, charity",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FPoject-Kyrie.JPG?alt=media&token=301fb336-3daf-4956-8320-f3516b12c706",
      link: "",
      website: "http://gimg.blogspot.com/",
      email: "Millicent.tan@gmail.com",
      phone: "+65 96956267",
      whatsapp: "",
      facebook: "https://www.facebook.com/projectkyrie",
      instagram: "",
    },
    {
      name: "Project Micah",
      description:
        "Project Micah started its mission to serve underprivileged children in orphanages in Myanmar. From annual mission trips, they now continue to reach out through online means and keep in touch with the Canossian family and boarding homes in Myanmar",
      category: "Community",
      keywords:
        "charity, mission trips, support for the poor, outreach, volunteer, Myanmar",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fprojectmicah.png?alt=media&token=e556eac7-cfbe-46c7-86d9-b649b97944e6",
      link: "",
      website: "",
      email: "projectmicahsg@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/ProjectMicah",
      instagram: "",
    },
    {
      name: "Sowers in God’s Name (SiGN)",
      description:
        "SiGN is a Parish based mission group from the parish of St Francis Xavier. While most of our members are from within the parish, we do welcome like-minded people from outside the parish, whether they be Christian or not, to journey with us and to experience the richness of life as a part of a Christian Community.",
      category: "Community",
      keywords:
        "charity, mission trips, support for the poor, outreach, volunteer, Church of St Francis Xavier",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsoweringodsname.png?alt=media&token=ea99ed61-eff7-4e09-b8e2-7de120bc9fee",
      link: "",
      website: "https://www.sfxchurch.sg/sign/",
      email: "sign@sfxchurch.sg",
      phone: "+65 94899922",
      whatsapp: "",
      facebook: "https://www.facebook.com/groups/Sign.sg",
      instagram: "",
    },
    {
      name: "Infant Jesus Homes & Children’s Centres (IJHCC)",
      description:
        "Provide a caring and supportive environment conducive to the balanced growth and personal formation of children, young persons, and youth at risk, to help them grow into responsible individuals, well-equipped for life, with a capacity to contribute to society",
      category: "Community",
      keywords:
        "volunteer, donate, bursary awards, speech and drama, outreach, charity, caritas, joy packs, support for elderly, support for children, student care",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FIJHCC.png?alt=media&token=879c8b06-e4a0-49b6-8ce8-de87475b99af",
      link: "",
      website: "https://ijhcc.org/",
      email: "ijvillage@ijhcc.org",
      phone: "+65 6459 4801",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Faith and Light Community",
      description:
        "Faith and Light Community not only focuses on bringing special families together to share their struggles as they bring up their special needs child, but also provider a companionable environment where their child can meet and make new friends. ",
      category: "Community",
      keywords:
        "intellectual disability, support for low income, volunteer, special child, autism, down syndrome, volunteer, donate, workshops, special needs, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FFaith-and-Light.png?alt=media&token=686f166d-bf0c-4717-b27e-e2309ee556cf",
      link: "",
      website: "https://www.faithandlight.org/",
      email: "faith.lightsingapore@gmail.com",
      phone: "+65 9747 9833",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Christian Family and Social Movement (CFSM)",
      description:
        "In working towards building good Christian families, Christian Family and Social Movement (CFSM) assists all family members to live and fulfil their various roles in the family, workplace, community and nation. The Movement organizes faith and person formation sessions, outings and circulates articles on issues as “food for thought and formation”. Participation and circulation are restricted only to its members only.",
      category: "Organisation",
      keywords: "family programmes",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCSFM.webp?alt=media&token=c0218053-5b58-4dd4-94e8-547207bd2ace",
      link: "",
      website: "https://www.cfsm.org.sg/",
      email: "cfsmsg@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Morning Star Community Services",
      description:
        "Morning Star Community Services is a registered charity and Institution of Public Character (IPC) which aims to enrich and strengthen family relationships in Singapore. By empowering individuals and families, we strive to build vibrant communities that can make a difference to society. Founded in 1999 with our first centre in Lorong Low Koon, we have since expanded to six centres. Services offered by Morning Star Community Services include after-school care services and CareNights, a newly launched initiative which offers temporary caregiving as a respite for parents aspiring to upgrade or are facing difficulties.",
      category: "Community",
      keywords:
        "volunteer, temporary caregiving, support for parents, after school care, evening child care",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fmorningstar.jpg?alt=media&token=834e0934-f7e7-401c-837e-a200d65d9142",
      link: "",
      website: "https://www.morningstar.org.sg/",
      email: "contactus@morningstar.org.sg",
      phone: "+65 6285 1377",
      whatsapp: "",
      facebook: "https://www.facebook.com/MorningStarSG/",
      instagram: "https://www.instagram.com/morningstar_sg/",
    },
    {
      name: "Marymount Centre",
      description:
        "Marymount Centre is dedicated to helping vulnerable women and children in Singapore.",
      category: "Organisation",
      keywords:
        "donate, student care, shelter, volunteer, women in need, help for women",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMarymount-Centre.png?alt=media&token=4ee63512-7c19-4520-a288-af7d0062222f",
      link: "",
      website: "https://marymountctr.org.sg/",
      email: "enquiry@marymountctr.org.sg",
      phone: "+65 6256 4440",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Sri Lankan Catholics of Singapore",
      description: "Sri Lankan Catholic Community in Singapore",
      category: "Community",
      keywords: "sri lankan community, support group",
      prodserve: "",
      photo: "",
      link: "",
      website: "http://www.slcis.org",
      email: "srilankancatholics@gmail.com",
      phone: "+65 9686 3625",
      whatsapp: "",
      facebook: "https://www.facebook.com/SUPUWATHA/",
      instagram: "https://www.instagram.com/supuwatha/",
    },
    {
      name: "Hong Kong Catholics",
      description: "Hong Kong Catholic Community in Singapore",
      category: "Community",
      keywords: "hong kong community, cantonese speaking, support group",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "funglapyun@yahoo.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Spanish-speaking Catholic Community",
      description: "Spanish Catholic Community in Singapore",
      category: "Community",
      keywords: "spanish community, support group",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "joe.lopez@catholic.org.sg",
      phone: "+65 6779 0652",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Myanmar Catholic Community",
      description:
        "Aims Myanmar Catholic Faithfuls to pray together, have a proper Instruction in Faith, help and love each other for spiritual,physical and social needs.",
      category: "Community",
      keywords: "myanmar community, burmese community, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMyanmar-Catholic-Community.jpg?alt=media&token=361bde76-aa4b-4fce-8ca3-130a616a02c5",
      link: "",
      website: "",
      email: "joseph.kace@catholic.org.sg",
      phone: "+65 6288 9140",
      whatsapp: "",
      facebook: "https://www.facebook.com/MCCS.Singapore/",
      instagram: "",
    },
    {
      name: "Korean Catholics Community of Singapore",
      description: "Korean Catholic Community in Singapore",
      category: "Community",
      keywords: "korean community, support group",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://cafe.daum.net/kccsg",
      email: "",
      phone: "+65 6288 9771",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Japanese Catholics of Singapore",
      description: "Japanese Catholic Community in Singapore",
      category: "Community",
      keywords: "japanese community, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Japanese.webp?alt=media&token=f6c77d54-1228-4944-8402-f83a873a0da5",
      link: "",
      website: "https://sgcatholicjp.wixsite.com/sjcc",
      email: "sg_catholic_jp@yahoo.co.jp",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/sgcatholicjp/",
      instagram: "",
    },
    {
      name: "Italian-Speaking Catholic Community",
      description:
        "Reaching out to Italian speaking friends. Praying together, celebrating Mass, helping parents on the spiritual education of our children and teenagers.",
      category: "Community",
      keywords: "italian community, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatolica-Italiana.jpg?alt=media&token=db1a3c41-d3dd-46dc-87c4-c035823c5611",
      link: "",
      website: "https://www.comunitacattolicaasingapore.com/",
      email: "catechismo.italiano@gmail.com",
      phone: "+65 9061 4762",
      whatsapp: "",
      facebook:
        "https://www.facebook.com/Comunit%C3%A0-Cattolica-Italiana-a-Singapore-1280485348730486/",
      instagram: "",
    },
    {
      name: "Indonesian Catholic Community (KKIS)",
      description:
        "Aims to become the Centre for all Indonesian speaking Catholics in Singapore and to serve and assist in fulfilling their spiritual and social needs in Singapore.",
      category: "Community",
      keywords: "indonesian community, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FKatolik-Indonesia.png?alt=media&token=7ca00a20-67a0-455f-820d-f4841d3281d2",
      link: "",
      website: "https://kkis.org/",
      email: "kkis.contact@gmail.com",
      phone: "+65 8840 3076",
      whatsapp: "+6585917218",
      facebook: "https://www.facebook.com/kkis.org/",
      instagram: "https://www.instagram.com/kkisorg/",
    },
    {
      name: "German-speaking Catholics",
      description:
        "A home away from home / pastoral care for German speakers in Singapore.",
      category: "Community",
      keywords: "german community, german-speaking Catholics, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-German.jpg?alt=media&token=63620882-cbc3-4ebb-a58d-d742f0867c03",
      link: "",
      website: "https://dt-katholiken.sg/wp/",
      email: "gemeinde@dt-katholiken.sg",
      phone: "+65 8358 8164",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "ABLE SEAS Limited",
      description:
        "With the mission of providing opportunities for the physically challenged to be gainfully employed. ",
      category: "Organisation",
      keywords:
        "charitable organisation, transport, Accounting, payroll services, bookeeping, payroll management, accounting assistants",
      prodserve: "disability service, social service",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fable-accounting-services.png?alt=media&token=4c36d9b0-4fe4-4e2b-86ba-f162abce6a01",
      link: "",
      website: "https://www.able-sg.org/able-seas/",
      email: "enquiries@able-sg.org",
      phone: "+65 6801 7450",
      whatsapp: "",
      facebook: "https://www.facebook.com/AbilitiesBLE/",
      instagram: "",
    },
    {
      name: "Agape Village",
      description:
        "Agape Village is a social service hub which offers integrated and holistic care to those in need, regardless of race and religion. Apart from services and programmes for the vulnerable and specific groups, Agape Village also houses facilities and amenities for the community such as the multi-purpose halls, training rooms, and specialised rooms (baking and cooking rooms, dance studio, art therapy room and others).",
      category: "Organisation",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.caritas-singapore.org/about-agape-village/",
      email: "agapevillage@caritas-singapore.org",
      phone: "+65 6801 7400",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Good Shepherd Convent Restful Waters",
      description:
        "A pastoral service of the Good Shepherd Sisters to provide a place and programmes for people of all races and religions who are seeking a deeper connection with self, others and God. ",
      category: "Organisation",
      keywords: "Counselling, prayer companionship, support group",
      prodserve: "Counselling, prayer companionship",
      photo: "",
      link: "",
      website: "https://goodshepherdrestfulwaters.wordpress.com/",
      email: "restful_waters@yahoo.com.sg",
      phone: "+65 6242 2925",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Marriage Preparation Course (MPC)",
      description:
        "MPC is an apostolate of the archdiocese of the Catholic Church of Singapore dedicated to preparing couples for marriage in the church.",
      category: "Organisation",
      keywords:
        "couple programme, marriage programme, relationship development, support group",
      prodserve:
        "marriage preparation, couples before getting married, engaged couples",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMPC-logo-new-220122-large.jpg?alt=media&token=8a043053-9b6b-44bf-8693-ac5a33c4b246",
      link: "",
      website: "https://www.mpcsingapore.com/",
      email: "mpcsingapore@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Joseph’s Home",
      description:
        "St Joseph’s Home (SJH) is a nursing home facility established in 1978 by CWS with the support of the Cannosian Sisters. For the past four decades, SJH has worked tirelessly to support the elderly in need, providing long-term residential care services for seniors.",
      category: "Nursing Home",
      keywords:
        "Nursing Home, elderly, volunteer, aged, old folks home, hospice care, dementia care, caregivers",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://stjh.org.sg/",
      email: "general@stjh.org.sg",
      phone: "+65 6268 0482",
      whatsapp: "",
      facebook: "https://www.facebook.com/stjhsingapore/",
      instagram: "https://www.instagram.com/stjh_singapore/",
    },
    {
      name: "St Theresa’s Home",
      description:
        "St. Theresa’s Home is built on a foundation of Christian values of love and care for others; especially for those less fortunate in our society.  Although St. Theresa’s Home is no longer managed by the Little Sister, the home remains true to its heritage and guiding philosophies. St. Theresa’s Home is today a nursing home, licensed by the Ministry of Health, Singapore with a capacity for 200 residents. A nursing home, our team of trained and dedicated nurses and caregivers provide continual nursing care to our residents, most of whom are discharged from re-structured hospitals.",
      category: "Nursing Home",
      keywords: "Nursing Home, residential care for elderly, caregiver",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsttheresahome.jpg?alt=media&token=551ab643-7be9-442e-a642-0d1f3e044137",
      link: "",
      website: "https://www.sainttheresahome.org.sg/",
      email: "admin@sainttheresahome.org.sg",
      phone: "+65 6256 2532",
      whatsapp: "",
      facebook: "https://www.facebook.com/sttheresahome/",
      instagram: "https://www.instagram.com/sttheresashome/",
    },
    {
      name: "Couple Mentor Journey (CMJ)",
      description:
        "The Couple Mentor Journey (CMJ) is an initiative designed to help couples, either newly married or planning for marriage, dedicate time towards preparing for the real challenges of marriage, by learning from the lived experience of their couple mentors.",
      category: "Organisation",
      keywords:
        "couple programme, marriage programme, couple mentors, marriage enrichment, relationship development",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCMJLogo.jpeg?alt=media&token=90d8ad88-d45e-48a1-bbee-a598c2b0dfe2",
      link: "",
      website: "https://cmj.sg/",
      email: "cmj@acf.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Vincent Home",
      description:
        "St Vincent Home is a sheltered home for elderly residents in the community in need of a place to stay. Set up in 1979 in collaboration with the Society of St Vincent de Paul, St Vincent Home provides long-term housing and accommodations for seniors on the Public Assistance Scheme, with the aim of enhancing their quality of life and assisting them in maintaining agency, independence, and dignity. Residents have the freedom of managing their own activities of daily life as far as possible, and play an active part in the upkeep and maintenance of the home. In order to keep residents mentally, physical and socially engaged, St Vincent Home organises a wide and varied range of activities that promote health, cognitive function and independent living, including social events and recreational activities.",
      category: "Nursing Home",
      keywords: "Housing, accomodation for seniors, nursing home",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Welfare-Org.png?alt=media&token=8b1575f7-7119-4f18-9df8-cf8c3985fb96",
      link: "",
      website: "https://catholicwelfare.org.sg/our-programmes-services#pane-9/",
      email: "stvincenthome@catholicwelfare.org.sg",
      phone: "+65 6337 6535",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Villa Francis Home for the Aged",
      description:
        "Villa Francis Home for the Aged was set up to provide inpatient nursing care for the destitute poor and sick, regardless of race or religion. We emphasize the importance of maintaining a positive attitude towards life in spite of ageing and illness and to stay physically, socially, emotionally and spiritually engaged.",
      category: "Nursing Home",
      keywords:
        "home for the aged, nursing, caregivers, rehabilitation, nursing home",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Welfare-Org.png?alt=media&token=8b1575f7-7119-4f18-9df8-cf8c3985fb96",
      link: "",
      website: "http://villafrancis.org.sg/",
      email: "admin@villafrancis.org.sg",
      phone: "+65 6269 2877",
      whatsapp: "",
      facebook: "https://www.facebook.com/villafrancissg",
      instagram: "",
    },
    {
      name: "Young Christian Students’ Movement (YCS)",
      description:
        "Young Christian Students Movement (YCS) founded in 1956, is a Catholic student movement by students for students in Christ focusing on faith formation, leadership skills and evangelisation. It is present in 6 Catholic Secondary schools, namely Holy Innocents’ High School, Catholic High School, St Joseph’s Institution (Independent), St Joseph’s Institution (International), St Gabriel’s High School and CHIJ St Nicholas Girl’s School. Catholics and non-Catholics students aged 13 to 16 journey together as a community through their formative years as teens.",
      category: "Organisation",
      keywords:
        "youth organisation, young people, student, support group, catholic school",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FYCS-Singapore.jpg?alt=media&token=a677e8cb-b97b-416b-b475-ce32a4499186",
      link: "",
      website: "https://ycssingapore1956.wixsite.com/ycssingapore",
      email: "secretary.ycs@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/ycssingapore/",
      instagram: "https://www.instagram.com/ycssingapore/",
    },
    {
      name: "The Singapore Association of the Sovereign Military Hospitaller Order of St John of Jerusalem, of Rhodes and of Malta",
      description:
        "Actively caring for people in need through its medical, social and humanitarian works. The Order of Malta, has a long history of service to the vulnerable and the sick. ",
      category: "Association",
      keywords: "medical works, social works, humanitarian works",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOrder-of-Malta-Singapore.jpg?alt=media&token=dd615f54-cae1-4430-804f-269e244e8d72",
      link: "",
      website: "https://www.orderofmaltasingapore.org/",
      email: "info@orderofmaltasingapore.org",
      phone: "+65 6696 6093",
      whatsapp: "",
      facebook: "https://www.facebook.com/smomsingapore/",
      instagram: "https://www.instagram.com/orderofmalta_singapore/",
    },
    {
      name: "St Pio Devotion Singapore",
      description:
        "A community that gathers monthly for home or church devotion to St Padre Pio of Pietrelcina.",
      category: "Community",
      keywords: "Prayer, devotion, Padre Pio ",
      prodserve: "",
      photo: "",
      link: "",
      website: "http://www.stpiodevotionsg.org/",
      email: "stpiodevotionsg@gmail.com",
      phone: "+65 6280 0980",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CatholicSG Radio",
      description:
        "Roman Catholic Archdiocese of Singapore very own digital streaming service. ",
      category: "",
      keywords: "radio station",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Radio.webp?alt=media&token=7e7ef1e3-cd78-4070-9009-83d2a463dbca",
      link: "",
      website: "https://radio.catholic.sg/",
      email: "link@catholic.org.sg",
      phone: "+65 6895 1515",
      whatsapp: "+65 6895 1515",
      facebook: "https://www.facebook.com/CatholicSGRadio",
      instagram: "",
    },
    {
      name: "St Joseph’ Institution Old Boys Association (SJIOBA)",
      description:
        "To foster, maintain and promote a Josephian family driven and nurtured by a spirit of faith, fellowship, solidarity and service, in the continued tradition of SJI.",
      category: "",
      keywords: "alumni network",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSJIOBA%20Logo%202_1.webp?alt=media&token=42598008-9a9d-4e49-a7c3-75c815bab847",
      link: "",
      website: "https://www.sjioba.org/",
      email: "secretariat@sjioba.org",
      phone: "+65 8628 9678",
      whatsapp: "",
      facebook: "https://www.facebook.com/groups/sjioba",
      instagram: "https://www.instagram.com/sjioba/",
    },
    {
      name: "Volunteers in the Archdiocese (VITA)",
      description:
        "As an acronym, VITA means Volunteers In The Archdiocese. The Latin word “vita” means “Life”. VITA members are called to be vibrant and life-giving in their work for the service of the Church and all mankind. (n.b. Latin is the official language of the Roman Catholic Church)",
      category: "",
      keywords: "volunteer, archcomms, media",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://archcomms.catholic.sg/what-is-vita/",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Singapore Polytechnic Catholic Students’ Society (SPCSS)",
      description:
        "SP CSS is a community built on love and friendship. Together, we desire to grow closer to God, and we do so through weekly sessions in school where we explore various forms of prayer, share our lives and discover more about the Catholic faith. We also practise a culture of being vulnerable and authentic with one another, and we are supported through our struggles knowing that we are not alone on this journey. We also have fellowship activities together, in and outside of school, such as meals, games and bonding activities.",
      category: "",
      keywords: "campus community, student society, support group",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://oyp.org.sg/singapore-polytechnic-catholic-student-society-css/",
      email: "info.oyp@catholic.org.sg",
      phone: "+65 6285 2571",
      whatsapp: "",
      facebook: "https://www.facebook.com/oyp.sg/",
      instagram: "https://www.instagram.com/oyp.sg",
    },
    {
      name: "Singapore Management University, FIDES – The SMU Catholic Community",
      description:
        "We are a community of Catholic students seeking to live as joyful witnesses of the Gospel in every aspect of our lives. We gather every Wednesday at 8:00pm to worship God in song, share the Gospel and to catch up with friends and cell groups. Mass is celebrated every alternate Friday at 4:00pm. ​If you are a Catholic student attending, or about to attend SMU, don’t be shy! Come and join us!",
      category: "",
      keywords: "student, support group, community",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsmufides.jpeg?alt=media&token=5bedea9a-2f43-47bc-9b15-90faf0192922",
      link: "",
      website: "https://oyp.org.sg/singapore-management-university/",
      email: "fides@sa.smu.edu.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/groups/smufides",
      instagram: "https://www.instagram.com/smufides/",
    },
    {
      name: "Archdiocesan Commissions for the Family (ACF)",
      description:
        "A commission of the Catholic Archdiocese of Singapore acting as a compass to realise the Church’s vision for marriage and the family.",
      category: "",
      keywords: "family life, family love",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FACF.jpg?alt=media&token=d10fc201-9c09-4b56-a0ff-986c9257f3d2",
      link: "",
      website: "https://catholicfamily.org.sg/",
      email: "admin@acf.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/CatholicFamSG/",
      instagram: "",
    },
    {
      name: "Singapore Institute of Management, Catholic Society (SIM CS)",
      description:
        "SIM is home to thirteen universities, so regardless of whichever university you are in, SIM Catholic Society welcomes you! Our activities include monthly mass, praise & worship sessions, talks by invited speakers, sharings, community service and fellowship. We meet every Wednesday, 7pm in SIM (venue to be advised weekly, via our Facebook Group)",
      category: "",
      keywords: "student, support group, community",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "catholicsociety@mymail.sim.edu.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/SIMcatholic/",
      instagram: "https://www.instagram.com/simcatholic/",
    },
    {
      name: "Archdiocesan Liturgy Commission",
      description:
        "The aim of the Commission under the authority and direction of the Archbishop is the promotion of Liturgy within the Archdiocese according to the Vatican II Constitution on Sacred Liturgy and any subsequent decrees.",
      category: "",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://liturgy.catholic.sg/",
      email: "kim_f_ho@yahoo.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Singapore Catholic Deaf Community",
      description:
        "Singapore Catholic Deaf Community is an independent lay organization established on 9th August 1978, serving in the Archdiocesan of Singapore.",
      category: "",
      keywords: "deaf community, hearing interpreter, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Deaf-Community.jpg?alt=media&token=29c038df-195c-4391-b6fe-94f25b3a8a97",
      link: "",
      website: "",
      email: "singaporecatholicdeaf@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/singaporecatholicdeafcommunity",
      instagram: "",
    },
    {
      name: "Ecclesiastical Tribunal of the Roman Catholic Archdiocese of Singapore",
      description: "",
      category: "",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://tribunal.catholic.sg/",
      email: "tribunal@catholic.org.sg",
      phone: "+65 6288 4659",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Secular Franciscan Order (OFS)",
      description:
        "A Singapore Catholic Community committing their lives to Jesus Christ in the spirit of St Francis of Assisi",
      category: "",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSecurlar-Franciscans.jpg?alt=media&token=b2f204dc-265b-4d2b-a52a-2ac6d949119c",
      link: "",
      website: "",
      email: "janetsfo@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/SecularFranciscansSG/",
      instagram: "",
    },
    {
      name: "Archdiocesan CaFE Promotion Team (ACPT)",
      description:
        "Aims to guide and empower all Catholics to a more committed discipleship through the Catholic Faith Exploration (CaFE) process. CaFE helps people of all ages in a relaxed, welcoming ’café’ style atmosphere to learn, share and pray together. The CaFE courses inspire and empower regular church-goers and also inform those exploring the faith in a modern and non-threatening manner.",
      category: "",
      keywords: "faith exploration, discipleship",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCafe-.png?alt=media&token=9dd75ecf-5009-4403-ab86-44e636028809",
      link: "",
      website: "https://acptsg.wordpress.com/",
      email: "acptcafe@gmail.com",
      phone: "+65 9007 0987",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Pieta – Support for bereaved parents",
      description:
        "Pieta is a community of Catholic parents who, after the death of our children, united in faith to seek God’s comfort, hope and wisdom. This is overseen by Catholic Family Life in Singapore. It was founded on 22 September 2016, when 4 parents came together to acknowledge the role our Catholic faith played in restoring our lives after the death of our children. We believe that God never abandoned us. Instead He continued to enfold each one in love. Searching together in faith and prayer, we found new meaning in the gift of life.",
      category: "",
      keywords:
        "support for bereaved parents, loss of child, loss of kid, mourn, mourning, bereavement, support group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fpieta%20border.webp?alt=media&token=35fd2c43-fc58-4b28-aa92-5364199819ba",
      link: "",
      website: "https://www.pietasingapore.org/",
      email: "pieta.singapore@gmail.com",
      phone: "+65 6281 6780",
      whatsapp: "",
      facebook: "https://www.facebook.com/PietaSingapore/",
      instagram: "",
    },
    {
      name: "Ngee Ann Polytechnic Catholic Youth Apostolate (NP CYA)",
      description:
        "CYA, short for Catholic Youth Apostolate, is a Christ-Centred Community in Ngee Ann Polytechnic that gathers to journey in faith and fellowship. Every Wednesday, we would gather for our weekly session. Our sessions consisted of Dinner, Praise and Worship, Check-in, Bible Sharing, Fellowship and Games.",
      category: "",
      keywords: "student community, support group, youth group, bible study",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Youth-Apostolate.jpg?alt=media&token=f8c68934-ce97-45c2-90a4-f640e725fc44",
      link: "",
      website: "",
      email: "ngeeanncya@gmail.com",
      phone: "+65 9695 2267",
      whatsapp: "",
      facebook: "https://www.facebook.com/npcya/",
      instagram: "",
    },
    {
      name: "Brothers of Mercy (FMMA)",
      description:
        "To have closer union with Jesus and increasing love for God, leading men to Christ and implanting the love of Mary in the hearts of men through caring for the sick and elderly out of Christian love.",
      category: "",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://history.catholic.sg/brothers-of-mercy-of-mary-help-of-christians-fmma-2/",
      email: "brambrosesg@yahoo.com.sg",
      phone: "+65 6284 0468",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Brothers of St Gabriel (SG)",
      description:
        "One with the Brothers sharing the same light on the Gospel and are committed to work together for the Kingdom of God. The Brothers dedicates themselves to education, particularly that of youth, poor children, those who are abandoned, maladjusted or handicapped in any way. The Brothers will consider this work as eminent service of high value to humanity and a privileged way of making Christ known.",
      category: "",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMontfort%20Centre%20Logo%20High%20Reso.webp?alt=media&token=728dce7e-1041-4392-9b09-928a19085f30",
      link: "",
      website: "https://www.montfortcentre.org/",
      email: "enquiries@montfortcentre.org",
      phone: "+65 6769 5711",
      whatsapp: "",
      facebook: "https://www.facebook.com/dieuseulsg/",
      instagram: "",
    },
    {
      name: "Neocatechumenal Way",
      description:
        "Neocatechumenal Way aims to form communities like the Holy Family of Nazareth living in Humility, Simplicity & Praise.",
      category: "",
      keywords: "community",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FNeocatechumenal-Way-SG.jpg?alt=media&token=e6a07dda-6d34-438f-9ab7-9a0619b2fc0a",
      link: "",
      website: "http://ncwaysingapore.sg/",
      email: "padretim97@gmail.com",
      phone: "+65 6744 0347",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Brothers of the Christian Schools (FSC) – De La Salle Brothers",
      description:
        "The purpose of this Institute is to provide a human and Christian education to the young, especially the poor, according to the ministry which the Church has entrusted to it. Christian schools, which need to be constantly renewed, are the preferred sphere of activity for the Brothers. The Institute is open also to other forms of teaching and education, suited to the needs of time and place.",
      category: "Community",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLa-Salle-SG-colored-logo-1.png?alt=media&token=69c7411c-0e3a-4c83-8294-243fdec72f73",
      link: "",
      website: "https://lasalle.sg/",
      email: "contact@lasalle.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/SGLaSalle",
      instagram: "",
    },
    {
      name: "National University of Singapore Catholic Students’ Society (NUS CSS)",
      description:
        "We are the Catholic student community on campus, while striving to represent the face of Christ and His Church in the university enterprise.We aim to foster spiritual growth and development amongst our members, while providing a strong base of spiritual and social support for young Catholic students striving to live the Gospel. At the same time, we strive to promote Catholic values in the wider society.",
      category: "",
      keywords: "student community, support group, youth group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCSS%20AY2122%20Logo.webp?alt=media&token=99874639-4cf9-4fe6-9f0b-2d4ca519fbf1",
      link: "",
      website: "https://nuscss.wixsite.com/nuscss",
      email: "nuscss@gmail.com",
      phone: "+65 67772523",
      whatsapp: "",
      facebook: "https://www.facebook.com/NUSCatholicStudentsSociety",
      instagram: "https://www.instagram.com/nus_css/",
    },
    {
      name: "Nanyang Technological University & National Institute of Education Catholic Students’ Apostolate (NTU CSA)",
      description:
        "The Catholic Students’ Apostolate is a Roman Catholic society founded on the campus grounds of Nanyang Technological Institute, now know as Nanyang Technological University (NTU). The main purpose of the Catholic Students’ Apostolate is to gather like-minded students who are curious to know what God has in store for them, and also to gather those who are lost in their calling or need a boost in their spirituality.",
      category: "",
      keywords: "youth group, student community, support group",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://oyp.org.sg/nanyang-technological-university/",
      email: "catholic@e.ntu.edu.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/NTUCatholic/",
      instagram: "https://www.instagram.com/ntucsa/",
    },
    {
      name: "Congregration of the Most Holy Redeemer (CSsR)",
      description:
        "The Redemptorists officially named the Congregation of the Most Holy Redeemer (Latin: Congregatio Sanctissimi Redemptoris), abbreviated CSsR, is a Catholic clerical religious congregation of pontifical right for men (priests and brothers). This congregation aims to follow the example of Jesus Christ, the Redeemer, by preaching the word of God to the poor.",
      category: "",
      keywords: "novena, redemptorist, congregation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FNovena.webp?alt=media&token=a581fb7f-12bb-4112-9ee1-f5b2c5da0c24",
      link: "",
      website: "https://novenachurch.com/",
      email: "secretariatcssr@novenachurch.com",
      phone: "+65 6255 2133",
      whatsapp: "",
      facebook: "https://www.facebook.com/novenachurch/",
      instagram: "https://www.instagram.com/novenachurch/",
    },
    {
      name: "Nanyang Polytechnic LOM - Catholic Youth Ministry",
      description:
        "Nanyang Polytechnic Legion of Mary Catholic Youth Ministry aims to build a Catholic Community in NYP that provides Catholic students an avenue to grow deeper in faith, by supporting each other in both spiritual and academic life.",
      category: "",
      keywords:
        "community, student, support group, legion of mary, youth group",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCYC.png?alt=media&token=3f397af7-c6cc-4a56-852e-241730122075",
      link: "",
      website:
        "https://www.nyp.edu.sg/student-life/cca/societies/legion-of-mary.html",
      email: "poly.oyp@catholic.org.sg",
      phone: "+65 6285 2571",
      whatsapp: "",
      facebook: "https://www.facebook.com/nanyangpoly",
      instagram: "https://www.instagram.com/nypstudentlife",
    },
    {
      name: "Malayalam Charismatic Group (MCG)",
      description:
        "MCG is comprised of Intercession Ministry, Childrens Ministry, Healing Ministry, Praise and Worship Ministry, Bible Sharing Ministry, Ministry of Young Couples and Families",
      category: "",
      keywords:
        "malayalam community, migrant, support group, indian community, charismatic group, healing ministry, young couple, bible sharing, bible study",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "mcgsingapore@gmail.com",
      phone: "+65 6291 9272",
      whatsapp: "",
      facebook: "https://www.facebook.com/groups/624165357629684/",
      instagram: "",
    },
    {
      name: "Daughters of St Paul (FSP) & Apostolate of the Media",
      description:
        "Called by the Divine Master in the Eucharist, the Daughters of St Paul proclaim the Good News through the media of communication and the witness of their lives. The vision of their founder, Fr James Alberione, is to live and give the whole Christ to persons in their totality.",
      category: "",
      keywords: "media, books, bibles, sisters, pauline",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fpaulines.jpeg?alt=media&token=94f66ac9-77c9-4be1-95ee-6d8df56dd3bd",
      link: "",
      website: "http://www.paulines.org.sg/",
      email: "pauline.fsp@gmail.com",
      phone: "+65 6919 1123",
      whatsapp: "",
      facebook: "https://www.facebook.com/Paulines.Sg",
      instagram: "",
    },
    {
      name: "Franciscan Missionaries of the Divine Motherhood (FMDM)",
      description:
        "Franciscan Missionaries of the Divine Motherhood (FMDM) Sisters are called to proclaim with our lives the joy and freedom of the Gospel in the spirit of St. Francis and Clare of Assisi. Mount Alvernia Hospital began with the arrival of three FMDM Sisters from on 7 March 1949. They took over the tuberculosis wards at Tan Tock Seng Hospital, which eventually became known as Mandalay Road Hospital. Amazingly, the hospital was entirely staffed by the Sisters who were professionally trained as nurses, midwives, physiotherapists, radiographers, laboratory technicians and other support services. The Sisters who ran the hospital received all their professional healthcare qualifications, religious training and hospital work attachments in England. What is more, before and after ward duties, the Sisters also doubled up as housekeepers, chefs and meal server.",
      category: "",
      keywords: "nun, sister, missionary, vocation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Ffmdm.jpg?alt=media&token=fd6cef78-9fb4-4ee7-9c78-de5160dbc141",
      link: "",
      website:
        "https://fmdminternational.co.uk/our-locations/malaysia-and-singapore/",
      email: "lindasimfmdm@gmail.com",
      phone: "+65 9783 2300",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Good Shepherd Sisters (RGS)",
      description:
        "Our Corporate Mission as Good Shepherd Sisters in the Province of Singapore-Malaysia is to participate with our lay partners in the mission of Jesus the Good Shepherd. We seek to be living witnesses of God’s merciful love in our communities, and in our ministries directed specifically to those who are marginalized and broken, especially women, girls and their families, restoring in them their worth and dignity.",
      category: "",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "http://gssmmission.org/",
      email: "admin@gssmmission.org",
      phone: "+65 6242 1324",
      whatsapp: "+65 8341 9982",
      facebook: "https://www.facebook.com/goodshepherdSingaporeMalaysia/",
      instagram: "",
    },
    {
      name: "Lay Canossian Association Singapore",
      description:
        "The Lay Canossian, attracted by the charism of St Magdalene of Canossa, is called to live the love of Christ Crucified in the secular dimension, for the glory of God and the salvation of souls. The charism of charity, a fire which spreads and seeks to embrace all, makes the Lay Canossian courageous and creative in proclaiming and witnessing the Gospel in every field: the family, the world of work, of culture, of politics and economics.",
      category: "",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "http://www.canossians-sg.org/LayCanossians.html",
      email: "lm4canossian@gmail.com",
      phone: "+65 6312 8751",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Lay Dominicans Singapore",
      description:
        "The Dominican Order, as any other Order, is a gift of the Holy Spirit to the Church that from its foundation had counted among its members both religious and laity. The Lay Dominicans therefore share in the Dominican charism and are an integral part of the Dominican Order.",
      category: "",
      keywords: "vocation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLay-Dominicans-SG.png?alt=media&token=783bf794-14b5-4644-b588-d1b03e239e62",
      link: "",
      website: "https://www.laydominicans.sg/",
      email: "laydominicans@gmail.com",
      phone: "+65 6282 8627",
      whatsapp: "",
      facebook: "https://www.facebook.com/LayDominicansSingapore/",
      instagram: "",
    },
    {
      name: "Marist Brothers (FMS)",
      description:
        "The Marist brothers have a special mission to provide a Christian education for children and young people, particularly those who are poor, abandoned or neglected.  This explains the letters ‘FMS’ after their name which stands for Fratres Maristae a Scholis or “Marist Brothers of the Schools”. The Brothers in Singapore belong to the Singapore-Malaysia-Hong Kong-China Province. The first Brothers arrived in China in 1891 and in Singapore in 1949.",
      category: "",
      keywords: "brotherhood",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "maristsg@gmail.com",
      phone: "+65 6382 3448",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Order of Discalced Carmelites Friars (OCD)",
      description:
        "Carmelite Friars give priority to that special apostolate of the Order of Carmelites, Discalced. There are two communities of the Discalced Carmelite Friars in Singapore. House of Formation: St John of the Cross Community and Parish Community: St Elijah Community",
      category: "",
      keywords: "camelites, friars, formation, community",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "hkchuajohn@yahoo.com",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Order of Friars Minor (OFM)",
      description:
        "The Order of Friars Minors, also called the Franciscan Friars, is a Catholic religious order founded by St Francis of Assisi in 1209. The word ‘friar’ means “brother”, and the Order of Friars Minor, or “Order of Lesser Brothers”, is first and foremost a brotherhood. Franciscan Friars seek to follow Jesus Christ more closely, with the inspiration of the Holy Spirit and in accordance with the Rule of Life composed by St Francis.",
      category: "Religious Order",
      keywords: "franciscan friar, vocation, formation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FFranciscan-Friars.png?alt=media&token=51871c26-bff0-42e8-836d-7ed05baeba44",
      link: "",
      website: "https://franciscans.sg/",
      email: "parish.stmary@catholic.org.sg",
      phone: "+65 6567 3866",
      whatsapp: "",
      facebook: "https://www.facebook.com/FranciscansMySg",
      instagram: "https://www.instagram.com/franciscansmysg/",
    },
    {
      name: "Paris Foreign Missions Society (MEP)",
      description:
        "The Society of Foreign Missions of Paris (French: Société des Missions Etrangères de Paris, short M.E.P.) is a Roman Catholic missionary organization. It is an organization of secular priests and lay persons dedicated to missionary work in foreign lands.",
      category: "",
      keywords: "community",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FParis-Foreign-Missions-Society.jpg?alt=media&token=13a697e1-4841-4fc8-9163-72c6053d0e1b",
      link: "",
      website: "https://missionsetrangeres.com/?lang=en",
      email: "paul.ngo@ihm.sg",
      phone: "+65 6444 3242",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Scheut Missions (CICM)",
      description:
        "The Congregation of the Immaculate Heart of Mary (CICM) is popularly known as ‘Scheut Missions’. All members are sent to a nation and culture different from their own in order to announce the Good News of Christ among the peoples who have not heard or discovered it.",
      category: "",
      keywords: "missionary vocations, ihm",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FScheut-Missions-Singapore.jpg?alt=media&token=8b4e3c6f-9aba-4d90-9180-bf925a7dba9a",
      link: "",
      website: "",
      email: "cicminfo@catholic.org.sg",
      phone: "+65 6468 2645",
      whatsapp: "",
      facebook:
        "https://www.facebook.com/Scheut-Missions-Singapore-138038800088851/",
      instagram: "",
    },
    {
      name: "Society of Jesus (Jesuits)",
      description:
        "The Society of Jesus is a Roman Catholic religious order of priests and brothers founded in 1540 by Saint Ignatius of Loyola and his companions. Members, commonly known as Jesuits, are called to serve the Church and world, especially where the needs are great, and beyond the traditional ministries of the Church. There are Jesuit parish priests, chaplains, educators, doctors, lawyers, astronomers, playwrights, scientists",
      category: "Religious Order",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fmas-jesuits-circle.png?alt=media&token=c9a3e68f-1611-45a2-b962-76151ddea7a7",
      link: "",
      website: "http://www.mas-jesuits.org/",
      email: "mas-info@jesuits.net",
      phone: "+65 6463 6022",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Courage and Encourage Singapore",
      description:
        "EnCourage is a Roman Catholic apostolate for parents, friends and family members of loved ones with same-sex attraction. EnCourage is a faithful witness to Catholic teaching on sexual morality which meets the needs of its members with compassion. EnCourage does not embrace the necessity of changing a loved one’s sexual attraction to seek support from our ministry.",
      category: "",
      keywords:
        "same-sex, lgbtq, gay, support group, returning catholics, community, lesbian, bisexual, transgender",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCourage-International.jpg?alt=media&token=ccc97d90-2ccb-40a7-a465-6d4b251cb2c8",
      link: "",
      website: "http://courage.org.sg/",
      email: "SpDirCourage@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/courage.sg",
      instagram: "https://www.instagram.com/courage.sg",
    },
    {
      name: "St Gabriel’s Foundation",
      description:
        "Aims to provide educational and welfare opportunities in a creative and sustained manner that develop the fullness of the human persons according to their God-given potential.",
      category: "",
      keywords: "foundation, welfare, youth, montfort education",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSGF-logo.png?alt=media&token=116449e4-a6e1-45fb-a24d-bfd24e2b39c0",
      link: "",
      website: "https://www.stgabrielsfoundation.org/",
      email: "enquiry@stgabrielsfoundation.org",
      phone: "+65 6769 5711",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Alpha in the Catholic Context Team (ACCT)",
      description:
        "Alpha is a space for anyone to explore questions of life and the Christian faith. It is a place to ask questions, and share their point of view in a safe and honest environment. Alpha is free and everyone is welcome.",
      category: "",
      keywords: "community",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAlpha.jpg?alt=media&token=05bc92e5-ba71-4c61-97be-4080a91604a8",
      link: "",
      website: "https://singapore.alpha.org/",
      email: "info@alpha.org.sg",
      phone: "+65 6469 5085",
      whatsapp: "",
      facebook: "https://www.facebook.com/alphasingapore",
      instagram: "https://www.instagram.com/alphasingapore/",
    },
    {
      name: "Catholic Prayer Society (CPS)",
      description:
        "Catholic Prayer Society had early beginnings as informal prayer meetings held by a group of working Catholics in the city or central business areas. This group, calling themselves “Catholics in the Marketplace” (CIMP), initially had Rosary sessions and adoration of the Blessed Sacrament. Eventually, it had the Eucharist celebrated at OUB Centre. This group helped in the formation of another group within the same area and together they became CPS Raffles Place (known as CPS Raffles), holding their Masses at various locations around Raffles Place. Another group in the CBD area, inspired by CIMP, became CPS Shenton Way (known as CPS Shenton) and the Eucharist was held at the Singapore Conference Hall. Catholic Prayer Society was approved by the Senate in 1994 as a private juridical person and was registered in August 1995 with the Registry of Societies.",
      category: "Community",
      keywords:
        "prayer group, community, support group, working adults, volunteer",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-Prayer-Society.png?alt=media&token=dd3cfd67-125e-4d46-b1aa-be0043efd388",
      link: "",
      website: "http://www.cps.org.sg/",
      email: "cps.contactus@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Apostolate for Catholic Truth (ACT)",
      description:
        "To promote the Catholic Faith by proclaiming the truths of the Catholic Church and explaining her practices and doctrines as taught by the Magisterium",
      category: "",
      keywords: "apologetics",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "andrewk@catholic.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Archdiocesan Divine Mercy Apostolate (Singapore)",
      description:
        "The Archdiocesan Divine Mercy Apostolate (ADMA) was formed in 1993 by the late Archbishop Gregory Yong. The mission of the ADMA was to introduce the Divine Mercy (DM) prayers and the way of life to parish prayer groups, neighbourhood Christian communities, and to provide a uniform and authentic version of true devotion.",
      category: "",
      keywords: "prayer group, devotion",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "yow.frederick@gmail.com",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic Bible Association (Mandarin-speaking)",
      description:
        "The Singapore Catholic Chinese Bible Association was started by a retiree named Chen Tian Rang and his wife Huang Rui Rong in 1975.  After Mr Chen passed on in 1979, the group continued to pursue Mr Chen’s will in promoting Bible reading. The Association later began to organize Bible classes, seminars and other spiritual activities in order to help its Catholic community study the Word and live the Catholic faith with devotion. Since 1979, the Association has grown to include bible study groups from all over Singapore. At present, it has 36 Bible study groups with 530 members.",
      category: "",
      keywords: "bible study, community, bible sharing",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FBible-Association.jpg?alt=media&token=21359eaa-3321-4e9d-a60a-2c92d230dba4",
      link: "",
      website: "https://sites.google.com/site/bibleassociation/",
      email: "bibleassociation@gmail.com",
      phone: "+65 6282 8428",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Community @ St Bernadette",
      description:
        "We are Filipino-speaking community at the parish of St Bernadette. We serve the Lord and the Parish through song and music, audiovisual presentation, and commentating and lectoring at Holy Mass on every first Sunday of the month, at 2.00 pm. We practice the singing of hymns on every last Sunday of the month, typically Tagalog and Filipino-language songs. ",
      category: "Filipino Community",
      keywords:
        "filipino community, song, volunteer, lector, reading, tagalog, filipino choir, filcom, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.stbernadette.org.sg/filipino-community",
      email: "csb.secretariat@catholic.org.sg",
      phone: "",
      whatsapp: "+6596518140",
      facebook: "https://www.facebook.com/stbernadettesg/",
      instagram: "",
    },
    {
      name: "Filipino Choir @ Christ the King",
      description:
        "The Barangay Choir hailed from the Barangay Christ the King (BCK), a Filipino community in our parish conceptualized on April 22, 1993. A small group of Filipinos then got together and on July 4, 1993 Barangay Christ was formed with the objective to cultivate the “Bayanihan Spirit” (Spirit of Cooperation) amongst the Filipinos in Singapore. ",
      category: "Filipino Community",
      keywords:
        "filipino community, filipino choir, barangay choir, barangay christ the king, bck, bayanihan, volunteer, tagalog, filcom, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "http://www.christtheking.com.sg/ministries/liturgical-committee/choir/filipino-choir/",
      email: "",
      phone: "+6593639480",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Community @ Church of the Risen Christ",
      description:
        "The CRC-FilCom is a community that aims to provide a sense of ‘home’ and belongingness to the filipino migrant workers, allowing them to cope from homesickness and from being a stranger in a diﬀerent land. It also aims to gather all Filipinos and provide a venue to grow spiritually and to share their God-given talent. The FilCom take part, help out and support the parish activities such as running the canteen every second Sunday of alternate months, Lay Apostolate Sunday, Holy Week Activities including the Feast Day Celebration.",
      category: "Filipino Community",
      keywords:
        "filipino community, migrant, volunteer, tagalog, filcom, Wardens, Lectors, Choir, Altar Boys, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.risenchrist.org.sg/ministries-groups/other-communities/filipino-community",
      email: "sjcoreste@yahoo.com",
      phone: "+659879 4729",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Apostolate @ Our Lady of Star of the Sea",
      description:
        "Filipino Apostolate has diverse ministries like Christ is the Answer, a family support group who meets once a week. There is a prayer meeting and Bible sharing which takes place in the home of each member. Block Rosary, a family devotion to Mama Mary. It is open to all families who wish to have the image of Our Lady of Fatima brought to their homes and pray the rosary daily and Agape, a Filipino choir serving in Sunday 5pm evening mass and 1st Saturday Tagalog mass. Apart from pastoral support and service to parish, the Filipino Apostolate also brings to life the observance of the Filipino Catholic traditions to our parish like All Soul’s Day, Simbang Gabi, Pabasa (Jesus Life & Passion Vigil), Our Lady of Penafrancia, Flores de Mayo and others.",
      category: "Filipino Community",
      keywords:
        "filipino community, migrant, tagalog, volunteer, block rosary, choir, family support group, bible sharing, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.olss.sg/index.php/ministries/filipino-apostolate",
      email: "jlpisigan@yahoo.com.sg",
      phone: "+65 98794572",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Committee @ St Francis of Assisi",
      description:
        "Filipino Committee at St Francis of Assisi are involved in different ministries like Legion of Mary, Liturgical Committee, Altar Servers, Lectors, Communion Ministers, Hospitality Ministry and Choir",
      category: "Filipino Community",
      keywords:
        "filipino community, migrant, volunteer, tagalog, filcom, wardens, lectors, choir, altar servers, hospitality ministry, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.csfa.sg/our-ministries/filipino-committee",
      email: "",
      phone: "+65 6264 0078 ",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Group @ Holy Family",
      description:
        "The Filipino Group was established as a support group for fellow Filipinos who worship at Holy Family Church.  We gather every Sunday for bible sharing, group prayer and sharing, and fellowship.  Once a month, our group will join the Society of St Vincent de Paul in visiting the homes of the elderly and entertain them with songs and dance. We have also set up a Skills Development Training Programme that provides opportunities for migrant workers to be productive, responsible citizens and attain their own level of success now and in the future. The program, which is run by migrant and local volunteers, offers skills courses in nursing aid, dress making, hair and beauty care, and basic computer skills.",
      category: "Filipino Community",
      keywords:
        "filipino community, migrant, volunteer, tagalog, filcom, wardens, lectors, choir, altar servers, hospitality ministry, skills development, nursing aid, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.holyfamily.org.sg/index.php/ministries/groups/filipino-group",
      email: "chf.secretariat@catholic.org.sg",
      phone: "+65 6344 0046",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Filipino Community @ St Mary",
      description:
        "We are a Filipino Language Community made up of Filipino parishioners of St Mary of the Angels who actively participate in the parish many functions and activities",
      category: "Filipino Community",
      keywords:
        "filipino community, migrant, volunteer, tagalog, filcom, wardens, lectors, choir, altar servers, hospitality ministry, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.stmary.sg/filipino",
      email: "cox.alviso@gmail.com",
      phone: "+65 6567 3866",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Malayalam Prayer Group @ Christ the King",
      description:
        "This group aims to build communion among the Malayalee Catholics in Ang Mo Kio and work towards a greater collaboration with the other ministries in the Church of Christ the King. To reach out and welcome the migrant workers from the State of Kerala in India, and  pray as one community.",
      category: "Malayalam Community",
      keywords:
        "malayalam community, prayer group, malayalee, migrant workers, indian community, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "http://www.christtheking.com.sg/ministries/prayer-committee/malayalam-prayer-group/",
      email: "w.silva@fugro.com",
      phone: "+65 94523122",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Malayalam Community @ St Francis of Assisi",
      description:
        "The main goal of the St. Francis of Assisi Boon Lay Malayalam community (SFABLMC) would be unity in liturgical services to praise, worship and celebrate masses in Malayalam under the umbrella of parish of St Francis of Assisi Church. The SFABLMC offers formation and ministries for the Malayalam speaking migrants and families around west region. As well as serving the spiritual needs of the large number migrants in this region through Malayalam Mass, prayer groups, Rosary groups, Bible readings and sharing. ",
      category: "Malayalam Community",
      keywords:
        "malayalam community, prayer group, malayalee, migrant workers, indian community, migrant support",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.csfa.sg/our-ministries/indian-committee/malayalam-community",
      email: "antu.joseph@csfaministries.sg ",
      phone: "+65 92300394",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Church of Our Lady Queen of Peace",
      description:
        "In 1940, due to overcrowding in central Singapore, many parishioners were relocated to the suburbs. As the number of Catholics increased in the East, there was a pressing need to set up a Church. With the onset of the Japanese Occupation and post war recovery, development plans for the Church of Our Lady Queen of Peace was interrupted. However, with the perseverance of Father Dupoirieux and the prayers of many faithful, the Church of Our Lady Queen of Peace was established on 4 April 1954.",
      category: "Church",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FOLQP.jpg?alt=media&token=b85f556d-7f87-45c1-9661-4c723b9322bd",
      link: "",
      website: "https://www.queenofpeace.sg/",
      email: "admin@queenofpeace.sg",
      phone: "+65 6744 2879",
      whatsapp: "",
      facebook: "https://www.facebook.com/churchofourladyqueenofpeacesg/",
      instagram: "https://www.instagram.com/queenofpeace.sg/",
    },
    {
      name: "Evangelization 2033",
      description:
        "A service of PRAYER, PROCLAMATION AND FORMATION encouraging the Training of Clergy, Religious and especially the Laity to become Missionary Disciples mainly through Schools of Evangelization",
      category: "",
      keywords: "evangelisation, prayer, formation, proclamation",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FEV2033-logo-300-674fa8db.webp?alt=media&token=65e3409f-965f-4391-9a24-d25d4d43c0ff",
      link: "",
      website: "http://www.evangelization2033.eu/",
      email: "ev2033@singnet.com.sg",
      phone: "+65 6736 4773",
      whatsapp: "",
      facebook: "https://www.facebook.com/www.evangelization2033.eu/",
      instagram: "",
    },
    {
      name: "Epiphany",
      description:
        "Epiphany’s mission is evangelisation, both in the local and overseas contexts. Epiphany evangelise through the spreading of mission awareness among the parishioners of the Church of the Immaculate Heart of Mary (IHM) in Singapore. It organises outreach events and encourages the parishioners of IHM to invite non-Catholic neighbours to participate in its events. In addition, Epiphany helps to maintain a Catholic presence in non-Catholic environments through humanitarian aid, mainly in the form of education programmes with the long-term aim of uplifting the standard of living among poor families. Epiphany seeks to show the face of Christ through visits, material aid and support of education to the poor.",
      category: "",
      keywords: "Education, Indonesia, Mission Trip, outreach",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FEpiphany.jpg?alt=media&token=997087a3-b38f-43b5-bb1b-3dd0983f7c1f",
      link: "",
      website: "",
      email: "epiphany@ihm.sg",
      phone: "+65 9842 5865",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "BE.live Ministry",
      description:
        "BE.live Ministry is born out of the unity of the spirit in Nicholas Lye, Mark and Janice Lim, who have the passion for bringing the faith to children and youths through the arts. We have since been blessed with a team of passionate and faith-driven creative arts professionals, together with whom we seek to praise God in all we do, encourage prayer and proclaim God`s love to children and youths through dance, drama, music and art. ",
      category: "",
      keywords:
        "preschool, children ministry, children arts, kids camp, children activities, kids dance, kids arts, kids music, music and art for kids",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FBe-Live.jpg?alt=media&token=f5943d6a-44d0-4f63-9643-869fe6965248",
      link: "",
      website: "https://beliveministry.wixsite.com/belive",
      email: "be.liveministry@gmail.com",
      phone: "+65 9796 6578",
      whatsapp: "",
      facebook: "https://www.facebook.com/beliveministry/",
      instagram: "https://www.instagram.com/be.live_ministry/",
    },
    {
      name: "HopeHouse",
      description:
        "HopeHouse provides residential programmes for youths at risk. Their vision is to be an Oasis of Hope, with the goal of giving youths hope for a new beginning. HopeHouse is open to male youths at risk, aged 16 and above, regardless of race and religion.",
      category: "",
      keywords:
        "youth, skill training, community services, Counselling, volunteer, male youth, young boys, shelter for boys, young male ",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fhopehouse.jpg?alt=media&token=1b2e6768-4c27-4f41-aecd-7fa4b3f764a4",
      link: "",
      website: "https://www.hopehouse.sg/",
      email: "volunteer@hopehouse.sg",
      phone: "+65 6348 1959",
      whatsapp: "",
      facebook: "https://www.facebook.com/HopeHouse-Ltd-566331027174123/",
      instagram: "",
    },
    {
      name: "El Shaddai DWXI- Prayer Partners Foundation International, Inc. (Singapore Chapter)",
      description:
        "A Catholic Charismatic Group in Singapore whose vision and mission is to spread the Good News",
      category: "",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Felshaddai.jpeg?alt=media&token=97076afe-6de4-47d6-bb14-c48404ee5bf2",
      link: "",
      website: "",
      email: "",
      phone: "+65 6474 0741",
      whatsapp: "",
      facebook: "https://www.facebook.com/ELSHADDAI.DWXI.PPFII.SINGAPORE/",
      instagram: "",
    },
    {
      name: "Rumah Majella (House of Formation)",
      description:
        "The community is called to share and reflect the spirit of the Redemptorist congregation and its charism to our Aspirants and Postulants through community life, prayer and active apostolic life.",
      category: "Community",
      keywords: "Formation",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "secretariatcssr@novenachurch.com",
      phone: "+65 6386 8019",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Society of St Vincent de Paul Shop",
      description:
        "In 2017, SSVP received a significant bequest from the estate of an anonymous donor. With this the Society acquired a building at 501 Geylang Road, renovated it and set up SSVP Shop, a social enterprise project, on the ground floor. ",
      category: "Shop",
      keywords:
        "thrift shop, charity shop, donate, social enterprise, catholic shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fssvpshop.jpeg?alt=media&token=0fff0f6f-66db-4e26-9c06-f95494ac0dfd",
      link: "",
      website: "https://ssvpsingapore.org/ssvp-shop/",
      email: "shop@ssvpsingapore.org",
      phone: "+65 6974 2480",
      whatsapp: "",
      facebook: "https://www.facebook.com/SSVPSHOPSINGAPORE/",
      instagram: "https://www.instagram.com/ssvpshop_sg/",
    },
    {
      name: "Congregation of the Sacred Hearts of Jesus and Mary (SS.CC)",
      description: "",
      category: "",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsscclogo5.png?alt=media&token=87e4ea4a-7fce-493e-a78e-ae1b095bc89d",
      link: "",
      website: "https://ssccindonesia.org/",
      email: "secretariatsscc@gmail.com",
      phone: "+65 6474 0582",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Society of St Vincent de Paul",
      description:
        "SSVP is a lay Catholic mission with 750 members that serves 3500 poor beneficiaries in Singapore, regardless of race, nationality and religion, so that they may lead resilient and dignified lives.",
      category: "Community",
      keywords:
        "volunteer, outreach, counselling, support for poor, support for less fortunate, financial aid,  support children, employment, giving cash grants, food rations, education support, medical bills aids, transport for sick, elderly, support group, charity",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSSVP.png?alt=media&token=844165b4-3869-4a31-a39c-b4ce70db6b38",
      link: "",
      website: "https://ssvpsingapore.org/",
      email: "enquiry@ssvpsingapore.org",
      phone: "+65 6801 7480",
      whatsapp: "",
      facebook: "https://www.facebook.com/SSVPSingapore/",
      instagram: "https://www.instagram.com/ssvpsingapore/",
    },
    {
      name: "His Excellency Archbishop Marek Zalewski - Apostolic Nuncio",
      description:
        "His Excellency Archbishop Marek Zalewski, ordained 27 May 1989.  Apostolic Nuncio - Apostolic Nunciature, 55 Waterloo Street #09-01, Singapore 187954. Contact number +65 6337 2466",
      category: "",
      keywords: "Apostolic Nuncio",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=438",
      email: "",
      phone: "+65 6337 2466",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Adrian Anthony - Priest",
      description:
        "Rev Fr Adrian Anthony, ordained 06 February 1971.  Priest - 29 Hillside Drive, Singapore 548947",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=18",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Abel Antony - Priest",
      description:
        "Rev Fr Abel Antony, ordained 08 May 2019.  Priest - Church of Christ the King, 2221 Ang Mo Kio Avenue 8, Singapore 569809. Contact number +65 6459 9957",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=463",
      email: "",
      phone: "+65 6459 9957",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Alex Chua - Priest",
      description: "Rev Fr Alex Chua, ordained 02 January 2000.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=171",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Adrian Yeo - Priest",
      description:
        "Rev Fr Adrian Yeo, ordained 06 November 1999.  Priest - Church of the Holy Family, 6 Chapel Road, Singapore 429509. Contact number +65 6344 0046",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=158",
      email: "",
      phone: "+65 6344 0046",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Albert Ng - Priest",
      description:
        "Rev Fr Albert Ng, ordained 19 January 1999.  Priest - Church of the Transfiguration, 51 Punggol Central, Singapore 828725. Contact number +65 6341 9718",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=152",
      email: "",
      phone: "+65 6341 9718",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "H. Em. Cardinal William SC Goh - Archbishop of Singapore",
      description:
        "H. Em. Cardinal William SC Goh, ordained 01 May 1985.  Archbishop of Singapore - Archbishop’s House, 31 Victoria Street, Singapore 187997. Contact number +65 6895 1555; Fax +65 6895 1551",
      category: "",
      keywords: "Archbishop of Singapore",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=60",
      email: "",
      phone: "+65 6895 1555; Fax +65 6895 1551",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Most Rev Nicholas Chia, DD - Archbishop Emeritus",
      description:
        "Most Rev Nicholas Chia, DD, ordained 26 January 1964.  Archbishop Emeritus - St Theresa’s Home, 49 Upper Thomson Road , Singapore 574325. Contact number +65 6256 2532",
      category: "",
      keywords: "Archbishop Emeritus",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=34",
      email: "",
      phone: "+65 6256 2532",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Aloysius Doraisamy - Priest",
      description:
        "Rev Fr Aloysius Doraisamy, ordained 26 January 1964.  Priest - c/o Archbishop’s House , 31 Victoria Street, Singapore 187997",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=49",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Aloysius Ong - Priest",
      description:
        "Rev Fr Aloysius Ong, ordained 29 June 2003.  Priest - Church of St Bernadette, 12 Zion Road, Singapore 247731. Contact number +65 6737 3529",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=321",
      email: "",
      phone: "+65 6737 3529",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Arun Bellarmin - Priest",
      description:
        "Rev Fr Arun Bellarmin, ordained 12 May 2010.  Priest - Church of the Risen Christ, 91 Toa Payoh Central, Singapore 319193. Contact number +65 6352 8737",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=473",
      email: "",
      phone: "+65 6352 8737",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Anthony Kenny Tan - Priest",
      description:
        "Rev Fr Anthony Kenny Tan, ordained 04 September 2005.  Priest - Church of Our Lady of Perpetual Succour, 31 Siglap Hill , Singapore 456085. Contact number +65 6445 6092",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=57",
      email: "",
      phone: "+65 6445 6092",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Andrew Wong - Priest",
      description:
        "Rev Fr Andrew Wong, ordained 21 June 1992.  Priest - Church of St Vincent De Paul, 301 Yio Chu Kang Road , Singapore 805910. Contact number +65 6481 3745",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=134",
      email: "",
      phone: "+65 6481 3745",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Anthony Ho - Priest",
      description:
        "Rev Fr Anthony Ho, ordained 08 December 1968.  Priest - c/o St Anne’s Church, 66 Sengkang East Way , Singapore 548593",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=65",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Alphonsus Dominic - Priest",
      description:
        "Rev Fr Alphonsus Dominic, ordained 01 May 2013.  Priest - Church of the Transfiguration, 51 Punggol Central, Singapore 828725. Contact number +65 6341 9718",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=369",
      email: "",
      phone: "+65 6341 9718",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Msgr Ambrose Vaz - Monsignor",
      description:
        "Rev Msgr Ambrose Vaz, ordained 05 June 1981.  Monsignor - c/o Church of St Teresa , 510 Kampong Bahru Road, Singapore 099446. Contact number +65 6011 1179",
      category: "",
      keywords: "Monsignor",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=129",
      email: "",
      phone: "+65 6011 1179",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Dinil Abraham, CMI - Priest",
      description:
        "Rev Fr Dinil Abraham, CMI, ordained 28 December 2012.  Priest - Church of St Bernadette, 12 Zion Road, Singapore 247731. Contact number +65 6737 3529",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=468",
      email: "",
      phone: "+65 6737 3529",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Antony Kuttianickal, CMI - Priest",
      description:
        "Rev Fr Antony Kuttianickal, CMI, ordained 11 May 1981.  Priest - Church of the Transfiguration, 51 Punggol Central, Singapore 828725. Contact number +65 6341 9718",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=437",
      email: "",
      phone: "+65 6341 9718",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Mark Ma Shuai, CDD - Priest",
      description:
        "Rev Fr Mark Ma Shuai, CDD, ordained 14 September 2020.  Priest - 21 Hazel Park Terrace, #16-07, Singapore 678946. Contact number +65 6575 7929",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=454",
      email: "",
      phone: "+65 6575 7929",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Rodolfo Ricardo Valdes Chavarria,  - Priest",
      description:
        "Rev Fr Rodolfo Ricardo Valdes Chavarria, ordained 04 May 2013.  Priest - 17 Prome Road, Singapore 328325. Contact number +65 6811 5925",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=457",
      email: "",
      phone: "+65 6811 5925",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Msgr Peter Zhang SiQian, CDD - Monsignor",
      description:
        "Rev Msgr Peter Zhang SiQian, CDD, ordained 26 October 2003.  Monsignor - St Joseph’s Church (Bukit Timah), 620 Upper Bukit Timah Road, Singapore 678116. Contact number +65 6760 4636",
      category: "",
      keywords: "Monsignor",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=359",
      email: "",
      phone: "+65 6760 4636",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Michael Chan,  - Priest",
      description:
        "Rev Fr Michael Chan, ordained 26 August 1990.  Priest - 5 Whitley Road, Singapore 297832. Contact number +65 6502 2116",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=30",
      email: "",
      phone: "+65 6502 2116",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joseph Zhang Liyong, CDD - Priest",
      description:
        "Rev Fr Joseph Zhang Liyong, CDD, ordained 20 August 2012.  Priest - 21 Hazel Park Terrace, #16-07, Singapore 678946. Contact number +65 6575 7529",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=361",
      email: "",
      phone: "+65 6575 7529",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Luke Zhang Wei Gang, CDD - Priest",
      description:
        "Rev Fr Luke Zhang Wei Gang, CDD, ordained 14 September 2020.  Priest - Church of the Holy Trinity, 20 Tampines Street 11 , Singapore 529455. Contact number +65 6784 2332",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=458",
      email: "",
      phone: "+65 6784 2332",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joe Lopez Carpio,  - Priest",
      description:
        "Rev Fr Joe Lopez Carpio, ordained 07 June 1998.  Priest - 7 Heng Mui Keng Terrace, Singapore 119611. Contact number +65 6338 3167",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=307",
      email: "",
      phone: "+65 6338 3167",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Robertus Sarwiseso, CICM - Priest",
      description:
        "Rev Fr Robertus Sarwiseso, CICM, ordained 04 December 1993.  Priest - Church of The Holy Cross, 450 Clementi Avenue 1, Singapore 129955. Contact number +65 6776-1330",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=211",
      email: "",
      phone: "+65 6776-1330",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Romeo Yu Chang, CICM - Priest",
      description:
        "Rev Fr Romeo Yu Chang, CICM, ordained 08 September 1990.  Priest - Church of St Teresa, 510 Kampong Bahru Road, Singapore 099446. Contact number +65 6011 1171",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=218",
      email: "",
      phone: "+65 6011 1171",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Avelino Marin,  - Priest",
      description:
        "Rev Fr Avelino Marin, ordained 05 September 1993.  Priest - 17 Prome Road, Singapore 328325. Contact number +65 6811 5925",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=308",
      email: "",
      phone: "+65 6811 5925",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Peter Koh Joo Kheng, CICM - Priest",
      description:
        "Rev Fr Peter Koh Joo Kheng, CICM, ordained 21 May 1997.  Priest - Eugenia Court, 4 Pandan Valley #07-418, Singapore 597628. Contact number +65 6468 2645",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=466",
      email: "",
      phone: "+65 6468 2645",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Frank Aniban,  - Priest",
      description:
        "Rev Fr Frank Aniban, ordained 06 September 1992.  Priest - 17 Prome Road, Singapore 328325. Contact number +65 6811 5925",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=380",
      email: "",
      phone: "+65 6811 5925",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul E Staes, CICM - Priest",
      description:
        "Rev Fr Paul E Staes, CICM, ordained 06 August 1961.  Priest - Eugenia Court, 4 Pandan Valley #07-418 , Singapore 597628. Contact number +65 6468 2645",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=112",
      email: "",
      phone: "+65 6468 2645",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Damien Lim Guan Heng,  - Priest",
      description:
        "Rev Fr Damien Lim Guan Heng, ordained 14 May 2011.  Priest - 7 Heng Mui Keng Terrace, Singapore 119611. Contact number +65 6779 0652",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=439",
      email: "",
      phone: "+65 6779 0652",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Kamelus Kamus, CICM - Priest",
      description:
        "Rev Fr Kamelus Kamus, CICM, ordained 30 April 1995.  Priest - Church of the Holy Spirit, 248 Upper Thomson Road , Singapore 574371. Contact number +65 6264 8318",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=348",
      email: "",
      phone: "+65 6264 8318",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Johan Wongso, SS.CC - Priest",
      description:
        "Rev Fr Johan Wongso, SS.CC, ordained 26 June 1999.  Priest - Blessed Sacrament Church, 1 Commonwealth Drive , Singapore 149603. Contact number +65 6474 0582 ext 103",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=350",
      email: "",
      phone: "+65 6474 0582 ext 103",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Rusdi Santoso, SS.CC - Priest",
      description:
        "Rev Fr Rusdi Santoso, SS.CC, ordained 15 August 2013.  Priest - Blessed Sacrament Church, 1 Commonwealth Drive , Singapore 149603. Contact number +65 6474 0582 ext. 102",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=440",
      email: "",
      phone: "+65 6474 0582 ext. 102",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Sambodo Sru Ujianto, SS.CC - Priest",
      description:
        "Rev Fr Sambodo Sru Ujianto, SS.CC, ordained 23 July 2008.  Priest - Blessed Sacrament Church, 1 Commonwealth Drive , Singapore 149603",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=365",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Vincent Low, CSsR - Priest",
      description:
        "Rev Fr Vincent Low, CSsR, ordained 03 August 1986.  Priest - 11 Nallur Road, Singapore 456610",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=418",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Terence Wee, CSsR - Priest",
      description: "Rev Fr Terence Wee, CSsR, ordained 01 August 2013.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=379",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Anthony Hutjes, SS.CC - Priest",
      description:
        "Rev Fr Anthony Hutjes, SS.CC, ordained 29 April 1973.  Priest - Blessed Sacrament Church, 1 Commonwealth Drive , Singapore 149603. Contact number +65 6474 0582 ext 104",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=67",
      email: "",
      phone: "+65 6474 0582 ext 104",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Simon Tan, CSsR - Priest",
      description:
        "Rev Fr Simon Tan, CSsR, ordained 01 January 1998.  Priest - 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=162",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Simon Pereira, CSsR - Priest",
      description:
        "Rev Fr Simon Pereira, CSsR, ordained 18 December 1977.  Priest. Contact number Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=87",
      email: "",
      phone: "Priest",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul Pang, CSsR - Priest",
      description:
        "Rev Fr Paul Pang, CSsR, ordained 28 August 1966.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=405",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Sikstus Atawolo, CSsR - Priest",
      description:
        "Rev Fr Sikstus Atawolo, CSsR, ordained 20 October 2020.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=464",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Peter Wee, CSsR - Priest",
      description:
        "Rev Fr Peter Wee, CSsR, ordained 05 October 1986.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=132",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Glenn de Cruz, CSsR - Priest",
      description:
        "Rev Fr Glenn de Cruz, CSsR, ordained 18 December 1988.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road , Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=43",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr J H (Gino) Henriques, CSsR - Priest",
      description:
        "Rev Fr J H (Gino) Henriques, CSsR, ordained 17 September 1960.  Priest - Cavenagh House, 100 Clemenceau Ave North #06-109, Singapore 229491. Contact number +65 6736 4773",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=176",
      email: "",
      phone: "+65 6736 4773",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Gerard Louis, CSsR - Priest",
      description:
        "Rev Fr Gerard Louis, CSsR, ordained 06 October 2006.  Priest - 11 Nallur Road, Singapore 456610",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=413",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Francis Vijayan, CSsR - Priest",
      description:
        "Rev Fr Francis Vijayan, CSsR, ordained 08 September 2016.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=419",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Eugene Lee, CSsR - Priest",
      description:
        "Rev Fr Eugene Lee, CSsR, ordained 27 June 2013.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=378",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Clement Lee, CSsR - Priest",
      description:
        "Rev Fr Clement Lee, CSsR, ordained 03 August 1986.  Priest - Church of St Alphonsus (Novena Church), 300 Thomson Road, Singapore 307653",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=353",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Michael Arro, MEP - Priest",
      description:
        "Rev Fr Michael Arro, MEP, ordained 03 July 1955.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8725",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=19",
      email: "",
      phone: "+65 6902 8725",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul Ngo Thanh Phong, MEP - Priest",
      description:
        "Rev Fr Paul Ngo Thanh Phong, MEP, ordained 07 March 2004.  Priest - Church of the Immaculate Heart of Mary, 24 Highland Road, Singapore 549115",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=260",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Rene Nicolas, MEP - Priest",
      description:
        "Rev Fr Rene Nicolas, MEP, ordained 02 February 1956.  Priest - St Theresa’s Home, 49 Upper Thomson Road, Singapore 574325. Contact number +65 6995 2415",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=95",
      email: "",
      phone: "+65 6995 2415",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Gregoire van Giang, MEP - Priest",
      description:
        "Rev Fr Gregoire van Giang, MEP, ordained 19 June 1993.  Priest - Church of Our Lady Star of the Sea, 10 Yishun Street 22 , Singapore 768579. Contact number +65 6257 4229",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=396",
      email: "",
      phone: "+65 6257 4229",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Jean Nguyen, MEP - Priest",
      description:
        "Rev Fr Jean Nguyen, MEP, ordained 25 June 1988.  Priest - Church of St Teresa, 510 Kampong Bahru Road , Singapore 099446. Contact number +65 6011 1175",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=94",
      email: "",
      phone: "+65 6011 1175",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Dominique Deme, MEP - Priest",
      description:
        "Rev Fr Dominique Deme, MEP, ordained 25 June 2017.  Priest - Church of Our Lady of Perpetual Succour, 31 Siglap Hill , Singapore 456085. Contact number +65 6241 8905",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=434",
      email: "",
      phone: "+65 6241 8905",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Andre Christophe, MEP - Priest",
      description:
        "Rev Fr Andre Christophe, MEP, ordained 29 June 1955.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=39",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Thomas Lim, OCD - Priest",
      description:
        "Rev Fr Thomas Lim, OCD, ordained 01 May 1997.  Priest - St. John of the Cross Community (Punggol Formation House), 203J, Ponggol Seventeenth Avenue, Singapore 829729. Contact number +65 6387 0530",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=82",
      email: "",
      phone: "+65 6387 0530",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Jeffrey Tan, OCD - Priest",
      description:
        "Rev Fr Jeffrey Tan, OCD, ordained 13 November 2012.  Priest - Church of Sts Peter and Paul, 225A Queen Street, Singapore 188551. Contact number +65 6337 2585",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=414",
      email: "",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joseph Koh, OCD - Priest",
      description:
        "Rev Fr Joseph Koh, OCD, ordained 05 August 1992.  Priest - St. John of the Cross Community (House of Formation for Postulants), 203J Ponggol 17th Avenue, Singapore 829729. Contact number +65 6387 0530",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=146",
      email: "",
      phone: "+65 6387 0530",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Chua, OCD - Priest",
      description:
        "Rev Fr John Chua, OCD, ordained 30 April 1996.  Priest - 203J Punggol 17th Avenue, Singapore 829729. Contact number +65 6387 0530",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=164",
      email: "",
      phone: "+65 6387 0530",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Ferdinand Purnomo, OCD - Priest",
      description:
        "Rev Fr Ferdinand Purnomo, OCD, ordained 28 August 2011.  Priest - Church of Sts Peter and Paul, 225A Queen Street , Singapore 188551. Contact number +65 6337 2585",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=354",
      email: "",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Emmanuel Teo, OCD - Priest",
      description:
        "Rev Fr Emmanuel Teo, OCD, ordained 14 September 2020.  Priest - St John of the Cross (Punggol Formation House), 203J, Ponggol Seventeenth Ave, Singapore 829729. Contact number +65 6387 0530",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=452",
      email: "",
      phone: "+65 6387 0530",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Edward Lim, OCD - Priest",
      description:
        "Rev Fr Edward Lim, OCD, ordained 15 October 2012.  Priest - Church of Sts Peter and Paul, 225A Queen Street, Singapore 188551. Contact number +65 6337 2585",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=362",
      email: "",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Daniel Lim, OCD - Priest",
      description:
        "Rev Fr Daniel Lim, OCD, ordained 05 May 1996.  Priest - St John of the Cross Community, 203J Ponggol 17th Avenue, Singapore 829729. Contact number +65 6387 0530",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=202",
      email: "",
      phone: "+65 6387 0530",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Bernard Wee, OCD - Priest",
      description:
        "Rev Fr Bernard Wee, OCD, ordained 21 June 2013.  Priest - Church of Sts Peter and Paul, 225A Queen Street, Singapore 188551. Contact number +65 6337 2585",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=376",
      email: "",
      phone: "+65 6337 2585",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Jerome Leon, SJ - Priest",
      description:
        "Rev Fr Jerome Leon, SJ, ordained 19 August 2017.  Priest - Kingsmead Hall, 8 Victoria Park Rd, Singapore 266492",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=432",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Matthew Tan, SJ - Priest",
      description:
        "Rev Fr Matthew Tan, SJ, ordained 26 July 2015.  Priest - St Francis Xavier Residence, Kingsmead Hall, 8 Victoria Park Road, Singapore 266492. Contact number +65 6466 3225",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=404",
      email: "",
      phone: "+65 6466 3225",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Leslie Raj, SJ - Priest",
      description:
        "Rev Fr Leslie Raj, SJ, ordained 09 January 1993.  Priest - St Francis Xavier Residence, Kingsmead Hall, 8 Victoria Park Road, Singapore 266492. Contact number +65 6466 0625 ext 33",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=104",
      email: "",
      phone: "+65 6466 0625 ext 33",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr James Tan, SJ - Priest",
      description:
        "Rev Fr James Tan, SJ, ordained 29 July 2006.  Priest - Loyola Jesuit Novitiate , Kingsmead Hall, 8 Victoria Park Road, Singapore 266492. Contact number +65 6469 7356",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=225",
      email: "",
      phone: "+65 6469 7356",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Gregory Tan, SJ - Priest",
      description:
        "Rev Fr Gregory Tan, SJ, ordained 26 July 2015.  Priest - Church of St Ignatius, 120 King’s Road, Singapore 268172. Contact number +65 6466 0625",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=430",
      email: "",
      phone: "+65 6466 0625",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Colin Tan, SJ - Priest",
      description:
        "Rev Fr Colin Tan, SJ, ordained 02 October 1999.  Priest - Church of St Ignatius, 120 King’s Road, Singapore 268172. Contact number +65 6466 0625",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=157",
      email: "",
      phone: "+65 6466 0625",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Christopher Soh, SJ - Priest",
      description:
        "Rev Fr Christopher Soh, SJ, ordained 30 July 2005.  Priest - St Francis Xavier Residence, Kingsmead Hall, 8 Victoria Park Road, Singapore 266492. Contact number +65 6466 3225 ext 25",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=223",
      email: "",
      phone: "+65 6466 3225 ext 25",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Agustinus Tanudjaja, SJ - Priest",
      description:
        "Rev Fr Agustinus Tanudjaja, SJ, ordained 28 April 2012.  Priest - St Francis Xavier Residence (1951), Kingsmead Hall, 8 Victoria Park Road, Singapore 266492. Contact number +65 6466 0625 ext 18 (Office)",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=389",
      email: "",
      phone: "+65 6466 0625 ext 18 (Office)",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Lourdusamy Prasanna - Priest",
      description:
        "Rev Fr Lourdusamy Prasanna, ordained 04 May 2015.  Priest - St Joseph’s Church (Bukit Timah), 620 Upper Bukit Timah Road , Singapore 678116. Contact number +65 6769 1666",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=445",
      email: "",
      phone: "+65 6769 1666",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Adrian Danker, SJ - Priest",
      description:
        "Rev Fr Adrian Danker, SJ, ordained 29 June 2013.  Priest - Church of the Sacred Heart, 111 Tank Road, Singapore 238069. Contact number +65 6238 1104",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=375",
      email: "",
      phone: "+65 6238 1104",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Leo Justin Chinnappan, HGN - Priest",
      description:
        "Rev Fr Leo Justin Chinnappan, HGN, ordained 26 April 2013.  Priest - Church of Our Lady of Lourdes, 50 Ophir Road, Singapore 188690",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=442",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joe Dinesh, HGN - Priest",
      description:
        "Rev Fr Joe Dinesh, HGN, ordained 10 May 2014.  Priest - Church of Our Lady Star of the Sea, 10 Yishun Street 22 , Singapore 768579. Contact number +65 6257 4229",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=441",
      email: "",
      phone: "+65 6257 4229",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar William Lee, OFM - Franciscan Friar",
      description:
        "Friar William Lee, OFM, ordained 28 May 2010.  Franciscan Friar - San Damiano Franciscan Centre, 5 Bukit Batok East Avenue 2, Church of St Mary of the Angels, Singapore 659918",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=270",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Rowland Yeo, OFM - Franciscan Friar",
      description:
        "Friar Rowland Yeo, OFM, ordained 28 November 2015.  Franciscan Friar - Greccio Friary, 4A Chestnut Drive, Singapore 679330",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=214",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Michael D’Cruz, OFM - Franciscan Friar",
      description:
        "Friar Michael D’Cruz, OFM, ordained 03 August 1997.  Franciscan Friar - Church of St Mary of the Angels, 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6567 3866",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=173",
      email: "",
      phone: "+65 6567 3866",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Robin Toha, OFM - Franciscan Friar",
      description:
        "Friar Robin Toha, OFM, ordained 21 June 2022.  Franciscan Friar - San Damiano Franciscan Centre, 5 Bukit Batok East Ave 2, Singapore 659918. Contact number +65 6560 0362",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=474",
      email: "",
      phone: "+65 6560 0362",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Martin Low, OFM - Franciscan Friar",
      description:
        "Friar Martin Low, OFM, ordained 03 August 1997.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6777 2523",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=86",
      email: "",
      phone: "+65 6777 2523",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Justin Lim, OFM - Franciscan Friar",
      description:
        "Friar Justin Lim, OFM, ordained 28 May 2010.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Avenue 2 , Singapore 659918. Contact number +65 6896 1050",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=271",
      email: "",
      phone: "+65 6896 1050",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Joseph Nasanathan, OFM - Franciscan Friar",
      description:
        "Friar Joseph Nasanathan, OFM, ordained 03 August 1997.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6896 1050 Ext 243",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=181",
      email: "",
      phone: "+65 6896 1050 Ext 243",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Julian Mariaratnam, OFM - Franciscan Friar",
      description:
        "Friar Julian Mariaratnam, OFM, ordained 07 February 1998.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Ave 2, Singapore 659918",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=269",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar John-Paul Tan, OFM, JCL - Franciscan Friar",
      description:
        "Friar John-Paul Tan, OFM, JCL, ordained 17 August 1990.  Franciscan Friar - 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6896 1050",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=115",
      email: "",
      phone: "+65 6896 1050",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar John Derrick Yap, OFM - Franciscan Friar",
      description:
        "Friar John Derrick Yap, OFM, ordained 28 May 2010.  Franciscan Friar - 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6896 1050",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=272",
      email: "",
      phone: "+65 6896 1050",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Jason Richard, OFM - Franciscan Friar",
      description:
        "Friar Jason Richard, OFM, ordained 12 February 2017.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Ave 2, Singapore 659918",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=424",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar John Wong, OFM - Franciscan Friar",
      description:
        "Friar John Wong, OFM, ordained 02 December 2000.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6896 1050 (messages only)",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=289",
      email: "",
      phone: "+65 6896 1050 (messages only)",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Gerard Victor, OFM - Franciscan Friar",
      description:
        "Friar Gerard Victor, OFM, ordained 23 July 1998.  Franciscan Friar - Church of St Mary of the Angels, 5 Bukit Batok East Avenue 2, Singapore 659918. Contact number +65 6567 3866",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=163",
      email: "",
      phone: "+65 6567 3866",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar David Garcia, OP - Dominican Friar",
      description:
        "Friar David Garcia, OP, ordained 08 August 1993.  Dominican Friar - San Francisco de Capillas House, 51 Kensington Park Road, Singapore 557285. Contact number +65 6282 8627",
      category: "",
      keywords: "Dominican Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=178",
      email: "",
      phone: "+65 6282 8627",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Aiden Peter, OFM - Franciscan Friar",
      description:
        "Friar Aiden Peter, OFM, ordained 27 October 2019.  Franciscan Friar - St Anthony Friary, 5 Bukit Batok East Ave 2, Singapore 659918. Contact number +65 6896 1050",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=449",
      email: "",
      phone: "+65 6896 1050",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Francisco Jude Basnayake, OFM - Franciscan Friar",
      description:
        "Friar Francisco Jude Basnayake, OFM, ordained 23 January 2016.  Franciscan Friar - Greccio Friary , 4A Chestnut Drive, Singapore 679330",
      category: "",
      keywords: "Franciscan Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=416",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Friar Antonio Gonzalez, OP - Dominican Friar",
      description:
        "Friar Antonio Gonzalez, OP, ordained 07 July 1974.  Dominican Friar - San Francisco de Capillas House, 51 Kensington Park Road, Singapore 557285. Contact number +65 6282 8627",
      category: "",
      keywords: "Dominican Friar",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=175",
      email: "",
      phone: "+65 6282 8627",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Xavier Nathanial - Priest",
      description:
        "Rev Fr Xavier Nathanial, ordained 13 April 2005.  Priest - Church of St Francis Xavier, 63A Chartwell Drive, Singapore 558758. Contact number +65 6280 8331",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=462",
      email: "",
      phone: "+65 6280 8331",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Vincent Chee - Priest",
      description: "Rev Fr Vincent Chee, ordained 20 December 1969.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=33",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Venus Praveen - Priest",
      description:
        "Rev Fr Venus Praveen, ordained 02 May 2002.  Priest - Church of Divine Mercy, 19 Pasir Ris Street 72, Singapore 518771. Contact number +65 6583 1694",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=465",
      email: "",
      phone: "+65 6583 1694",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Timothy Yeo - Priest",
      description:
        "Rev Fr Timothy Yeo, ordained 17 July 1997.  Priest - Church of Our Lady Queen of Peace, 4 Sandy Lane , Singapore 437321. Contact number +65 6744 0347",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=141",
      email: "",
      phone: "+65 6744 0347",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Valerian Cheong - Priest",
      description:
        "Rev Fr Valerian Cheong, ordained 07 August 2005.  Priest - St Francis Xavier Seminary Building, 618 Upper Bukit Timah Road #02-01, Singapore 678214. Contact number +65 6906 8451",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=216",
      email: "",
      phone: "+65 6906 8451",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Terence Kesavan - Priest",
      description:
        "Rev Fr Terence Kesavan, ordained 01 January 2014.  Priest. Contact number Catechetical Director",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=382",
      email: "",
      phone: "Catechetical Director",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Terence Pereira - Priest",
      description:
        "Rev Fr Terence Pereira, ordained Church of St Michael.  Priest - +65 6291 9272 ext. 6",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=102",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Stephen Yim - Priest",
      description:
        "Rev Fr Stephen Yim, ordained 04 July 1998.  Priest - Church of the Sacred Heart, 111 Tank Road, Singapore 238069. Contact number +65 6238 1097",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=140",
      email: "",
      phone: "+65 6238 1097",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Stanislaus Pang - Priest",
      description:
        "Rev Fr Stanislaus Pang, ordained 22 June 2003.  Priest - Church of the Holy Family, 6 Chapel Road, Singapore 429509. Contact number +65 6344 0046",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=198",
      email: "",
      phone: "+65 6344 0046",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Simon Ho - Priest",
      description:
        "Rev Fr Simon Ho, ordained 24 June 2022.  Priest - St Anne’s Church, 66 Sengkang East Way, Singapore 548593. Contact number +65 6386 5072 Ext.612",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=475",
      email: "",
      phone: "+65 6386 5072 Ext.612",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Samuel Lim - Priest",
      description:
        "Rev Fr Samuel Lim, ordained 09 August 2012.  Priest - Cathedral of the Good Shepherd, ’A’ Queen Street , Singapore 188533. Contact number +65 6337 2036",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=360",
      email: "",
      phone: "+65 6337 2036",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Sam Thadatheil John - Priest",
      description:
        "Rev Fr Sam Thadatheil John, ordained 16 March 2010.  Priest - Church of St Michael, 17 St Michael’s Road, Singapore 327976. Contact number +65 6291 9272 ext. 7",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=450",
      email: "",
      phone: "+65 6291 9272 ext. 7",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Robert Francois -Xavier Marie - Priest",
      description:
        "Rev Fr Robert Francois -Xavier Marie, ordained 24 June 2012.  Priest - MEP Mission House , 47-A La Salle Street, Singapore 456947",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=460",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Richards Ambrose - Priest",
      description:
        "Rev Fr Richards Ambrose, ordained 28 July 1993.  Priest - Church of Our Lady Star of the Sea, 10 Yishun Street 22 , Singapore 768579. Contact number +65 6257 4229",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=17",
      email: "",
      phone: "+65 6257 4229",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Reinhold Sahner - Priest",
      description:
        "Rev Fr Reinhold Sahner, ordained 10 March 1990.  Priest - St Anthony Friary, 5 Bukit Batok East Ave 2, Singapore 659918",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=459",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Peter Tan - Priest",
      description:
        "Rev Fr Peter Tan, ordained 25 February 1989.  Priest - Church of Divine Mercy, 19 Pasir Ris Street 72 , Singapore 518771. Contact number +65 6583 6971",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=117",
      email: "",
      phone: "+65 6583 6971",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Peter Koh - Priest",
      description:
        "Rev Fr Peter Koh, ordained 09 June 1986.  Priest - Church of St Francis of Assisi, 200 Boon Lay Avenue, Singapore 649964. Contact number +65 6261 2931",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=74",
      email: "",
      phone: "+65 6261 2931",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul Lim - Priest",
      description:
        "Rev Fr Paul Lim, ordained 04 November 1984.  Priest - Church of Christ the King, 2221 Ang Mo Kio Avenue 8, Singapore 569809. Contact number +65 6459 9958",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=81",
      email: "",
      phone: "+65 6459 9958",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul Yeo - Priest",
      description:
        "Rev Fr Paul Yeo, ordained 17 May 1992.  Priest - Church of Our Lady of Lourdes, 50 Ophir Road, Singapore 188690",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=138",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Paul Goh - Priest",
      description:
        "Rev Fr Paul Goh, ordained 20 December 1969.  Priest - Church of the Holy Spirit, 248 Upper Thomson Road, Singapore 574371. Contact number +65 6458 7459",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=59",
      email: "",
      phone: "+65 6458 7459",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Patrick Goh - Priest",
      description:
        "Rev Fr Patrick Goh, ordained 13 December 1969.  Priest - Church of St Bernadette, 12 Zion Road, Singapore 247731. Contact number +65 6737 7329",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=407",
      email: "",
      phone: "+65 6737 7329",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Michael Teo - Priest",
      description:
        "Rev Fr Michael Teo, ordained 15 December 1968.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8726",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=121",
      email: "",
      phone: "+65 6902 8726",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Michael Sitaram - Priest",
      description:
        "Rev Fr Michael Sitaram, ordained 07 January 1990.  Priest - Church of Our Lady of Lourdes, 50 Ophir Road, Singapore 188690",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=111",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Majo Marottikkal - Priest",
      description:
        "Rev Fr Majo Marottikkal, ordained 30 December 2004.  Priest - St Anne’s Church, 66 Sengkang East Way, Singapore 548593. Contact number +65 6386 5072 Ext.611",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=476",
      email: "",
      phone: "+65 6386 5072 Ext.611",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Majo Mathew - Priest",
      description:
        "Rev Fr Majo Mathew, ordained 29 December 2010.  Priest - Church of the Holy Family, 6 Chapel Road, Singapore 429509. Contact number +65 6344 0046",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=472",
      email: "",
      phone: "+65 6344 0046",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Luke Fong - Priest",
      description:
        "Rev Fr Luke Fong, ordained 20 June 2001.  Priest - Church of the Immaculate Heart of Mary, 24 Highland Road, Singapore 549115",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=174",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Kenson Koh - Priest",
      description:
        "Rev Fr Kenson Koh, ordained 25 July 1995.  Priest - Church of the Nativity of the BVM, 1259 Upper Serangoon Road, Singapore 534795. Contact number +65 6283 9344",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=73",
      email: "",
      phone: "+65 6283 9344",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Justin Yip - Priest",
      description:
        "Rev Fr Justin Yip, ordained 14 September 2020.  Priest - Church of the Nativity of the Blessed Virgin Mary, 1259 Upper Serangoon Road , Singapore 534795. Contact number +65 6214 0545",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=453",
      email: "",
      phone: "+65 6214 0545",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Jude David - Priest",
      description:
        'Rev Fr Jude David, ordained 01 January 2014.  Priest - Cathedral of the Good Shepherd, "A" Queen Street, Singapore 188533. Contact number +65 6337 2036',
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=383",
      email: "",
      phone: "+65 6337 2036",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joseph Kace - Priest",
      description:
        "Rev Fr Joseph Kace, ordained 25 April 2009.  Priest - Church of the Immaculate Heart of Mary, 24 Highland Road, Singapore 549115",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=436",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Johnson Fernandez - Priest",
      description:
        "Rev Fr Johnson Fernandez, ordained 13 February 1971.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8728",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=54",
      email: "",
      phone: "+65 6902 8728",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Joseph Fenelon - Priest",
      description:
        "Rev Fr John Joseph Fenelon, ordained 10 June 1998.  Priest - Church of St Teresa, 510 Kampong Bahru Road, Singapore 099446. Contact number +65 6011 1175",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=144",
      email: "",
      phone: "+65 6011 1175",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Bosco Pereira - Priest",
      description:
        "Rev Fr John Bosco Pereira, ordained 19 June 1996.  Priest - Church of St Stephen, 30 Sallim Road, Singapore 387639. Contact number +65 6461 3745",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=100",
      email: "",
      phone: "+65 6461 3745",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Park Jae Woo - Priest",
      description:
        "Rev Fr John Park Jae Woo, ordained 17 September 2004.  Priest - Blk A3-7, 98 Hougang Ave 8, Singapore 538791",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=467",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Chen Binshan - Priest",
      description:
        "Rev Fr John Chen Binshan, ordained 19 March 1998.  Priest - Church of the Risen Christ, 91 Toa Payoh Central, Singapore 319193. Contact number +65 6253 8622",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=427",
      email: "",
      phone: "+65 6253 8622",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Sim - Priest",
      description:
        "Rev Fr John Sim, ordained 30 June 1984.  Priest - Church of Christ the King, 2221 Ang Mo Kio Avenue 8, Singapore 569809. Contact number +65 6459 9958",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=110",
      email: "",
      phone: "+65 6459 9958",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Khoo - Priest",
      description:
        "Rev Fr John Khoo, ordained 13 December 1969.  Priest - Church of Christ the King, 2221 Ang Mo Kio Avenue 8 , Singapore 569809. Contact number +65 6459 9956",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=394",
      email: "",
      phone: "+65 6459 9956",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr John Lau - Priest",
      description:
        "Rev Fr John Lau, ordained 03 July 1981.  Priest - Church of St Stephen, 30 Sallim Road, Singapore 387639. Contact number +65 6747 1433",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=75",
      email: "",
      phone: "+65 6747 1433",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joachim Chang - Priest",
      description:
        "Rev Fr Joachim Chang, ordained 20 June 1998.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8729",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=395",
      email: "",
      phone: "+65 6902 8729",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Joachim Kang - Priest",
      description: "Rev Fr Joachim Kang, ordained 01 December 1974.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=70",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Msgr James Yeo - Monsignor",
      description:
        "Rev Msgr James Yeo, ordained 01 May 1989.  Monsignor - Catholic Theological Institute of Singapore , 618 Upper Bukit Timah Road, #01-01, Singapore 678214. Contact number +65 6902 8701",
      category: "",
      keywords: "Monsignor",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=136",
      email: "",
      phone: "+65 6902 8701",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Ignatius Low - Priest",
      description: "Rev Fr Ignatius Low, ordained 07 August 1995.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=85",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Ignatius Yeo - Priest",
      description:
        "Rev Fr Ignatius Yeo, ordained 27 May 2002.  Priest - Church of St Anthony, 25 Woodlands Avenue 1 , Singapore 739064. Contact number +65 6368 3804 ext. 206",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=197",
      email: "",
      phone: "+65 6368 3804 ext. 206",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Henry Siew - Priest",
      description:
        "Rev Fr Henry Siew, ordained 14 April 1991.  Priest - Church of The Holy Cross, 450 Clementi Avenue 1, Singapore 129955. Contact number +65 6777 5858",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=109",
      email: "",
      phone: "+65 6777 5858",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Gabriel Wong - Priest",
      description:
        "Rev Fr Gabriel Wong, ordained 01 January 2019.  Priest. Contact number Assistant Spiritual Director",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=444",
      email: "",
      phone: "Assistant Spiritual Director",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Gerard Weerakoon - Priest",
      description:
        "Rev Fr Gerard Weerakoon, ordained 04 November 1996.  Priest - Church of St Anthony, 25 Woodlands Avenue 1, Singapore 739064. Contact number +65 6368 3804 ext 205",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=72",
      email: "",
      phone: "+65 6368 3804 ext 205",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Jovita Cyprian Ho - Priest",
      description:
        "Rev Fr Jovita Cyprian Ho, ordained 15 October 2013.  Priest - St Anne’s Church, 66 Sengkang East Way, Singapore 548593. Contact number +65 6315 2976",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=372",
      email: "",
      phone: "+65 6315 2976",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Frederick Quek - Priest",
      description:
        "Rev Fr Frederick Quek, ordained 07 July 2001.  Priest - Church of the Holy Trinity, 20 Tampines St 11 , Singapore 529455. Contact number +65 6784 1211",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=183",
      email: "",
      phone: "+65 6784 1211",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Deacon Francis Tse Ming Fai - Deacon",
      description:
        "Rev Deacon Francis Tse Ming Fai, ordained 02 October 2004.  Deacon",
      category: "",
      keywords: "Deacon",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=448",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rt Rev Msgr Francis Lau - Monsignor",
      description:
        "Rt Rev Msgr Francis Lau, ordained 18 June 1961.  Monsignor - Cathedral of the Good Shepherd, ’A’ Queen Street , Singapore 188533. Contact number +65 6337 2036",
      category: "",
      keywords: "Monsignor",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=166",
      email: "",
      phone: "+65 6337 2036",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Francis Lee - Priest",
      description:
        "Rev Fr Francis Lee, ordained 07 December 1975.  Priest - Church of Our Lady Queen of Peace, 4 Sandy Lane, Singapore 437321. Contact number +65 6744 2397",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=77",
      email: "",
      phone: "+65 6744 2397",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Eugene Vaz - Priest",
      description:
        "Rev Fr Eugene Vaz, ordained 13 February 1971.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8722",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=58",
      email: "",
      phone: "+65 6902 8722",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Erbin Fernandez - Priest",
      description:
        "Rev Fr Erbin Fernandez, ordained 02 January 1995.  Priest - Bethany East Clergy Residence, 576 Changi Road, Singapore 419945. Contact number +65 6902 8727",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=393",
      email: "",
      phone: "+65 6902 8727",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Eugene Chong - Priest",
      description:
        "Rev Fr Eugene Chong, ordained 14 July 1990.  Priest - Church of St Vincent De Paul, 301 Yio Chu Kang Road , Singapore 805910. Contact number +65 6482 0959",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=38",
      email: "",
      phone: "+65 6482 0959",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Edward Seah - Priest",
      description:
        "Rev Fr Edward Seah, ordained 08 August 2013.  Priest - 618 Upper Bukit Timah Road, #02-01, St Francis Xavier Seminary Building, Singapore 678214. Contact number +65 6906 8452",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=371",
      email: "",
      phone: "+65 6906 8452",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Edward Lim - Priest",
      description:
        "Rev Fr Edward Lim, ordained 27 May 1983.  Priest - Church of the Risen Christ, 91 Toa Payoh Central , Singapore 319193. Contact number +65 6255 7509",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=80",
      email: "",
      phone: "+65 6255 7509",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Edmund Chong - Priest",
      description:
        "Rev Fr Edmund Chong, ordained 10 May 1992.  Priest - Church of Our Lady of Perpetual Succour, 31 Siglap Hill, Singapore 456085. Contact number +65 6241 0953",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=37",
      email: "",
      phone: "+65 6241 0953",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Cyril John Lee - Priest",
      description:
        "Rev Fr Cyril John Lee, ordained 16 January 1966.  Priest - Church of St Anthony, 25 Woodlands Avenue 1 , Singapore 739064. Contact number +65 6368 3804 ext 207",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=76",
      email: "",
      phone: "+65 6368 3804 ext 207",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Damian De Wind - Priest",
      description:
        "Rev Fr Damian De Wind, ordained 07 August 2005.  Priest - Church of Divine Mercy, 19 Pasir Ris Street 72, Singapore 518771. Contact number +65 6583 8789",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=188",
      email: "",
      phone: "+65 6583 8789",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Cornelius Vianney Ching - Priest",
      description:
        "Rev Fr Cornelius Vianney Ching, ordained 09 August 2016.  Priest",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=415",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Deacon Clement Chen - Deacon",
      description:
        "Rev Deacon Clement Chen, ordained 01 November 2008.  Deacon - Church of the Holy Family, 6 Chapel Road, Singapore 429509. Contact number +65 6344 0046",
      category: "",
      keywords: "Deacon",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=345",
      email: "",
      phone: "+65 6344 0046",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Christopher Lee - Priest",
      description:
        "Rev Fr Christopher Lee, ordained 28 August 2005.  Priest - St Joseph’s Church (Bukit Timah), 620 Upper Bukit Timah Road, Singapore 678116. Contact number +65 6760 0052",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=217",
      email: "",
      phone: "+65 6760 0052",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Cary Chan - Priest",
      description:
        "Rev Fr Cary Chan, ordained 01 May 1992.  Priest - Church of the Holy Spirit, 248 Upper Thomson Road , Singapore 574371. Contact number +65 6453 8119",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=29",
      email: "",
      phone: "+65 6453 8119",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Brian D’Souza - Priest",
      description:
        "Rev Fr Brian D’Souza, ordained 07 July 2002.  Priest - Cathedral of the Good Shepherd, ’A’ Queen Street , Singapore 188533. Contact number +65 6337 2036",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=133",
      email: "",
      phone: "+65 6337 2036",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Benjamin Anthonisamy - Priest",
      description:
        "Rev Fr Benjamin Anthonisamy, ordained 04 December 2000.  Priest - Church of St Francis of Assisi, 200 Boon Lay Ave, Singapore 649964. Contact number +65 6268 0452",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=409",
      email: "",
      phone: "+65 6268 0452",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Benedict Chng - Priest",
      description:
        "Rev Fr Benedict Chng, ordained 08 August 2013.  Priest - Church of St Francis Xavier, 63A Chartwell Drive , Singapore 558758. Contact number +65 6288 0484",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=370",
      email: "",
      phone: "+65 6288 0484",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Augustine Tay - Priest",
      description:
        "Rev Fr Augustine Tay, ordained 01 December 1974.  Priest - Blk 178, Edgefield Plains, #08-230, Singapore 820178. Contact number +65 9671 3409",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=118",
      email: "",
      phone: "+65 9671 3409",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Rev Fr Augustine Joseph - Priest",
      description:
        "Rev Fr Augustine Joseph, ordained 10 May 1995.  Priest - Church of St Teresa, 510 Kampong Bahru Road, Singapore 099446. Contact number +65 6011 1170",
      category: "",
      keywords: "Priest",
      prodserve: "",
      photo: "",
      link: "",
      website:
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?PrD8890=69",
      email: "",
      phone: "+65 6011 1170",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Parables",
      description: "",
      category: "Shop",
      keywords: "plushie, books, pins, coasters, pillow, keychain, stationery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fparables.jpg?alt=media&token=e8cdd955-3945-44a8-9fd7-5f4ee2cea841",
      link: "",
      website: "https://parablesgifts.com/",
      email: "hello@parablesgifts.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/parablesgifts/",
      instagram: "https://www.instagram.com/parablesgifts/",
    },
    {
      name: "Halo & Wings Pte Ltd",
      description: "",
      category: "Shop",
      keywords: "Jewellery, bangle, necklace, keychain, bible, ring, anklet",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fhalo-and-wings.webp?alt=media&token=8c42e8c7-26d8-49b6-a3f7-2e90c20dde95",
      link: "",
      website: "https://www.haloandwings.sg/",
      email: "sales@haloandwings.sg",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/haloandwing.sg",
      instagram: "",
    },
    {
      name: "Footprints Books & Magazines",
      description: "",
      category: "Shop",
      keywords: "Catholic items, statues, rosary, incense, holy water bottles",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FFootprints.jpg?alt=media&token=e3c95d8e-7841-406c-b2f8-6a20eab9ab9f",
      link: "",
      website: "https://footprintss.com/",
      email: "",
      phone: "",
      whatsapp: "+65 8612 5610",
      facebook: "",
      instagram: "",
    },
    {
      name: "Abundantgifts.com.sg",
      description: "",
      category: "Shop",
      keywords:
        "Catholic items, books, bibles, cross, crucifix, fridge magnets, calendar, candle",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAbundant-Gifts.png?alt=media&token=b7e1a3c2-840d-41e9-a2f0-d1abe1e8356d",
      link: "",
      website: "https://abundantgifts.com.sg/",
      email: "abundantgifts.com.sg@gmail.com",
      phone: "",
      whatsapp: "+65 8811 7667",
      facebook: "https://www.facebook.com/abundantgifts.com.sg/",
      instagram: "",
    },
    {
      name: "Avila Catholic Shop SG",
      description:
        "Avila Catholic Shop SG is a family business with a mission to bring good-quality Catholic devotional items to wide Catholic community in Singapore. We select our products carefully to suit the needs of Catholics in Singapore and procure from reputable manufacturers based in Italy, Spain, and Germany directly. Currently we sell our products via online platforms and also supply to other Catholic shops in Singapore.",
      category: "Shop",
      keywords:
        "Jewellery, Statues, crucifix, cross, pendant, candle, incense, burner, bracelet, rosary, frame, plaque, medal, keychain, veil, nativity set, monstran, pyx",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAvila-catholic-shop.jpg?alt=media&token=dd39e3c7-3612-4447-90f6-d39413cf0413",
      link: "",
      website: "https://www.avilashopsg.com/",
      email: "avila.sgrep@gmail.com",
      phone: "",
      whatsapp: "+65 8887 7113",
      facebook: "https://www.facebook.com/avilashopsg",
      instagram: "https://www.instagram.com/avilashopsg/",
    },
    {
      name: "Holly Jolly",
      description: "",
      category: "Shop",
      keywords: "Statues, figurine, last supper, nativity set",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FHolly-Jolly.jpg?alt=media&token=4f01b4d5-a4e2-44c0-9111-ebddae9def88",
      link: "",
      website: "https://shopee.sg/hollyjolly20",
      email: "",
      phone: "",
      whatsapp: "+65 9646 4657",
      facebook: "",
      instagram: "",
    },
    {
      name: "Naomi Artisan Candle",
      description:
        "Personalised Handmade Candle to capture your special memories.",
      category: "Shop",
      keywords: "Candles, decorative candles",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fnaomi-artisan.jpg?alt=media&token=865246f5-dc7c-40de-9c86-ffdf1e0b1e81",
      link: "",
      website: "https://shopify.naomiartisan.com.sg/",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/naomiartisan",
    },
    {
      name: "Sundays and Saints",
      description: "",
      category: "Shop",
      keywords: "Print, Rosary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSundays-and-Saints.jpg?alt=media&token=9d4378e8-ec92-4210-8e34-cf4684883d68",
      link: "",
      website: "https://www.sundaysandsaints.com/",
      email: "hello@sundaysandsaints.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/sundaysandsaints",
      instagram: "https://www.instagram.com/sundaysandsaints/",
    },
    {
      name: "Noah’s Ark by Saint Max Pte Ltd",
      description: "",
      category: "Shop",
      keywords: "Books, plushie, bible",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FNoah-Ark-2.png?alt=media&token=cd3da0d1-a891-4ce9-86ec-f895a76cf22e",
      link: "",
      website: "https://www.noahsark.sg/",
      email: "info@noahsark.sg",
      phone: "",
      whatsapp: "+65 9888 9951",
      facebook: "https://www.facebook.com/noahsark.singapore",
      instagram: "",
    },
    {
      name: "Saint Max Pte Ltd",
      description: "",
      category: "Media",
      keywords: "Video",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSaint-Max-SG.jpg?alt=media&token=f0a6b2d3-aa60-470b-b0ee-055676fd53b5",
      link: "",
      website: "https://www.saintmax.sg/",
      email: "info@saintmax.sg",
      phone: "",
      whatsapp: "+65 8399 2921",
      facebook: "https://www.facebook.com/profile.php?id=100063632109188",
      instagram: "https://www.instagram.com/saintmax.sg",
    },
    {
      name: "Miracle Crafts",
      description: "",
      category: "Shop",
      keywords: "Jewellery, accessories",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fmiracle-crafts.jpg?alt=media&token=1bad4fbb-bcb2-4b7b-a371-00b9a96414b8",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/miraclecrafts.co",
    },
    {
      name: "Carlo Catholic Society",
      description: "",
      category: "Shop",
      keywords:
        "Catholic Shop, rosary, bible, books, scapular, statues, plushie",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCARLOCATHOLICSERVICES.png?alt=media&token=cf56eed9-96ed-4622-bca9-1bbaa2457223",
      link: "",
      website: "https://carlo.org.sg/",
      email: "carlo@carlo.org.sg",
      phone: "",
      whatsapp: "+65 9113 5681",
      facebook: "",
      instagram: "",
    },
    {
      name: "Hai Sing Pao",
      description: "",
      category: "Newspaper",
      keywords: "Chinese Newspaper",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCARLOCATHOLICSERVICES.png?alt=media&token=cf56eed9-96ed-4622-bca9-1bbaa2457223",
      link: "",
      website: "https://carlo.org.sg/haisingpao/",
      email: "haisingpao@carlo.org.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Katong Catholic Book Centre Pte Ltd",
      description: "",
      category: "Shop",
      keywords:
        "Rosary, bible, books, scapular, sacred gifts, gifts, churchware, prayer cards, statues",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FKCBC.jpg?alt=media&token=e59f26e9-138c-4f60-aec7-633657165236",
      link: "",
      website: "https://katongcatholic.com/",
      email: "enquiry@katongcatholic.com",
      phone: "",
      whatsapp: "+65 6345 1413",
      facebook: "https://www.facebook.com/profile.php?id=100070015660208",
      instagram: "https://www.instagram.com/kcbc_pl/",
    },
    {
      name: "Tipsy Dyes",
      description: "",
      category: "Shop",
      keywords: "Tie Dye, shirts, t-shirts",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Ftipsy-dyes.jpg?alt=media&token=058e9925-ac64-4202-b86a-7675f538926b",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/tipsydyes/",
    },
    {
      name: "Manda Creates",
      description: "",
      category: "Shop",
      keywords: "Calligraphy",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FManda-creates.jpg?alt=media&token=b5318880-d423-46aa-8eec-0e4432fc029a",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/manda.creates_/",
    },
    {
      name: "TSA Wines",
      description: "",
      category: "Shop",
      keywords: "Alcohol, Wine,",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FTSA-Wines.png?alt=media&token=ae783725-0c54-44f9-a16f-d35e0aa9e7fb",
      link: "",
      website: "https://tsawines.com.sg/",
      email: "sales@tsawines.com.sg",
      phone: "",
      whatsapp: "+65 8818 0822",
      facebook: "https://www.facebook.com/TSAWinesPteLtd/",
      instagram: "https://www.instagram.com/tsa.craftbeers.wines/",
    },
    {
      name: "All That Is Comfy",
      description: "",
      category: "Shop",
      keywords: "Mask",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fall-that-is-comfy.jpg?alt=media&token=508bb47e-6e3d-466e-b10a-ac61d5af21d0",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/allthatiscomfy/",
    },
    {
      name: "Nio’s Oven",
      description: "",
      category: "Shop",
      keywords: "Baked goods, bread, cake",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fnio-oven.jpg?alt=media&token=a2ca5599-34c8-480e-8dff-596fcb8f0999",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/nios.oven/",
      instagram: "https://www.instagram.com/Niosoven",
    },
    {
      name: "The Cocoa Trees",
      description: "",
      category: "Shop",
      keywords: "Candy, Chocolate",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fthe-cocoa-trees.png?alt=media&token=aa3664dc-2e6b-4db1-af8d-66cb16a40087",
      link: "",
      website: "https://www.thecocoatrees.com/",
      email: "online@thecocoatrees.com",
      phone: "",
      whatsapp: "+65 9831 8307",
      facebook: "https://www.facebook.com/TheCocoaTreesSG",
      instagram: "https://www.instagram.com/thecocoatreessg/",
    },
    {
      name: "Creative Eateries Pte Ltd",
      description: "",
      category: "F&B",
      keywords: "Food catering",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcreative-eateries.jpg?alt=media&token=f64a61ae-17db-447f-8b32-df1bb9475a1f",
      link: "",
      website: "https://www.creativeeateries.com.sg/",
      email: "mail@createries.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/creativeeateries",
      instagram: "https://www.instagram.com/creativeeateries",
    },
    {
      name: "Essence N Accents Pte Ltd",
      description: "",
      category: "Shop",
      keywords: "Jewellery, Rosary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Frosary-jewellery.png?alt=media&token=57685356-4484-4184-81a2-5c5f06bf6261",
      link: "",
      website: "https://rosaryjewellery.com/",
      email: "info@rosaryjewellery.com",
      phone: "",
      whatsapp: "+65 9179 1238",
      facebook: "https://www.facebook.com/giftsofgraceandblessing",
      instagram: "",
    },
    {
      name: "Partridge in a Parish",
      description: "",
      category: "Shop",
      keywords: "Jewellery, Rosary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fpartridge-in-a-parish.jpg?alt=media&token=2a5f18a6-9100-4f16-909b-b22b46dc8c37",
      link: "",
      website: "https://www.partridgeinaparish.com/",
      email: "info@partridgeinaparish.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/PARTRIDGEINAPARISH/",
      instagram: "https://www.instagram.com/partridgeinaparish/",
    },
    {
      name: "REAP Counselling",
      description:
        "REAP Counselling provide counselling in English, Mandarin & Hokkien. Individual, Couple and Family therapy are available. Bernard Lin has a Masters in Counselling and with more than 7 years of experience. Certified Choice Theory Reality Therapy Practitioner, couple with approach like Person-Centered Therapy (PCT), Solution Focused Brief Therapy (SFBT), Systemic Approach, Grief Therapy and Gottman Therapy.  ",
      category: "",
      keywords:
        "Counselling, Empathy, struggle, Mental Health, Depression, Stress, Grief, Hokkien, Dialect speaking therapist",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.reapcounselling.com/",
      email: "sower@reapcounselling.com",
      phone: "",
      whatsapp: "+6581892405",
      facebook: "https://www.facebook.com/profile.php?id=100089534264807",
      instagram: "https://www.instagram.com/reapcounselling/",
    },
    {
      name: "Benedito Cafe",
      description:
        "Of all the places you’d think of to go for authentic Indonesian food, a Catholic seminary is probably not the most obvious choice. Yet, here in this quiet school of theology is Benedito, a cafe serving Western, Japanese and most popular of all, Indonesian cuisine. Dishes include gado gado, ayam bakar and tonkotsu ramen.",
      category: "F&B",
      keywords:
        "indonesian food, cafe, restaurant, ramen, ayam bakar, gado gado",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://benedito-canteen.business.site/",
      email: "",
      phone: "+656815 1081",
      whatsapp: "",
      facebook: "https://www.facebook.com/profile.php?id=100063722822909",
      instagram: "",
    },
    {
      name: "Apostle Productions",
      description:
        "Apostle Productions is an events management and technical production company based in Singapore, providing the necessary creatives, hardware and manpower for a wide range of events and clientele.  A one-stop solution to your audio-visual and event needs, you will be rest assured of smooth and seamless event management, with our extensive inventory of equipment and resources.​ Sincere and dedicated, Apostle Productions work closely with our clients to deliver tailored and exceptional events that strike a positive and memorable impression in the minds of each and every audience member.  ",
      category: "",
      keywords:
        "events, AV, sound system, wide screen, microphone, amplifier, lights, staging, event logistics",
      prodserve: "",
      photo: "",
      link: "",
      website: "https://www.apostleproductions.com.sg/",
      email: "sales@apostleproductions.com.sg",
      phone: "",
      whatsapp: "+659662 2267",
      facebook: "https://www.facebook.com/EventswithPassion/",
      instagram: "https://www.instagram.com/apostleproductions/",
    },
    {
      name: "Sacred Heart of Jesus Plushie",
      description: "Huggable Sacred Heart of Jesus stuffed toys",
      category: "Shop",
      keywords:
        "catholic gift shop, plushie, soft toy, catholic kids, toy shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fsacredheartjesusplushie.jpg?alt=media&token=8b18e215-1925-4495-8fe7-540f6939fec3",
      link: "https://www.lazada.com.ph/products/sacred-heart-of-jesus-plushie-huggable-catholic-soft-toy-i3398833453-s17348991837.html",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "Little Flock by Kirisuto Enterprise",
      description:
        "Little Flock provides Catholic gifts and learning resources for young children and their parents. We are a business that focuses on bringing Jesus Christ to all the children of God in Singapore.",
      category: "Shop",
      keywords: "cute plushie, children toys",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Flock.png?alt=media&token=991c9020-b75c-43ba-94f4-1799ecfc3809",
      link: "",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "Immaculate Heart of Mary Plushie",
      description: "Huggable Mother Mary Soft Toy",
      category: "Shop",
      keywords:
        "catholic gift shop, plushie, soft toy, catholic kids, toy shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FImmaculate%20Heart%20of%20Mary%20Plushie.jpeg?alt=media&token=53bc1156-fd5c-4b3d-b29f-d84709b62dd1",
      link: "",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "St. Michael the Archangel Plushie",
      description: "Huggable devotional Saint Michael the Archangel toy",
      category: "Shop",
      keywords:
        "catholic gift shop, plushie, soft toy, catholic kids, toy shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt.%20Michael%20the%20Archangel%20Plushie.jpg?alt=media&token=a20a769c-0398-4529-baf2-4a9b38d52cfe",
      link: "",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "Jesus Bag Charm",
      description: "Jesus plushie keychain to hang on your bag",
      category: "Shop",
      keywords:
        "catholic gift shop, plushie, soft toy, catholic kids, toy shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FJesus%20Bag%20Charm.jpeg?alt=media&token=2d2044f4-9b53-44fa-925f-e49381575776",
      link: "",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "Mary Bag Charm",
      description: "Mary plushie keychain to hang on your bag",
      category: "Shop",
      keywords:
        "catholic gift shop, plushie, soft toy, catholic kids, toy shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMary%20Bag%20Charm.jpg?alt=media&token=2413448a-bcc9-44dd-995b-2df16e4ef45d",
      link: "",
      website: "https://www.littleflock.sg/",
      email: "littleflocksg@gmail.com",
      phone: "+65 8832 9976",
      whatsapp: "+65 8832 9976",
      facebook: "https://www.facebook.com/LittleFlockSG",
      instagram: "https://www.instagram.com/littleflock.sg/",
    },
    {
      name: "Proclaim Media ",
      description: "",
      category: "Media",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FProclaim-Media.jpg?alt=media&token=6a2ea987-3583-4076-a236-8cb3355be9d3",
      link: "",
      website: "https://portafidei.com/",
      email: "general@proclaimboldly.com",
      phone: "",
      whatsapp: "",
      facebook: "https://www.facebook.com/proclaimboldly",
      instagram: "",
    },
    {
      name: "WellSprings Catholic Books",
      description: "",
      category: "Shop",
      keywords: "Bookshop, Shop",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FWellsprings.jpg?alt=media&token=2c578e49-ba3e-4d40-9b95-a96b91210660",
      link: "",
      website: "https://www.wellsprings.com.sg/",
      email: "",
      phone: "+65 9749 3702",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "7Gifts (Bookshop)",
      description: "Bookshop at Church of the Holy Spirit",
      category: "Shop",
      keywords: "Bookshop, Shop",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "",
      phone: "+65 6453 6340",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "OLPS Bookshop",
      description: "Bookshop at Church of Our Lady of Perpetual Succour",
      category: "Shop",
      keywords: "Bookshop, Shop",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "",
      phone: "+65 6241 9565",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Church of Risen Christ Bookshop",
      description:
        "Media Centre (Apostolate of Media) operated by Daughters of Saint Paul, the Media Centre offers a variety of religious books, CDs and articles at affordable prices.",
      category: "Shop",
      keywords: "Bookshop, Shop",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "pauline.fsp@gmail.com",
      phone: "+65 6356 5958",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CROSSWORDS @ Church of the Holy Cross",
      description: "Bookshop at Church of the Holy Cross",
      category: "Shop",
      keywords: "Bookshop, Shop",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "chc.secretariat@catholic.org.sg",
      phone: "+65 6777 5858",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "IHM Book and Gift Shop",
      description: "Bookshop at Church of the Immaculate Heart of Mary",
      category: "Shop",
      keywords: "Bookshop, Shop, Gift",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "immaculata@ihm.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St. Anne’s Church Gift Shop",
      description: "Bookshop at St. Anne’s Church",
      category: "Shop",
      keywords: "Bookshop, Shop, Gift",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "",
      phone: "+65 9088 0575",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Alphonsus Liguori Gift Shop",
      description: "Bookshop at Novena Church",
      category: "Shop",
      keywords: "Bookshop, Shop, Gift",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fnovenagiftshop.jpg?alt=media&token=15bcd8f5-0e9c-409c-a028-6f4120bb9c25",
      link: "",
      website: "https://shop.novenachurch.com/",
      email: "giftshop@novenachurch.com",
      phone: "+65 6771 9222",
      whatsapp: "+65 8028 0285",
      facebook: "",
      instagram: "",
    },
    {
      name: "Assumption English School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAssumption-School.jpg?alt=media&token=995fc348-3ffb-4e16-a8c0-2f4dbcdd1e93",
      link: "",
      website: "https://assumptionenglish.moe.edu.sg/",
      email: "aes@moe.edu.sg",
      phone: "+65 6572 9100",
      whatsapp: "",
      facebook: "",
      instagram: "https://www.instagram.com/aes_manna/",
    },
    {
      name: "Assumption Pathway School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FAssumption-School.jpg?alt=media&token=995fc348-3ffb-4e16-a8c0-2f4dbcdd1e93",
      link: "",
      website: "http://www.aps.edu.sg/",
      email: "aps@ite.edu.sg",
      phone: "+65 6879 3900",
      whatsapp: "",
      facebook: "https://www.facebook.com/assumptionpathwayschool/",
      instagram: "https://www.instagram.com/assumptionpathway/",
    },
    {
      name: "Brothers of the Christian Schools (FSC) - De La Salle Brothers",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossa Catholic Primary School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCanossa-Catholic-School.jpg?alt=media&token=b359a142-ed72-4923-910a-fb96a3e96af2",
      link: "",
      website: "https://canossacatholicpri.moe.edu.sg/",
      email: "ccps@moe.edu.sg",
      phone: "+65 6844 1418",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossian School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCanossian-School.jpg?alt=media&token=17725dc7-91b0-4640-b867-c80c13a3fb79",
      link: "",
      website: "http://www.canossian.edu.sg/",
      email: "admin@canossian.edu.sg",
      phone: "+65 6749 8971",
      whatsapp: "",
      facebook: "https://www.facebook.com/canossianschool/",
      instagram: "",
    },
    {
      name: "Catholic High School",
      description: "",
      category: "School",
      keywords: "Primary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-High-School.png?alt=media&token=9749cbd8-c0d4-42a7-a8b9-6603d04d4646",
      link: "",
      website: "http://www.catholichigh.moe.edu.sg/",
      email: "chs_ps@moe.edu.sg",
      phone: "+65 6458 9869",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Catholic High School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCatholic-High-School.png?alt=media&token=9749cbd8-c0d4-42a7-a8b9-6603d04d4646",
      link: "",
      website: "http://www.catholichigh.moe.edu.sg/",
      email: "chs@moe.edu.sg",
      phone: "+65 6458 2177",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ (Katong) Primary",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "https://www.chijkcp.moe.edu.sg/",
      email: "chijktps@moe.edu.sg",
      phone: "+65 6344 3072",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ Katong Convent",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijkatongconvent.moe.edu.sg/",
      email: "chijktcs@moe.edu.sg",
      phone: "+65 6448 6433",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ Our Lady of Good Counsel",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijourladyofgoodcounsel.moe.edu.sg/",
      email: "chijolgcs@moe.edu.sg",
      phone: "+65 6288 6930",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ Our Lady of Nativity",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijourladyofthenativity.moe.edu.sg",
      email: "chijoln@moe.edu.sg",
      phone: "+65 6385 2455",
      whatsapp: "",
      facebook: "https://www.facebook.com/chijoln.official/",
      instagram: "",
    },
    {
      name: "CHIJ Our Lady Queen of Peace",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijourladyqueenofpeace.moe.edu.sg/",
      email: "chijolqp@moe.edu.sg",
      phone: "+65 6769 1529",
      whatsapp: "",
      facebook: "https://www.facebook.com/chijolqp.official",
      instagram: "",
    },
    {
      name: "CHIJ Primary (Kellock)",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijkellock.moe.edu.sg",
      email: "chijks@moe.edu.sg",
      phone: "+65 6273 0096",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ Primary (Toa Payoh)",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijpritoapayoh.moe.edu.sg",
      email: "chijtpps@moe.edu.sg",
      phone: "+65 6353 2164",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ Secondary ",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijsec.edu.sg",
      email: "chijtpss@moe.edu.sg",
      phone: "+65 6353 4972",
      whatsapp: "",
      facebook: "https://www.facebook.com/chijsecondary/",
      instagram: "https://www.instagram.com/chijsecondary/",
    },
    {
      name: "CHIJ St Joseph’s Convent",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "https://chijstjosephsconvent.moe.edu.sg/",
      email: "chijsjcs@moe.edu.sg",
      phone: "+65 6489 7580",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ St Nicholas Girl’s School",
      description: "",
      category: "School",
      keywords: "Primary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijstnicholasgirls.moe.edu.sg/",
      email: "chijsng_pri@moe.edu.sg",
      phone: "+65 6454 9698",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ St Nicholas Girl’s School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijstnicholasgirls.moe.edu.sg/",
      email: "chijsng_sec@moe.edu.sg",
      phone: "+65 6354 1839",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "CHIJ St Theresa’s Convent",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCHIJ%20Badge.jpg?alt=media&token=0a0d75a2-4e1d-4daf-aa8e-3b618e418f78",
      link: "",
      website: "http://www.chijsttheresasconvent.moe.edu.sg/",
      email: "chijstcs@moe.edu.sg",
      phone: "+65 6477 5777",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "De La Salle School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FDe-La-Salle-School.jpg?alt=media&token=cfe064a7-c0e9-47fb-b916-b0dde6ca0cad",
      link: "",
      website: "http://www.delasalle.moe.edu.sg/",
      email: "dlss@moe.edu.sg",
      phone: "+65 6766 7675",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Hai Sing Catholic School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FHai-Sing-Catholic-School.jpg?alt=media&token=ade83de5-e8ec-457a-be82-b8b10e8603b1",
      link: "",
      website: "http://www.haisingcatholic.moe.edu.sg/",
      email: "hshs@moe.edu.sg",
      phone: "+65 6582 7864",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Holy Innocent’s High School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FHoly-Innocent-High-School.jpg?alt=media&token=9895e9ba-37db-4042-95d5-6a69429866e0",
      link: "",
      website: "http://www.holyinnocentshigh.moe.edu.sg/",
      email: "hihs@moe.edu.sg",
      phone: "+65 6283 3381",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Holy Innocent’s Primary School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FHoly-Innocent-Primary-School.jpg?alt=media&token=c16b8d9c-49b5-48aa-9865-00f3068a36b0",
      link: "",
      website: "http://www.holyinnocentspri.moe.edu.sg",
      email: "holyinnocentspri@moe.edu.sg",
      phone: "+65 6288 6516",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Maris Stella High School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMaris-Stella-Logo.jpg?alt=media&token=de23b3c6-6aa1-48cd-a3de-22aead9cc367",
      link: "",
      website: "https://marisstellahigh.moe.edu.sg",
      email: "msh@moe.edu.sg",
      phone: "+65 6280 3880",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Maris Stella High School",
      description: "",
      category: "School",
      keywords: "Primary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMaris-Stella-Logo.jpg?alt=media&token=de23b3c6-6aa1-48cd-a3de-22aead9cc367",
      link: "",
      website: "https://marisstellahigh.moe.edu.sg",
      email: "mshp@moe.edu.sg",
      phone: "+65 6282 7743",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Marymount Convent School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMarymount-Convent-School.jpg?alt=media&token=107cf793-9b72-428c-9548-2acfbe20e77d",
      link: "",
      website: "http://www.marymountconvent.moe.edu.sg",
      email: "enquire@marymount.edu.sg",
      phone: "+65 6256 6701",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Monfort Junior School",
      description: "",
      category: "School",
      keywords: "Junior",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMonfort-Junior-School.jpg?alt=media&token=f8185c93-c953-467b-85ce-357dca3a9838",
      link: "",
      website: "http://www.montfortjunior.moe.edu.sg/",
      email: "montfort_js@moe.edu.sg",
      phone: "+65 6510 1588",
      whatsapp: "",
      facebook: "https://www.facebook.com/montfortjuniorschool/",
      instagram: "",
    },
    {
      name: "Monfort Secondary School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMonfort-Junior-School.jpg?alt=media&token=f8185c93-c953-467b-85ce-357dca3a9838",
      link: "",
      website: "http://www.montfortsec.moe.edu.sg/",
      email: "contact@montfortsec.edu.sg",
      phone: "+65 6510 7070",
      whatsapp: "",
      facebook: "https://www.facebook.com/montfortsec.edu.sg/",
      instagram: "",
    },
    {
      name: "St Anthony’s Canossian Primary School",
      description: "",
      category: "School",
      keywords: "Primary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCanossian-School.jpg?alt=media&token=17725dc7-91b0-4640-b867-c80c13a3fb79",
      link: "",
      website: "http://www.stanthonyscanossianpri.moe.edu.sg/",
      email: "sacps@moe.edu.sg",
      phone: "+65 6449 2239",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Anthony’s Canossian Secondary School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FCanossian-School.jpg?alt=media&token=17725dc7-91b0-4640-b867-c80c13a3fb79",
      link: "",
      website: "http://www.stanthonyscanossiansec.moe.edu.sg/",
      email: "sacss@moe.edu.sg",
      phone: "+65 6449 0616",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Anthony’s Primary School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Anthony-Primary-School.jpg?alt=media&token=84e1c5e3-22ca-44c1-8c93-180a1736f059",
      link: "",
      website: "http://www.stanthonyspri.moe.edu.sg/",
      email: "saps@moe.edu.sg",
      phone: "+65 6569 0822",
      whatsapp: "",
      facebook: "https://www.facebook.com/sapsanthonian/",
      instagram: "",
    },
    {
      name: "St Gabriel’s Primary School",
      description: "",
      category: "School",
      keywords: "Primary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMonfort-Junior-School.jpg?alt=media&token=f8185c93-c953-467b-85ce-357dca3a9838",
      link: "",
      website: "http://www.stgabrielspri.moe.edu.sg/",
      email: "stgps@moe.edu.sg",
      phone: "+65 6280 3628",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Gabriel’s Secondary School",
      description: "",
      category: "School",
      keywords: "Secondary",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMonfort-Junior-School.jpg?alt=media&token=f8185c93-c953-467b-85ce-357dca3a9838",
      link: "",
      website: "http://www.stgabrielssec.moe.edu.sg/",
      email: "stgss@moe.edu.sg",
      phone: "+65 6288 9470",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Joseph’s Institution",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Joseph-Institution.jpg?alt=media&token=214900b2-acd5-41f2-828e-0c838a3ea330",
      link: "",
      website: "http://www.sji.edu.sg",
      email: "contact@sji.edu.sg",
      phone: "+65 6250 0022",
      whatsapp: "",
      facebook: "https://www.facebook.com/stjosephsinstitution/",
      instagram: "https://www.instagram.com/stjosephsinstitution/",
    },
    {
      name: "St Joseph’s Institution International",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Joseph-Institution-International.jpg?alt=media&token=6f69a539-93e4-47df-a2c0-e844ec7b6748",
      link: "",
      website: "http://sji-international.com.sg",
      email: "info@sji-international.com.sg",
      phone: "+65 6353 9383",
      whatsapp: "",
      facebook: "https://www.facebook.com/SJI.International",
      instagram: "https://www.instagram.com/sjiinternational/",
    },
    {
      name: "St Joseph’s Institution Junior",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Joseph-Institution-Junior.jpg?alt=media&token=84d7e98b-652c-422b-b62b-e4fc2fd63522",
      link: "",
      website: "http://www.sjijunior.moe.edu.sg/",
      email: "sjijunior@moe.edu.sg",
      phone: "+65 6255 2700",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "St Patrick’s School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Patrick-School.jpg?alt=media&token=5afd3b67-b3c3-4ac0-b0a2-80bc4345668c",
      link: "",
      website: "http://www.stpatricks.moe.edu.sg/",
      email: "stpatricksch@moe.edu.sg",
      phone: "+65 6344 0929",
      whatsapp: "",
      facebook: "https://www.facebook.com/stpatricksschl.singapore/",
      instagram: "https://www.instagram.com/Stpatricksschoolsg/",
    },
    {
      name: "St Stephen’s School",
      description: "",
      category: "School",
      keywords: "",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FSt-Stephen-School.jpg?alt=media&token=3d0d4d4c-5abd-4a0c-8102-9d7b6b232512",
      link: "",
      website: "https://ststephens.moe.edu.sg",
      email: "ststephensch@moe.edu.sg",
      phone: "+65 6444 1645",
      whatsapp: "",
      facebook: "https://www.facebook.com/sonsofststephens",
      instagram: "",
    },
    {
      name: "Parents Catholic SG",
      description:
        "To support Catholic parents in their role as the first cathecists of their children’s faith",
      category: "Archdiocese",
      keywords: "kid’s faith, first cathecist, parent’s resource for kids",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FParents-Catholic-SG.jpg?alt=media&token=ec60ba9e-246b-431d-9258-ca8316af92b9",
      link: "",
      website: "https://parents.catholic.sg/",
      email: "engage@catholic.org.sg",
      phone: "+65 6895 1555",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossaville Preschool",
      description: "",
      category: "School",
      keywords: "Preschool",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcanossaville_logo.jpg?alt=media&token=b0d67b50-2237-4582-b80f-b811f0cdb372",
      link: "",
      website: "https://canossaville.org.sg/preschool/",
      email: "preschool@canossaville.org.sg",
      phone: "+65 6747 6554",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Canossaville Student Care",
      description: "",
      category: "School",
      keywords: "Student Care",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2Fcanossaville_logo.jpg?alt=media&token=b0d67b50-2237-4582-b80f-b811f0cdb372",
      link: "",
      website: "https://canossaville.org.sg/student-care/",
      email: "studentcare@canossaville.org.sg",
      phone: "+65 6812 0681",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },

    {
      name: "Good Shepherd Convent Kindergarten",
      description: "",
      category: "School",
      keywords: "Kindergarten",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FGood-Shepherd-Convent-Kindergarten.jpg?alt=media&token=bfd889fd-fdae-4be6-88a0-f386bdaf52a4",
      link: "",
      website: "http://www.gsckg.edu.sg",
      email: "principal@gsckg.edu.sg",
      phone: "+65 6241 1802",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Good Shepherd Student Care",
      description: "",
      category: "School",
      keywords: "Student Care",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMarymount-Centre.png?alt=media&token=4ee63512-7c19-4520-a288-af7d0062222f",
      link: "",
      website: "https://www.marymountctr.org.sg",
      email: "goodshepherdsc@marymountctr.org.sg",
      phone: "+65 6258 2403",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "LIttle Shepherd’s Schoolhouse @ Church of St Francis of Assissi - Jurong West",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "enquiries@catholicpreschool.edu.sg",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Maris Stella Kindergarten",
      description: "",
      category: "School",
      keywords: "Kindergarten",
      prodserve: "",
      photo: "",
      link: "",
      website: "http://www.marisstella.edu.sg/",
      email: "marisstellakg@yahoo.com.sg",
      phone: "+65 6474 3590",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Marymount Kindergarten",
      description: "",
      category: "School",
      keywords: "Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FMarymount-Preschool-Logo_resize.png?alt=media&token=fc95e98d-dcab-42ac-80f1-356f6034ce6a",
      link: "",
      website: "http://www.mkg.edu.sg",
      email: "principal@mkg.edu.sg",
      phone: "+65 6251 5049",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Star of the Sea Before and After School Care Centre",
      description: "",
      category: "School",
      keywords: "Kindergarten, Care Centre",
      prodserve: "",
      photo: "",
      link: "",
      website: "",
      email: "starbasc265@yahoo.com.sg",
      phone: "+65 6755 9535",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ Church of Our Lady Star of the Sea",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "eunice.yeh@catholicpreschool.edu.sg",
      phone: "+65 6257 0427",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ Church of St Anthony",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "sarah.giam@catholicpreschool.edu.sg",
      phone: "+65 6368 5058",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ Church of St Francis of Xavier",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "angeline.wong@catholicpreschool.edu.sg",
      phone: "+65 6011 1076",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ Church of the Holy Trinity",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "",
      phone: "+65 6440 4344",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ St Anne’s Church",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "sarah.tang@catholicpreschool.edu.sg",
      phone: "+65 6386 2007",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
    {
      name: "Little Shepherd’s Schoolhouse @ St Joseph Church - Bukit Timah",
      description: "",
      category: "School",
      keywords: "Childcare, Kindergarten, Nursery",
      prodserve: "",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/all-catholic.appspot.com/o/images%2FLittle-Shepherds-Schoolhouse.jpg?alt=media&token=eb61ba1e-c3d1-4ed9-86de-510c69d96183",
      link: "",
      website: "https://littleshepherdsschoolhouse.edu.sg",
      email: "melissa.neo@catholicpreschool.edu.sg",
      phone: "+65 6760 1301",
      whatsapp: "",
      facebook: "",
      instagram: "",
    },
  ];
}
