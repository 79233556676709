export function getRandomId(length) {
  let start = 2; //random generate decimal (eg 0.1234...)
  let end = start + length;
  return Math.random().toString().slice(start, end);
}

export function getDefaultRandomId() {
  return getRandomId(9);
}

export function getRandomString(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function removeLastSlash(url) {
  const last = url[url.length - 1];
  if (last === "/") {
    return url.slice(0, url.length - 1);
  }

  return url;
}

export function removeProtocol(url) {
  if (!url) {
    return "";
  }

  return url.replace(/(^\w+:|^)\/\//, "");
}
