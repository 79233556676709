import React from "react";
import { removeLastSlash, removeProtocol } from "../../../utils/utils";

function DisplayName({ redirect = true, name, website, facebook }) {
  if (website) {
    return (
      <>
        <div className="text-left px-0">
          {redirect ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "1.3em" }}
              className="text-left marianblue fs-4 mb-0 fw-bold namecolor"
              href={website}
            >
              {name}
            </a>
          ) : (
            <div
              style={{ lineHeight: "1.3em" }}
              className="text-left marianblue fs-4 mb-0 fw-bold namecolor"
            >
              {name}
            </div>
          )}
        </div>
        <div className="adjusttop text-success text-truncate allcatholicfont px-0 fs-5">
          {redirect ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "1.3em" }}
              className="text-left text-success"
              href={website}
            >
              {removeProtocol(removeLastSlash(website))}
            </a>
          ) : (
            removeProtocol(removeLastSlash(website))
          )}
        </div>
      </>
    );
  }
  if (!website && facebook) {
    return (
      <>
        <div className="text-left px-0">
          {redirect ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "1.3em" }}
              className="text-left marianblue fs-4 mb-0 fw-bold namecolor"
              href={facebook}
            >
              {name}
            </a>
          ) : (
            <div
              className="text-left marianblue fs-4 mb-0 fw-bold namecolor"
              style={{ lineHeight: "1.3em" }}
            >
              {name}
            </div>
          )}
        </div>
        <div className="adjusttop text-success text-truncate allcatholicfont px-0 fs-5">
          {redirect ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "1.3em" }}
              className="text-left text-success"
              href={facebook}
            >
              {removeProtocol(removeLastSlash(facebook))}
            </a>
          ) : (
            removeProtocol(removeLastSlash(facebook))
          )}
        </div>
      </>
    );
  }

  return (
    <p
      className="fs-4 mb-0 fw-bold marianblue"
      dangerouslySetInnerHTML={{
        __html: name,
      }}
    ></p>
  );
}

export default DisplayName;
