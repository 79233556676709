import React from "react";
import { useNavigate } from "react-router-dom";

function TempPage() {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center  pt-5 mt-5 pe-4">
      <button
        className="btn btn-outline-primary mx-2"
        onClick={() => navigate("/entries")}
      >
        Entries
      </button>
      <button
        className="btn btn-outline-primary mx-2"
        onClick={() => navigate("/userinput")}
      >
        User Input
      </button>
    </div>
  );
}

export default TempPage;
