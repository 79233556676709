import { INVALID_FORM_FIELD_VALUES } from "./config";

export const addCustomValidateFieldWithExclude = (inputs, exclude) => {
  //invalid conditions
  const schema = INVALID_FORM_FIELD_VALUES;
  //this loops thru the entire schema and skips exclude
  let entry;
  for (entry in schema) {
    /*            entry : key
     *    schema[entry] : value
     */
    const field = entry;
    const val = schema[entry];
    if (exclude === field) {
      continue;
    }
    //if input matches the invalid conditions....
    if (inputs[field] === val) {
      return false;
    }
  }
  return true;
};

export const validateAddForm = (inputs, field, value) => {
  //validate inputs
  //this make sure that the inputs are valid,
  const schema = INVALID_FORM_FIELD_VALUES;
  let entry;
  for (entry in schema) {
    /*            entry : key
     *    schema[entry] : value
     */
    if (entry === field && schema[entry] === value) {
      return false;
    }
  }
  //check other remaining fields excluding the previously checked
  if (addCustomValidateFieldWithExclude(inputs, field)) {
    return true;
  }

  return false;
};
