import React from "react";
import ContactUs from "./ContactUs";

function ContactBottom({ text = "Re: AllCatholics.SG" }) {
  return (
    <div className="mt-5 pt-5 d-flex justify-content-end align-items-center">
      <ContactUs entry={text} />
    </div>
  );
}

export default ContactBottom;
