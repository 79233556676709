import React from "react";
import logo from "../assets/images/allcatholics-logo.png";
import { getReleaseVersion } from "../utils/settings";

function WideLogo({ showVersion = false }) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <img
        src={logo}
        alt="AllCatholics.SG"
        width="50px"
        height="50px"
        className="me-1"
      />
      <div className="d-flex justify-content-center align-items-center">
        <div className="marianblue fs-1 fw-bold">AllCatholics.SG</div>
        {showVersion && (
          <div
            style={{ marginTop: "45px", marginLeft: "-42px" }}
            className="text-muted fw-normal"
          >
            {getReleaseVersion()}
          </div>
        )}
      </div>
    </div>
  );
}

export default WideLogo;
