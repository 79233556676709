import { doc, getDoc } from "firebase/firestore";
import { db } from "./fire";

export async function getVersion(type = "web") {
  const result = [];
  const docRef = doc(db, "version", type);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    result.push(docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
  return result[0];
}
