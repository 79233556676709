import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getData, getModal, resetModal } from "../redux/modal";
import ComponentModalForm from "./ComponentModalForm";
import ComponentModalViewForm from "./ComponentModalViewForm";
import { getAllowSave } from "../redux/form";
// import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import DisplayEntry from "./DisplayEntry";
import {
  MODAL_TYPE_ADD,
  MODAL_TYPE_DELETE,
  MODAL_TYPE_EDIT,
  MODAL_TYPE_VIEW,
} from "../utils/config";
import GenericModal from "../../../common/GenericModal";

function ComponentModals({
  processing = false,
  addHandler = () => {},
  editHandler = () => {},
  deleteHandler = () => {},
}) {
  const dispatch = useDispatch();
  const modal = useSelector(getModal);
  const selectedEntry = useSelector(getData);
  const allowSave = useSelector(getAllowSave);

  const closeModal = () => {
    dispatch(resetModal());
  };

  let enablebutton = allowSave;
  let buttontext = "Save";
  let buttoncanceltext;
  let buttontype = "success";
  let handler = () => {};
  let modalBody = <div className="py-5 my-5"></div>;
  if (modal.type === MODAL_TYPE_ADD) {
    modalBody = <ComponentModalForm />;
    handler = addHandler;
  } else if (modal.type === MODAL_TYPE_EDIT) {
    modalBody = <ComponentModalForm />;
    handler = editHandler;
  } else if (modal.type === MODAL_TYPE_VIEW) {
    modalBody = <ComponentModalViewForm />;
    handler = null;
    buttoncanceltext = "Close";
  } else if (modal.type === MODAL_TYPE_DELETE) {
    enablebutton = true;
    handler = deleteHandler;
    buttontext = "Delete";
    buttontype = "danger";
    modalBody = (
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">{/* <img src="..." alt="..."> */}</div>
        <div className="flex-grow-1 ms-3">
          <p className="biggerfontsize font-weight-bold text-danger mb-0">
            Proceed to delete?
          </p>
          <DisplayEntry entry={selectedEntry} />
        </div>
      </div>
    );
  }
  return (
    <GenericModal
      show={
        modal.type === MODAL_TYPE_ADD ||
        modal.type === MODAL_TYPE_EDIT ||
        modal.type === MODAL_TYPE_VIEW ||
        modal.type === MODAL_TYPE_DELETE
      }
      closehandler={closeModal}
      size="lg"
      okhandler={handler}
      enablebutton={enablebutton}
      buttoncanceltext={buttoncanceltext}
      buttontext={buttontext}
      buttontype={buttontype}
      title={modal.text}
      processing={processing}
      body={modalBody}
      css="text-dark font-weight-bold"
    />
  );
}

export default ComponentModals;
