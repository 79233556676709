import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactBottom from "./ContactBottom";
import FavoriteIcon from "@mui/icons-material/Favorite";

function Footer() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-center text-center">
            <ContactBottom />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex justify-content-center align-items-center text-center"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pt-4 d-flex justify-content-center align-items-center text-center">
            © 2023 AllCatholics.SG{" "}
            <span className="ms-1 text-muted">| alpha version</span>
          </div>
          <div className="pt-0 d-flex justify-content-center align-items-center text-center">
            <span className="text-muted">All Rights Reserved.</span>
          </div>
          <div className="pt-0 pb-5 d-flex justify-content-center text-muted  align-items-center text-center">
            Made with <FavoriteIcon className="ms-1 text-danger" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
