import { createSlice } from "@reduxjs/toolkit";
import {
  MODAL_INIT,
  MODAL_NAME,
  MODAL_TITLE_ADD,
  MODAL_TITLE_DELETE,
  MODAL_TITLE_EDIT,
  MODAL_TITLE_VIEW,
  MODAL_TYPE_ADD,
  MODAL_TYPE_DELETE,
  MODAL_TYPE_EDIT,
  MODAL_TYPE_VIEW,
} from "../../utils/config";

const initialState = {
  modal: MODAL_INIT,
  processing: false,
  data: null,
};

const slice = createSlice({
  name: MODAL_NAME,
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
    showViewModal: (state, action) => {
      const text = action.payload?.text || MODAL_TITLE_VIEW;
      state.modal = { text: text, type: MODAL_TYPE_VIEW };
      state.data = action.payload?.data;
    },
    /** Modal */
    showAddModal: (state, action) => {
      const text = action.payload?.text || MODAL_TITLE_ADD;
      state.modal = { text: text, type: MODAL_TYPE_ADD };
      state.data = action.payload?.data;
    },
    showEditModal: (state, action) => {
      const text = action.payload?.text || MODAL_TITLE_EDIT;
      state.modal = { text: text, type: MODAL_TYPE_EDIT };
      state.data = action.payload?.data;
    },
    showDeleteModal: (state, action) => {
      const text = action.payload?.text || MODAL_TITLE_DELETE;
      state.modal = { text: text, type: MODAL_TYPE_DELETE };
      state.data = action.payload?.data;
    },
    resetModal: () => initialState,
  },
});

function getSlice(state) {
  return state[MODAL_NAME];
}
//setters
export const {
  setModal,
  setData,
  setProcessing,
  showViewModal,
  showAddModal,
  showEditModal,
  showDeleteModal,
  resetModal,
} = slice.actions;

//getters
export const getModal = (state) => getSlice(state).modal;
export const getData = (state) => getSlice(state).data;
export const getProcessing = (state) => getSlice(state).processing;

export default slice.reducer;
