import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./form";
import modalReducer from "./modal";

export default configureStore({
  reducer: {
    form: formReducer,
    modal: modalReducer,
  },
});
