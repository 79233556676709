import React from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import UserInput from "./UserInput";

function MainUserInput({ user }) {
  return (
    <Provider store={store}>
      <UserInput user={user} />
    </Provider>
  );
}

export default MainUserInput;
