import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../../common/Header";
import SearchOffRoundedIcon from "@mui/icons-material/SearchOffRounded";

function NotFound() {
  const history = useNavigate();
  return (
    <>
      <Header />
      <Container className="mx-auto">
        <Row>
          <Col>
            <div className="d-flex flex-column vertical-center justify-content-center">
              <SearchOffRoundedIcon className="largeicon marianblue" />
              <p className="text-center fs-2 pb-4">
                Sorry, I can't find what you are looking for
              </p>
              <button
                className="btn btn-lg marianbutton"
                size="lg"
                onClick={() => history("/")}
              >
                Back to Home
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
