import {
  query,
  getDocs,
  setDoc,
  collection,
  where,
  doc,
  deleteDoc,
  updateDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../../services/fire";

export async function getUserInputFromDB(count = 10) {
  const results = [];
  const ref = collection(db, "userinput");
  const q = query(ref, orderBy("created", "desc"), limit(count));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
    // console.log(`${doc.id} => ${doc.data()}`);
  });

  console.log(results);

  return results;
}

export async function updateUserInputById(id, data) {
  console.log(id);
  console.log(data);
  try {
    const ref = doc(db, "userinput", id);
    await updateDoc(ref, { ...data });
  } catch (e) {
    console.error(e);
  }
}

export async function deleteUserInputFromDB(entryId) {
  await deleteDoc(doc(db, "userinput", entryId));
}
