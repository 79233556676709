import React from "react";

function Terms() {
  return (
    <p className="maxinputwidth px-4">
      <span className="text-danger">*</span>
      By clicking Notify Me, you allow AllCatholics.SG to send you updates once
      the site is ready.
    </p>
  );
}

export default Terms;
