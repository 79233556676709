import React, { useState } from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ShowMoreText from "react-show-more-text";
import DisplayName from "./DisplayName";
import Report from "../../../common/Report";
import DisplayActions from "../../../common/DisplayActions";
import { useNavigate } from "react-router-dom";
import { isClickableKeywords } from "../../../utils/settings";

function ListDetails({ data, showlabel = true }) {
  const navigate = useNavigate();
  const { document } = data;
  const [showExtra, setShowExtra] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const {
    name,
    description,
    category,
    keywords,
    instagram,
    facebook,
    website,
  } = document;
  const isDynamic = true;
  const isClickable = isClickableKeywords();
  const displayCSS = isDynamic ? "d-none d-lg-block" : "";
  let newName = name,
    newDescription = `${description}`,
    newCategory = category.length > 0 ? `${category}` : null,
    newKeywords = keywords.length > 0 ? keywords : null;

  const Extras = () => (
    <>
      {newCategory && (
        <p className="d-flex align-items-center my-1 fw-normal fs-6">
          <BookmarkBorderIcon className="text-muted fs-6" /> {newCategory}
        </p>
      )}
      {newKeywords && (
        <div className="my-2">
          {newKeywords.split(",").map((i, index) => {
            if (i.trim().length === 0) {
              return null;
            }
            return (
              <p
                onClick={() => {
                  if (isClickable) {
                    navigate(`/s/${i}`);
                  }
                }}
                key={index}
                className={`${
                  isClickable ? "pointer" : ""
                } me-1 my-1 badge rounded-pill lightframeborder text-dark fw-normal`}
                dangerouslySetInnerHTML={{
                  __html: i.trim().toLowerCase(),
                }}
              ></p>
            );
          })}
        </div>
      )}
    </>
  );
  return (
    <div className="">
      <DisplayName name={newName} website={website} facebook={facebook} />
      <ShowMoreText
        lines={3}
        more="See more"
        less="See less"
        className="fs-5"
        anchorClass="seemore pointer"
        onClick={() => setShowExtra((p) => !p)}
        expanded={false}
        onTruncate={() => {
          setUntouched(false);
          setShowExtra(true);
        }}
      >
        {newDescription}
        <div className="clearfix"></div>
        {showExtra && <Extras />}
      </ShowMoreText>
      {untouched && <Extras />}
      <DisplayActions
        showlabel={showlabel}
        css={displayCSS}
        detail={document}
      />

      {website.length > 0 &&
      website.indexOf(
        "https://www.catholic.sg/directory/singapore_catholic_church/priest-contact-details-information/?"
      ) === -1 ? (
        <div className="d-flex justify-content-end">
          <Report entry={name} />
        </div>
      ) : (
        <div className="d-flex justify-content-end">
          <Report entry={name} variant="feedback" />
        </div>
      )}
    </div>
  );
}

export default ListDetails;
