import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PublicIcon from "@mui/icons-material/Public";

function DisplayActions({ showlabel, css, detail }) {
  const { instagram, facebook, phone, whatsapp, website, email } = detail;

  return (
    <div className="pt-3 d-flex justify-content-end flex-wrap align-items-center">
      {facebook && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="notextdeco text-center text-muted"
            href={facebook}
          >
            <div className="notextdeco d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <FacebookIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>Facebook</span>
              )}
            </div>
          </a>
        </div>
      )}

      {website && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="notextdeco text-center mx-auto"
            href={website}
          >
            <div className="d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <PublicIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>Website</span>
              )}
            </div>
          </a>
        </div>
      )}
      {email && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="notextdeco text-center mx-auto"
            href={`mailto:${email.trim()}`}
          >
            <div className="d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <MailOutlineIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>Email</span>
              )}
            </div>
          </a>
        </div>
      )}

      {instagram && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="notextdeco text-center mx-auto"
            href={instagram}
          >
            <div className="d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <InstagramIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>Instagram</span>
              )}
            </div>
          </a>
        </div>
      )}

      {whatsapp && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="notextdeco text-center mx-auto"
            href={`https://wa.me/${whatsapp}`}
          >
            <div className="d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <WhatsAppIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>WhatsApp</span>
              )}
            </div>
          </a>
        </div>
      )}
      {phone && (
        <div className="text-truncate text-center my-1 ms-2 px-0">
          <a
            target="_blank"
            className="notextdeco text-center"
            rel="noopener noreferrer"
            href={`tel:${phone}`}
          >
            <div className="d-flex justify-content-center align-items-center text-center roundcornerlightbutton">
              <PhoneIcon className="" />
              {showlabel && (
                <span className={`ms-1 notextdeco ${css}`}>Call</span>
              )}
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export default DisplayActions;
