import {
  query,
  orderBy,
  getDocs,
  setDoc,
  collection,
  where,
  doc,
  deleteDoc,
  updateDoc,
  limit,
} from "firebase/firestore";
import { db } from "../../../services/fire";

export async function getAutoSuggestFromDB(count = 10) {
  const results = [];
  const ref = collection(db, "autosuggest");
  const q = query(ref, limit(count));
  // const q = query(ref, orderBy("created", "desc"), limit(count));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
    console.log(`${doc.id} => ${doc.data()}`);
  });

  console.log(results);

  return results;
}

export async function addAutoSuggestToDB(entry) {
  const ref = collection(db, "autosuggest");
  const docRef = doc(ref);
  const obj = {
    ...entry,
    id: docRef.id,
  };
  await setDoc(docRef, obj);
  return obj;
}

export async function updateAutoSuggestById(id, data) {
  console.log(id);
  console.log(data);
  const ref = doc(db, "autosuggest", id);

  await updateDoc(ref, { ...data });
}

export async function deleteAutoSuggestFromDB(entryId) {
  const results = [];
  const userRef = collection(db, "autosuggest");
  const q = query(userRef, where("entryId", "==", entryId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  await deleteDoc(doc(db, "autosuggest", results[0].id));
}
