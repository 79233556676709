import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../common/Header";
import { isValidEmail } from "../../../utils/validate";
import { addEmailToDB, checkEmailFromDB } from "../../../services/userlist";
import AddEmailSuccess from "../components/AddEmailSuccess";
import Terms from "../components/Terms";
import Tagline from "../components/Tagline";
import EmailInput from "../../../common/EmailInput";
import SubmitButton from "../../../common/SubmitButton";
import UpdateModal from "../../updatemodal/page/UpdateModal";

function Landing() {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = () => {
    const valid = isValidEmail(email.trim().toLowerCase());

    if (!valid) {
      setEmailErr("Please provide a valid email");
      setLoading(false);
    } else {
      setLoading(true);
      setEmailErr("");
      //process here
      checkEmailFromDB(email.trim().toLowerCase()).then(async (found) => {
        if (!found) {
          //add
          await addEmailToDB(email.trim().toLowerCase());
        }
        setLoading(false);
        setShowSuccess(true);
      });
    }
  };

  return (
    <div>
      <Header />
      <UpdateModal />
      <div className="gradientredbackgrounds pb-3">
        <div className="text-center d-flex flex-column vertical-center justify-content-center">
          {!showSuccess ? (
            <>
              <Tagline />
              <Container>
                <Row>
                  <Col className="mx-auto">
                    <EmailInput
                      email={email}
                      emailErr={emailErr}
                      setEmail={setEmail}
                    />
                  </Col>
                </Row>
              </Container>
              <SubmitButton loading={loading} handler={handleSubmit} />
              <Terms />
            </>
          ) : (
            <div className="pt-5">
              <AddEmailSuccess
                handler={() => {
                  setShowSuccess(false);
                  setEmail("");
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Landing;
