import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function CustomSearch({
  search = "",
  value = "",
  autoFocus = true,
  onChange = () => {},
  handleOnSelect,
  handleOnFocus = () => {},
  formatResult = () => {},
}) {
  return (
    <ReactSearchAutocomplete
      items={value}
      inputSearchString={search}
      showNoResults={false}
      placeholder="What are you looking for?"
      inputDebounce={10}
      onSearch={onChange}
      //   onHover={handleOnHover}
      onSelect={handleOnSelect}
      onFocus={handleOnFocus}
      autoFocus={autoFocus}
      formatResult={formatResult}
    />
  );
}

export default CustomSearch;
